import axios from 'axios';
import {
    CREATE_REVIEW_REQUEST,
    CREATE_REVIEW_SUCCESS,
    CREATE_REVIEW_FAIL,
    GET_REVIEWS_REQUEST,
    GET_REVIEWS_SUCCESS,
    GET_REVIEWS_FAIL,
    REVIEW_DETAILS_REQUEST,
    REVIEW_DETAILS_SUCCESS,
    REVIEW_DETAILS_FAIL,
    UPDATE_REVIEW_REQUEST,
    UPDATE_REVIEW_SUCCESS,
    UPDATE_REVIEW_FAIL,
    DELETE_REVIEW_REQUEST,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_FAIL,
    CLEAR_ERRORS
} from '../constants/reviewConstants'

export const newReview = (reviewData) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_REVIEW_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/reviews/new', reviewData, config)

        dispatch({
            type: CREATE_REVIEW_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CREATE_REVIEW_FAIL,
            payload: error.response.data.errMessage
        })
    }
};

export const getReviews = (role) => async (dispatch) => {
    let URL = '/api/v1/reviews';
    if (role === "Admin") { URL = '/api/v1/admin/reviews' }

    try {
        dispatch({ type: GET_REVIEWS_REQUEST })

        const { data } = await axios.get(URL)

        dispatch({
            type: GET_REVIEWS_SUCCESS,
            payload: data.reviews
        })
    } catch (error) {
        dispatch({
            type: GET_REVIEWS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getReviewDetails = (id, role) => async (dispatch) => {
    let URL =  `/api/v1/reviews/${id}`;
    if (role === "Admin") { URL =  `/api/v1/admin/reviews/${id}` }

    try {
        dispatch({ type: REVIEW_DETAILS_REQUEST })
        const { data } = await axios.get(URL)

        dispatch({
            type: REVIEW_DETAILS_SUCCESS,
            payload: data.review
        })
    } catch (error) {
        dispatch({
            type: REVIEW_DETAILS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const updateReview = (id, update) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_REVIEW_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/reviews/${id}`, update, config)

        dispatch({
            type: UPDATE_REVIEW_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: UPDATE_REVIEW_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const deleteReview = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_REVIEW_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.delete(`/api/v1/admin/reviews/${id}`, config)

        dispatch({
            type: DELETE_REVIEW_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETE_REVIEW_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

// CLEAR ERRORS
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}
import React from 'react'
import { Link } from 'react-router-dom'

const CheckoutSteps = ({shipping, confirmOrder, payment, currState}) => {
  const { toShip, shippingInfo } = currState;
  return (
    <div className="checkout-progress d-flex justify-content-center mt-3 mb-5">
        {shipping && toShip ? <Link to="/shop/checkout/shipping" state={ {toShip} } className='step active-step'>Shipping</Link> 
        : <span className="step inactive-step" disabled>Shipping</span>
        } 

        {confirmOrder ? <Link to="/shop/checkout/confirm-order" state={ {shippingInfo, toShip} } className='step active-step'>Confirm Order</Link> 
        : <span className="step inactive-step">Confirm Order</span>
        }

        {payment ? <Link to="/shop/checkout/payment" state={ {toShip} } className='step active-step'>Payment</Link> 
        : <span className="step inactive-step">Payment</span>
        }
    </div>
  )
}

export default CheckoutSteps
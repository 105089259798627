import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import AdminNavigation from '../layout/adminNavigation'
import { costFormatter, numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'

import { getCourses } from '../../actions/courseActions'
import { getRecipes } from '../../actions/recipeActions'
import { getClasses } from '../../actions/classActions'
import { getAllUsers  } from '../../actions/userActions'


const Dashboard = () => {
    const dispatch = useDispatch();

    const { courses } = useSelector(state => state.getCourses);
    const { recipes } = useSelector(state => state.getRecipes);
    const { classes, loading } = useSelector(state => state.getClasses);
    const { users } = useSelector(state => state.allUsers );

    let activeCourse = []

    let courseVids = courses.map(course => course.courseVideos)
    
    for (let course of courseVids) {
        for (let video of course) {
            if (video.unlocked) {
                activeCourse.push(video)
                break
            }
        }
    }

    useEffect(() => {
        dispatch(getCourses("Admin"));
        dispatch(getRecipes("Admin"));
        dispatch(getClasses("Admin"));
        dispatch(getAllUsers());

    }, [dispatch])

    return (
        <Fragment>
            <Metadata title={'Dashboard'}/>
            <AdminNavigation Dashboard/>
            <div className="container-fluid container-md">
                <Fragment>
                    <div className="d-flex align-items-center mt-4 mb-4">
                        <h3 className="text-xs text-muted d-inline-block m-0 me-4 bold-2">DASHBOARD</h3>
                    </div>

                    <div className="row mb-5">
                        {/* COURSES */}
                        <div className="col-md-6">  
                            <div className="dash-box mb-3">
                                <div className="p-3 pb-0 text-muted text-xxs bold-2">
                                    <p>COURSES</p>
                                </div>

                                <div className="d-flex flex-row justify-content-center mb-3 mb-md-4">
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="text-4xl bold-2 mb-1">{(courses.filter(course => course.price > 0)).length}</p>
                                        <p className="text-xxs">Paid</p>
                                    </div>

                                    <div className="dash-sep"></div>
                                    
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="text-4xl bold-2 mb-1">{(courses.filter(course => course.price === 0)).length}</p>
                                        <p className="text-xxs">Free</p>
                                    </div>

                                    <div className="dash-sep"></div>

                                    <div className="d-flex flex-column align-items-center">
                                        <p className="text-4xl bold-2 mb-1">{activeCourse.length}</p>
                                        <p className="text-xxs">Active</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* CLASSES */}
                        <div className="col-md-6">
                            <div className="dash-box mb-3">
                                <div className="p-3 pb-0 text-muted text-xxs bold-2">
                                    <p>CLASSES</p>
                                </div>

                                <div className="d-flex flex-row justify-content-center mb-3 mb-md-4">
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="text-4xl bold-2 mb-1">{(classes.filter(masterclass => masterclass.level === "Free")).length}</p>
                                        <p className="text-xxs">Free</p>
                                    </div>

                                    <div className="dash-sep mx-4 mx-xl-5"></div>
                                    
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="text-4xl bold-2 mb-1">{(classes.filter(masterclass => masterclass.level === "Bronze")).length}</p>
                                        <p className="text-xxs">Bronze</p>
                                    </div>

                                    <div className="dash-sep mx-4 mx-xl-5"></div>

                                    <div className="d-flex flex-column align-items-center">
                                        <p className="text-4xl bold-2 mb-1">{(classes.filter(masterclass => masterclass.level === "Silver")).length}</p>
                                        <p className="text-xxs">Silver</p>
                                    </div>
                                    
                                    <div className="dash-sep mx-4 mx-xl-5"></div>
                                    
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="text-4xl bold-2 mb-1">{(classes.filter(masterclass => masterclass.level === "Gold")).length}</p>
                                        <p className="text-xxs">Gold</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* RECIPES */}
                        <div className="col-md-6">
                            <div className="dash-box mb-3">
                                <div className="p-3 pb-0 text-muted text-xxs bold-2">
                                    <p>RECIPES</p>
                                </div>

                                <div className="d-flex flex-row justify-content-center mb-3 mb-md-4">
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="text-4xl bold-2 mb-1">{(recipes.filter(recipe => recipe.category === "Simple breakfast")).length}</p>
                                        <p className="text-xxs">Breakfast</p>
                                    </div>

                                    <div className="dash-sep mx-4 mx-xl-5"></div>
                                    
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="text-4xl bold-2 mb-1">{(recipes.filter(recipe => recipe.category === "Nigerian soups")).length}</p>
                                        <p className="text-xxs">Soups</p>
                                    </div>

                                    <div className="dash-sep mx-4 mx-xl-5"></div>

                                    <div className="d-flex flex-column align-items-center">
                                        <p className="text-4xl bold-2 mb-1">{(recipes.filter(recipe => recipe.category === "Plantain recipes" || recipe.category === "Chicken recipes" || recipe.category === "Pasta recipes")).length}</p>
                                        <p className="text-xxs">Main</p>
                                    </div>
                                    
                                    <div className="dash-sep mx-4 mx-xl-5"></div>
                                    
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="text-4xl bold-2 mb-1">{(recipes.filter(recipe => recipe.category === "OmoyeCooks special recipes")).length}</p>
                                        <p className="text-xxs">Special</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* USERS */}
                        <div className="col-md-6">  
                            <div className="dash-box mb-3">
                                <div className="p-3 pb-0 text-muted text-xxs bold-2">
                                    <p>USERS</p>
                                </div>

                                <div className="d-flex flex-row justify-content-center mb-3 mb-md-4">
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="text-4xl bold-2 mb-1">{(users.filter(user => user.userStatus === "Verified")).length}</p>
                                        <p className="text-xxs">Active</p>
                                    </div>

                                    <div className="dash-sep mx-4 mx-lg-5"></div>
                                    
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="text-4xl bold-2 mb-1">{(users.filter(user => user.courses && user.courses.length > 0)).length}</p>
                                        <p className="text-xxs">Enrolled</p>
                                    </div>

                                    <div className="dash-sep mx-4 mx-lg-5"></div>

                                    <div className="d-flex flex-column align-items-center">
                                        <p className="text-4xl bold-2 mb-1">{(users.filter(user => user.subscription !== "Free")).length}</p>
                                        <p className="text-xxs">Subscribed</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                
                </Fragment>                              
            </div>
        </Fragment>
    )
}

export default Dashboard
import React, { Fragment, useEffect, useState } from 'react';
import AWS from 'aws-sdk';

import { Link, useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Metadata from '../layout/metadata'
import AdminNavigation from '../layout/adminNavigation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { CREATE_PRODUCT_RESET } from '../../constants/productConstants'
import { newProduct, clearErrors } from '../../actions/productActions'

const BUCKET = process.env.REACT_APP_AWS_BUCKET
const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const accelerateEndpoint = process.env.REACT_APP_AWS_ACCELERATE_ENDPOINT;
const cloudfront = process.env.REACT_APP_AWS_CLOUDFRONT

const s3 = new AWS.S3({
  accessKeyId,
  secretAccessKey,
  signatureVersion: 'v4',
  accelerateEndpoint
});

function getProductKey() {
  const characters = "1234567890abcdefghijklmnopqrstuvwxyz";
  let key = "";
  for (let i = 0; i < 8; i++) {
      key += characters[Math.floor( Math.random() * characters.length )];
  }
  return key;
};

let previousLength = 0;

const AddProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const categories = [
    'Ebooks',
    'Spices',
    'Cookbooks',
    'Tshirts',
    'Utensils',
    'Services',
  ]

  const packages = [
      'Aluminium Foil',
      'Basket',
      'Crate',
      'None',
      'Paper Box',
      'Plastic Box',
      'Wooden Box',
      'Poly Bag',
      'Others'
  ]

  const { error, success } = useSelector(state => state.modifyProduct)
  
  const [doc, setDoc] = useState();
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [state, setState] = useState("Idle")

  const [product, setProduct] = useState({
    key: getProductKey(),
    name: "",
    category:  "", 
    deliveryType:  "", 
    packaging: "",
    price:  "",
    weight:  "",
    stock:  "",
    description:  "",
    keyFeatures:  "",
    images: [],
    doc: {}
})

  const { key, name, category, deliveryType, packaging, price, weight, stock, description, keyFeatures } = product;

  useEffect(() => {
    if (error) {
        toast.error("An error occurred, please check your internet connection, refresh, and try again.")
        dispatch(clearErrors())
    }
    if (success) {
        toast.success("Successfully added Product 👍")
        dispatch({ type: CREATE_PRODUCT_RESET })
        setState("Idle")
        navigate(`/admin/products`)
    }
}, [dispatch, error, success])



  const nameInputHandler = (event) => {
    const textRegex = /^[a-zA-Z0-9\s]+$/;
    if (textRegex.test(event.target.value)) {
      setProduct({...product, [event.target.name]: event.target.value });
    }
  };

  const handleBulletInput = (event) => {
    const bullet = "\u2022";
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);

    if (newLength > previousLength) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${bullet} `;
      } else if (newLength === 1) {
        event.target.value = `${bullet} ${event.target.value}`;
      }
    }
    
    previousLength = 0;
    setProduct({...product, [event.target.name]: event.target.value });
  };

  const productOnChange = (event) => {
    if (event.target.value === "Ebooks") product.deliveryType = "Download"
    setProduct({...product, [event.target.name]: event.target.value });
  };

  const onImageChange = e => {

    const files = Array.from(e.target.files)

    setImagesPreview([])
    setImages([])

    if (files.length > 5) {
      e.target.value = "";
      return toast.error(`You can select a maximum of 5 files.`);
    }

    setImages(files)

    let imageDetails = files.map(file => ({
      name: file.name,
      type: file.type,
      size: (parseFloat(file.size) * 0.0000009537).toFixed(2) + "MB",
      url: `${cloudfront}/Products/${key}/${file.name}`
    }))

    setProduct({...product, ["images"]: imageDetails });

    files.forEach(file => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImagesPreview(oldArray => [...oldArray, reader.result])
            }
        }
        reader.readAsDataURL(file)
    })
  };
  const onDocChange = e => {
    let docDetails = {}

    const file = e.target.files[0]
    setDoc(file)

    if (file) { 
      docDetails = {
        name: file.name,
        type: file.type,
        size: (parseFloat(file.size) * 0.0000009537).toFixed(2) + "MB",
        url: `${cloudfront}/Products/${key}/${file.name}`
      }
    }

    setProduct({...product, ["doc"]: docDetails });
  };

const submitHandler = async (e) => {
  e.preventDefault()
  setState("Working")
  try {
    // UPLOAD IMAGES
    for (let image of images) {
      const params = {
        Bucket: BUCKET,
        Key: `Products/${key}/${image.name}`,
        Body: image,
      };
  
      await s3.upload(params).promise();
    }

    // UPLOAD DOCS
    if (doc) {
      const params = {
        Bucket: BUCKET,
        Key: `Products/${key}/${doc.name}`,
        Body: doc,
      };
  
      await s3.upload(params).promise();
    }

  } catch (error) {
    toast.error("Couldn't upload images or document, proceeding to store product data.")
  } finally {
    dispatch(newProduct(product))
  }
}

  return (
    <Fragment>
      <Metadata title={'Add Product'} />
      <AdminNavigation Products/>
      <ToastContainer />
      <div className="container-fluid container-md bg-light shadow-lg p-0 mb-4">
        <div className="bg-dark border rounded p-3">
          <h3 className="text-sm text-light m-0">Add Product</h3>
        </div>

        <div className="p-3">
          <Link to="/admin/products" className="text-dark text-xs">{"< Back to Products"}</Link>
        </div>

        <form onSubmit={submitHandler} className="p-3 pb-5">
          <div className="row">

            {/* PRODUCT NAME */}
            <div className="col-md-6 mb-4">
              <label htmlFor="name" className="form-label">Product Name</label><i className="red">*</i>
              <strong><i id="nameHelp" className="ms-2 red text-xxs">(Cannot exceed 40 characters)</i></strong>
              <input 
                  type="text" 
                  className="form-control" 
                  id="name"
                  name='name'
                  value={name} 
                  onChange={nameInputHandler}
                  aria-describedby="nameHelp" 
                  placeholder="E.g. Tomatoes"
                  required
                  readOnly={state === "Working"}
              />
            </div>

            {/* PRODUCT CATEGORY */}
            <div className="col-md-6 mb-4">
                <label htmlFor="category" className="form-label">Product Category</label><i className="red">*</i>
                <select 
                    name="category" 
                    id="category" 
                    className="form-control"
                    value={category} 
                    onChange={productOnChange}
                    required
                    disabled={state === "Working"}
                    >
                    <option value="">Choose a Product Category</option>
                    {categories.map(category => (
                        <option key={category} value={category}>{category}</option>
                    ))}
                </select>
            </div>

            {/* DELIVERY TYPE */}
            <div className="col-md-6 mb-4">
                <label htmlFor="deliveryType" className="form-label">Delivery Type</label><i className="red">*</i>
                <select 
                    name="deliveryType"
                    id="deliveryType"
                    className="form-control"
                    value={deliveryType}
                    onChange={productOnChange}
                    required
                    disabled={state === "Working" || product.category === "Ebooks"}
                    >
                    <option value="">Choose Delivery Type</option>
                    <option value="Shipping">Shipping</option>
                    <option value="Download">Download</option>
                </select>
            </div>

            {/* PRODUCT PRICE */}
            <div className="col-md-6 mb-4">
                <label htmlFor="price" className="form-label">Product Price (&#x20A6;)</label><i className="red">*</i>
                <input 
                    type="number"
                    className="form-control"
                    id="price"
                    name='price'
                    value={price}
                    onChange={productOnChange}
                    placeholder="E.g. 37000"
                    required
                    readOnly={state === "Working"}
                />
            </div>

            { deliveryType === "Download" ?
            <Fragment></Fragment>:
            <Fragment>
              {/* PRODUCT PACKAGING */}
              <div className="col-md-4 mb-4">
                  <label htmlFor="packaging" className="form-label">Product Packaging</label><i className="red">*</i>
                  <select 
                      name="packaging" 
                      id="packaging" 
                      className="form-control"
                      value={packaging} 
                      onChange={productOnChange}
                      required
                      disabled={state === "Working"}
                      >
                      <option value="">Choose Packaging Type</option>
                      {packages.map(packaging => (
                          <option key={packaging} value={packaging}>{packaging}</option>
                      ))}
                  </select>
              </div>


              {/* PRODUCT WEIGHT */}
              <div className="col-md-4 mb-4">
                  <label htmlFor="weight" className="form-label">Product Weight (Kg)</label><i className="red">*</i>
                  <input 
                      type="number" 
                      className="form-control" 
                      id="weight" 
                      name='weight'
                      value={weight} 
                      onChange={productOnChange}
                      placeholder="E.g. 85"
                      required
                      readOnly={state === "Working"}
                  />
              </div>

              {/* UNITS IN STOCK */}
              <div className="col-md-4 mb-4">
                  <label htmlFor="stock" className="form-label">Product Stock (Units in Stock)</label><i className="red">*</i>
                  <input 
                      type="number" 
                      className="form-control" 
                      id="stock" 
                      name='stock'
                      value={stock} 
                      onChange={productOnChange}
                      placeholder="E.g. 50"
                      required
                      readOnly={state === "Working"}
                  />
              </div>
            </Fragment>            
            }

            {/* PRODUCT DESCRIPTION */}
            <div className="col-md-6 mb-4">
                <label htmlFor="description" className="form-label">Product Description</label><i className="red">*</i>
                <textarea 
                    className="form-control" 
                    id="description" 
                    name='description'
                    rows="5" 
                    value={description} 
                    onChange={productOnChange}
                    placeholder="Describe the product"
                    required
                    readOnly={state === "Working"}
                />
            </div>

            {/* KEY FEATURES */}
            <div className="col-md-6 mb-4">
              <label htmlFor="keyFeatures" className="form-label">Key Features</label><i className="red">*</i>
              <textarea 
                  className="form-control" 
                  id="keyFeatures" 
                  name="keyFeatures"
                  rows="5" 
                  value={keyFeatures} 
                  onInput={handleBulletInput}
                  placeholder="Enter key features..."
                  readOnly={state === "Working"}
                  required
              />
            </div>  

            {/* PRODUCT IMAGE */}
            <div className="col-12 mb-4">
                <label htmlFor="productImage" className="form-label mb-3">Product Images</label><i className="red">*</i>
                <strong><i id="productImageHelp" className="ms-2 red text-xxs">(Max 5 images)</i></strong>
                <div className="d-flex align-items-center">
                    {imagesPreview.map(image => (
                        <figure key={image} className="m-0 me-2 p-0">
                            <img 
                                src={image}
                                alt="Product Images Preview"
                                key={image}
                                className="round-preview" 
                            />
                        </figure>
                    ))}

                    <div className="w-100">
                        <input 
                            type="file"
                            name="images"
                            className="form-control text-xs w-100"
                            id="images"
                            onChange={onImageChange}
                            multiple
                            accept="image/*"
                            required
                            disabled={state === "Working"}
                        />
                    </div>
                </div>
            </div>

            {/* PRODUCT DOC */}
            <div className="col-12 mb-5">
                <label htmlFor="doc" className="form-label mb-3">{deliveryType==="Download" ? "Upload PDF" : "Product Specification Document - PDF only"}</label>{deliveryType==="Download" ? <i className="red">*</i> : <i className="text-muted text-xs"> (Optional)</i>}
                <input 
                    type="file"
                    name="doc"
                    className="form-control text-xs w-100 p-2 border"
                    id="doc"
                    accept="application/pdf"
                    onChange={onDocChange}
                    required={deliveryType === "Download"}
                    disabled={state === "Working"}
                />
            </div>

            {/* ###################################### */}

            {/* BUTTON */}
            <div className="d-flex flex-column align-items-end">
              <button className="action-btn text-xs mt-4 mt-md-0" type='submit' disabled={state === "Working"}>{state === "Working" ? "Adding product..." : "Add product"}</button>
            </div>
          </div>

        </form>

      </div>
    </Fragment>
  );
};

export default AddProduct;

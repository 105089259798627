import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { S3 } from 'aws-sdk';

import Metadata from '../layout/metadata'
import AdminNavigation from '../layout/adminNavigation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NEW_CLASS_RESET } from '../../constants/classConstants'
import { newClass, clearErrors } from '../../actions/classActions'

const s3 = new S3({
  accessKeyId: 'AKIAYYMZD6RFCPY6MNSC',
  secretAccessKey: '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'
});

function getClassKey() {
  const characters = "1234567890abcdefghijklmnopqrstuvwxyz";
  let key = "";
  for (let i = 0; i < 8; i++) {
      key += characters[Math.floor( Math.random() * characters.length )];
  }
  return key;
};

let key = getClassKey();

let uploadId = "";
let count = 0;
let state = "Idle"
let type = ""
let size = ""
let thumbnailURL = ""
let videoURL = ""

const BUCKET = 'omoyecooksmasterclass'

const AddClass = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error, success } = useSelector(state => state.newClass)

  const [name, setName] = useState('');
  const [level, setLevel] = useState('');
  const [description, setDescription] = useState('');
    const levels = [
    'Free',
    'Gold',
    'Silver',
    'Bronze'
    ]
  const [thumbnail, setThumbnail] = useState(null);
  const [video, setVideo] = useState(null);
  
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatusText, setUploadStatusText] = useState("");

  useEffect(() => {
    if (error) {
        toast.error("An error occurred, please check your internet connection, refresh, and try again.")
        console.log(error, "ERRORRR - addClass.jsx")
        dispatch(clearErrors())
        state = "Idle"
    }
    if (success) {
        toast.success("Successfully added Class 👍")
        dispatch({ type: NEW_CLASS_RESET })
    }
}, [dispatch, error, success])

  const startUpload = () => {
    setUploadStatusText("Preparing to upload...")
    const params = {
      Bucket: BUCKET,
      Key: `Classes/${key}/${video.name}`,
      ContentType: video.type
    };
    s3.createMultipartUpload(params, (err, data) => {
      if (err) {
        console.log(err)
        toast.error("An error occurred, please check your internet connection, refresh, and try again.");
        dispatch(clearErrors());
        state = "Idle";
      } else {
          uploadId = data.UploadId
      uploadParts(video);

      }
    });
  };

  const uploadParts = async () => {

    // UPLOAD VIDEO
    const partSize = 5 * 1024 * 1024; // 5MB
    const totalParts = Math.ceil(video.size / partSize);

    const parts = Array.from({ length: totalParts }, (_, i) => {
      const start = i * partSize;
      const end = Math.min((i + 1) * partSize, video.size);

      return {
        partNumber: i + 1,
        body: video.slice(start, end)
      };
    });

    setUploadStatusText("Uploading...")

    // UPLOAD IMAGE
    if (thumbnail) {
      const params = {
        Bucket: BUCKET,
        Key: `Classes/${key}/${thumbnail.name}`,
        Body: thumbnail,
      };
      const uploadData = await s3.upload(params).promise();
      thumbnailURL = uploadData.Location
    }

    parts.forEach(({ partNumber, body }, index) => {

      const params = {
        Bucket: BUCKET,
        Key: `Classes/${key}/${video.name}`,
        UploadId: uploadId,
        PartNumber: partNumber,
        Body: body
      };

      s3.uploadPart(params, (err, data) => {
        if (err) {
          console.log(err)
          toast.error("An error occurred, please check your internet connection, refresh, and try again.");
          dispatch(clearErrors());
          state = "Idle";
        } else {
          setUploadProgress(prevProgress => prevProgress + (100 / totalParts));
          count += 1
          if (count === totalParts) {
            completeUpload();
          }
        }
      }); 
    });
  };

  const completeUpload = async () => {
    setUploadStatusText("Stitching...")
    const listParams = {
      Bucket: BUCKET,
      Key: `Classes/${key}/${video.name}`,
      UploadId: uploadId,
    };

    const { Parts } = await s3.listParts(listParams).promise();
 
    const partsToComplete = Parts.map(part => (({ ETag, PartNumber }) => ({ ETag, PartNumber }))(part));

    const completeParams = {
      Bucket: BUCKET,
      Key: `Classes/${key}/${video.name}`,
      MultipartUpload: { Parts: partsToComplete},
      UploadId: uploadId
    }
    s3.completeMultipartUpload(completeParams, (err, data) => {
      if (err) {
        console.log(err)
        toast.error("An error occurred, please check your internet connection, refresh, and try again.");
        dispatch(clearErrors());
        state = "Idle";
      } else {
        count = 0;
        state = "Done"
        setUploadStatusText("Upload completed successfully.")
        videoURL = data.Location

        const classData = {
          key: key,
          name: name,
          level: level,
          description: description,
          type: type,
          size: size,
          thumbnailURL: thumbnailURL,
          videoURL: videoURL,
        }

        dispatch(newClass(classData))

      }})

  };

  const nameInputHandler = (e) => {
    const textRegex = /^[a-zA-Z0-9\s]+$/;
    if (textRegex.test(e.target.value)) {
      setName(e.target.value);
    }
  }

  const onChangeHandler = (e) => {
    const uploadedFile = e.target.files[0]
    state = "Idle"
    if (uploadedFile) {
      type = uploadedFile.type
      size = (parseFloat(uploadedFile.size) * 0.0000009537).toFixed(2) + "MB"
    }
    setVideo(uploadedFile)

    console.log(uploadedFile, size)
  }

  const onDone = () => {
    state = "Idle"
    navigate('/admin/classes')
  }

  const submitHandler = (e) => {
    e.preventDefault()
    state = "Working"
    startUpload();
  }

  return (
    <Fragment>
      <Metadata title={'Add Class'} />
      <AdminNavigation Classes/>
      <ToastContainer />
      <div className="container-fluid container-md shadow-lg p-0 mb-4">
        <div className="bg-dark border rounded p-3">
          <h3 className="text-sm text-light m-0">Add Class</h3>
        </div>

        <div className="p-3">
          <Link to="/admin/classes" className="text-dark text-xs">{"< Back to Classes"}</Link>
        </div>

        <form onSubmit={submitHandler} className="p-3 pb-5">
          <div className="row">

            {/* VIDEO NAME */}
            <div className="col-md-6 mb-4">
            <label htmlFor="name" className="form-label">Video Name</label><i className="red-color">*</i>
                <input 
                    type="text" 
                    className="form-control" 
                    id="name"
                    name='name'
                    value={name} 
                    onChange={nameInputHandler}
                    aria-describedby="nameHelp" 
                    placeholder="E.g. Baking Class"
                    readOnly={state === "Working"}
                    required
                />
            </div>

            {/* LEVEL */}
            <div className="col-md-6 mb-4">
                <label htmlFor="level" className="form-label">Class Level</label><i className="red-color">*</i>
                <select 
                    name="level" 
                    id="level" 
                    className="form-control"
                    value={level} 
                    onChange={(e) => setLevel(e.target.value)}
                    disabled={state === "Working"}
                    required
                    >
                    <option value="">Choose class level</option>
                    {levels.map(level => (
                        <option key={level} value={level}>{level}</option>
                    ))}
                </select>
            </div>

            {/* DESCRIPTION */}
            <div className="col-12 mb-4">
                <label htmlFor="description" className="form-label">Description</label><i className="red-color">*</i>
                <textarea 
                    className="form-control" 
                    id="description" 
                    name='description'
                    rows="2" 
                    value={description} 
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Describe class..."
                    readOnly={state === "Working"}
                    required
                />
            </div>     

            {/* THUMBNAIL */}
            <div className="col-md-6 mb-4">
              <label htmlFor="thumbnailPreview" className="form-label">Select Thumbnail</label>
              <input 
                type="file"
                name="thumbnail"
                className="form-control"
                id="thumbnail"
                onChange={(e) => setThumbnail(e.target.files[0])}
                multiple
                accept="image/*"
                disabled={state === "Working"}
              />

            </div>

            {/* VIDEO UPLOAD */}
            <div className="col-md-6 video">
              <label htmlFor="video" className="form-label">Select Video</label><i className="red-color">*</i>
              <input 
                  type="file" 
                  className="form-control" 
                  id="video"
                  name='video'
                  accept="video/*"
                  onChange={onChangeHandler}
                  disabled={state === "Working"}
                  aria-describedby="videoHelp" 
                  required
              />
            </div>

            {/* BUTTON */}
            <div className="d-flex flex-column align-items-end">
              { state === "Done" ?  
                <button type="button" onClick={onDone} className="upload-btn mt-4 mt-md-0">Done</button> :
              state === "Working" ? 
              <Fragment>
                <div className="d-block progress-bar">
                  <div className="inner-bar" style={{width: `${uploadProgress}%`}}>
                    <strong className='text-warning'>{Math.round(uploadProgress)}%</strong>
                  </div>
                </div>
                <i className="d-block mt-2 text-sm">{uploadStatusText}</i>
              </Fragment>
              : <button className="upload-btn mt-4 mt-md-0"
                  type='submit'
                  disabled={!video || state === "Working"}
                >Upload</button>
              }
            </div>
        
          </div>

        </form>

      </div>
    </Fragment>
  );
};

export default AddClass;

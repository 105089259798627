import axios from 'axios'
import { 
    CREATE_ORDER_REQUEST,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAIL,
    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAIL,
    DELETE_ORDER_REQUEST,
    DELETE_ORDER_SUCCESS,
    DELETE_ORDER_FAIL,
    GET_ORDERS_REQUEST,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_FAIL,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,
    CLEAR_ERRORS
} from '../constants/orderConstants'

export const createOrder = (order) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CREATE_ORDER_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/shop/orders/new', order, config)

        dispatch({
            type: CREATE_ORDER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CREATE_ORDER_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateOrderStatus = (newStatus, id) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_ORDER_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/orders/${id}`, {newStatus: newStatus}, config)

        dispatch({
            type: UPDATE_ORDER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_ORDER_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getBuyerOrders = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_ORDERS_REQUEST,
        })

        const { data } = await axios.get('/api/v1/shop/orders')

        dispatch({
            type: GET_ORDERS_SUCCESS,
            payload: data.buyersOrders
        })

    } catch (error) {
        dispatch({
            type: GET_ORDERS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getSellerOrders = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_ORDERS_REQUEST,
        })

        const { data } = await axios.get('/api/v1/shop/seller/orders')

        dispatch({
            type: GET_ORDERS_SUCCESS,
            payload: data.sellersOrders
        })

    } catch (error) {
        dispatch({
            type: GET_ORDERS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getAllOrders = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_ORDERS_REQUEST,
        })

        const { data } = await axios.get('/api/v1/admin/orders')

        dispatch({
            type: GET_ORDERS_SUCCESS,
            payload: data.allOrders
        })

    } catch (error) {
        dispatch({
            type: GET_ORDERS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}
export const getAdminOrderDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: ORDER_DETAILS_REQUEST })
        const { data } = await axios.get(`/api/v1/admin/orders/${id}`)

        dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload: data.order
        })
    } catch (error) {
        dispatch({
            type: ORDER_DETAILS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}
export const getSellerOrderDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: ORDER_DETAILS_REQUEST })
        const { data } = await axios.get(`/api/v1/shop/seller/orders/${id}`)

        dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload: data.order
        })
    } catch (error) {
        dispatch({
            type: ORDER_DETAILS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}
export const deleteOrderData = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_ORDER_REQUEST })
        const { data } = await axios.delete(`/api/v1/shop/orders/${id}`)

        dispatch({
            type: DELETE_ORDER_SUCCESS,
            payload: data.order
        })
    } catch (error) {
        dispatch({
            type: DELETE_ORDER_FAIL,
            payload: error.response.data.errMessage
        })
    }
}
export const getOrderDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: ORDER_DETAILS_REQUEST })
        const { data } = await axios.get(`/api/v1/shop/orders/${id}`)

        dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload: data.order
        })
    } catch (error) {
        dispatch({
            type: ORDER_DETAILS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

// CLEAR ERRORS
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}
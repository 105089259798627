import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Metadata from '../layout/metadata'
import AdminNavigation from '../layout/adminNavigation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { getCourseDetails, clearErrors } from '../../actions/courseActions'
import { arrayRemove } from '../../Functions/functions';

import UploadFile from './uploadCourse'


const ChangeVideos = () => {
    const dispatch = useDispatch();
    const params = useParams();

    let isChange = useRef(false);

    let allFiles = useRef([])
    let startFroms = useRef([])

    let prevFile = useRef({})
    let courseFiles = useRef({})

    let imagesPrev = useRef({})
    let videosPrev = useRef({})

    const [imagePreview, setImagePreview] = useState();
    const [videoPreview, setVideoPreview] = useState();

    const [imagesPreview, setImagesPreview] = useState({});
    const [videosPreview, setVideosPreview] = useState({});

    const [uploadStatus, setUploadStatus] = useState("Idle");
    const [showUploadFile, setShowUploadFile] = useState(false);
    const uploadFileRef = useRef(null);

    const { error, course } = useSelector(state => state.courseDetails);

    useEffect(() => {
        dispatch(getCourseDetails(params.id, "Admin"));

        if (showUploadFile && uploadFileRef.current) {
            uploadFileRef.current.startUpload();
        }

        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }
    }, [dispatch, error, showUploadFile, uploadFileRef])

    function getStatus(uploadStatus) {
        setUploadStatus(uploadStatus)
    };

    function onPrevThumbnailSelect(event) {
        isChange.current = true;
        let file = event.target.files[0]
        if (!file) {
            setImagePreview(course.prevVideo.thumbnailURL);
            if (!prevFile.current.video) startFroms.current = arrayRemove(startFroms.current, 0)
            return delete prevFile.current.image
        }

        prevFile.current.image = file
        if (!startFroms.current.includes(0)) startFroms.current.unshift(0);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            setImagePreview(event.target.result);
        };
    };

    function onPrevVideoSelect(event) {
        isChange.current = true;
        let file = event.target.files[0]
        if (!file) {
            setVideoPreview(course.prevVideo.videoURL);
            if (!prevFile.current.image) startFroms.current = arrayRemove(startFroms.current, 0)
            return delete prevFile.current.video
        }

        prevFile.current.video = file
        if (!startFroms.current.includes(0)) startFroms.current.unshift(0);

        const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
            setVideoPreview(event.target.result);
        };
    };

    function onCourseThumbnailsSelect(event, position) {
        isChange.current = true;

        let file = event.target.files[0]
        let imagePosition = "position" + position

        if (!file) {
            delete imagesPrev.current[imagePosition];
            if (courseFiles.current[imagePosition]) courseFiles.current[imagePosition].image = null
            return setImagesPreview({...imagesPrev.current})
        }

        courseFiles.current[imagePosition] ? courseFiles.current[imagePosition].image = file : courseFiles.current[imagePosition] = { image: file }

        const reader = new FileReader();
        reader.readAsDataURL(courseFiles.current[imagePosition].image);
        reader.onload = (event) => {
            imagesPrev.current[imagePosition] = event.target.result;
            setImagesPreview({...imagesPrev.current})
        };
    };

    function onCourseVideoSelect(event, position) {
        isChange.current = true;
        let file = event.target.files[0]
        let videoPosition = "position" + position

        if (!file) {
            delete videosPrev.current[videoPosition]
            if (courseFiles.current[videoPosition]) courseFiles.current[videoPosition].video = null
            return setVideosPreview({...videosPrev.current})
        }

        courseFiles.current[videoPosition] ? courseFiles.current[videoPosition].video = file : courseFiles.current[videoPosition] = { video: file }


        const reader = new FileReader();
        reader.readAsDataURL(courseFiles.current[videoPosition].video);
        reader.onload = (event) => {
            videosPrev.current[videoPosition] = event.target.result;
            setVideosPreview({...videosPrev.current})
        };
    };

    function handleButtonClick() {
        if (Object.keys(prevFile.current).length > 0) allFiles.current.unshift(prevFile.current)
        for (const key in courseFiles.current) {
            const position = parseInt(key.replace('position', ''));

            if( !courseFiles.current[key].image && !courseFiles.current[key].video ) continue;
            startFroms.current.push(position);
            allFiles.current.push(courseFiles.current[key]);
        }
        setShowUploadFile(true);
    }

    return (
        <Fragment>
            <Metadata title={'Change Videos'} />
            <AdminNavigation Academy/>
            <ToastContainer />
            <div className="container-fluid container-md bg-light shadow-lg p-0 pb-5 mb-4">
            <div className="bg-dark border rounded p-3">
                <h3 className="text-sm text-light m-0">Change videos of {course.name}</h3>
            </div>

            <div className="p-3">
                <Link to={`/admin/academy/${params.id}`} className="text-dark text-xs">{"< Back to Course"}</Link>
            </div>

            {/* PREVIEW VIDEO */}
            <div className="row m-0 p-0">
                <h5 className="form-label my-3">Preview Video</h5>
                {course.prevVideo ?
                    <div className="col-md-4 text-center">
                    <div className="row g-1 px-1 py-3 mb-3 border">
                        <div className="col-6 text-center">
                            <div className="video-box" style={{backgroundImage: `url(${imagePreview ? imagePreview : course.prevVideo.thumbnailURL ? course.prevVideo.thumbnailURL : "/images/no-image-icon.png"})`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                            <input
                                type="file"
                                name="prevThumbnail"
                                className="form-control mt-1"
                                id="prevThumbnail"
                                onChange={onPrevThumbnailSelect}
                                accept="image/*"
                                disabled={false}
                                aria-describedby="prevThumbnailHelp" 
                            />
                        </div>

                        <div className="col-6 text-center">
                            <video id="video-preview" className="bg-dark video-box" src={videoPreview ?  videoPreview : course.prevVideo.videoURL} controls></video>
                            <input 
                                type="file" 
                                className="form-control" 
                                id="prevVideo"
                                name='prevVideo'
                                accept="video/*"
                                onChange={onPrevVideoSelect}
                                disabled={false}
                                aria-describedby="prevVideoHelp" 
                            />
                        </div>
                    </div>
                    </div>
                : <div><p className="text-xs">No videos</p></div>}
            </div>

            {/* ###################################### */}
            {/* COURSE VIDEOS */}
            <div className="row m-0 p-0">
                <h5 className="form-label my-3">Course Videos</h5>
                {course.courseVideos && course.courseVideos.map(video => 
                    <div key={video.videoPosition} className="col-md-4 text-center">
                        <div className="row g-1 px-1 py-3 border">
                            <div className="col-6 text-center">
                                <div className="video-box" style={{backgroundImage: `url(${imagesPreview["position" + video.videoPosition] ? imagesPreview["position" + video.videoPosition] : video.thumbnailURL ? video.thumbnailURL : "/images/no-image-icon.png"})`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                                <input
                                    type="file"
                                    name="courseThumbnail"
                                    alt={video.videoPosition}
                                    className="form-control mt-3"
                                    id="courseThumbnail"
                                    onChange={(event) => onCourseThumbnailsSelect(event, video.videoPosition)}
                                    accept="image/*"
                                    disabled={false}
                                    aria-describedby="courseThumbnailHelp" 
                                />

                            </div>

                            <div className="col-6 text-center">
                                <video id="video-preview" className="bg-dark video-box" src={videosPreview["position" + video.videoPosition] ? videosPreview["position" + video.videoPosition] : video.videoURL ? video.videoURL : ""} controls></video>
                                    <input 
                                    type="file" 
                                    className="form-control mt-2" 
                                    id="courseVideo"
                                    name='courseVideo'
                                    alt={video.videoPosition}
                                    accept="video/*"
                                    onChange={(event) => onCourseVideoSelect(event, video.videoPosition)}
                                    disabled={false}
                                    aria-describedby="courseVideoHelp"
                                />
                            </div>
                        </div>
                    </div>
                )}
                {course.courseVideos && course.courseVideos.length === 0 ? <div><p className="text-xs">No videos</p></div> : <Fragment></Fragment>}
            </div>


            <div className="container-fluid container-md mt-4 mb-4">
                {/* Conditional rendering to show the UploadFile component */}
                {showUploadFile && (
                <UploadFile id={params.id} filesToUpload={allFiles.current} courseKey={course.key} startFrom={startFroms.current} ref={uploadFileRef} sendStatus={getStatus}/>
                )}
            </div>

            <div className="container-fluid container-md">
                {uploadStatus === "Complete" ? <Link to="/admin/academy" className="text-xs upload-btn w-100">Done</Link>
                : uploadStatus === "Uploading" ? <button disabled className="text-xs upload-btn w-100">Uploading Files...</button>
                : <button onClick={handleButtonClick} className="text-xs upload-btn w-100" disabled={!isChange.current} >Upload Files</button> }  
            </div>

            </div>
        </Fragment>
    );
};

export default ChangeVideos;

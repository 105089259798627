import React, { Fragment } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import Metadata from '../layout/metadata'
import { numberWithCommas } from '../../Functions/functions'
import CheckoutSteps from './checkoutSteps'

import { useSelector } from 'react-redux'

const ConfirmOrder = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const shippingCosts = {
        'Ajah': 3500,
        'Agbado': 3500,
        'Agege': 3000,
        'Ajegunle': 3500,
        'Alimosho': 3000,
        'Apapa': 3000,
        'Badagry': 3500,
        'Banana Island': 3000,
        'Ebute Metta': 3000,
        'Epe': 3500,
        'Ibeju-Lekki': 3500,
        'Ikeja': 3000,
        'Ikorodu': 3500,
        'Isheri-Magodo': 3500,
        'Iyana-Paja': 3500,
        'Lagos (Eko) city': 3000,
        'Lagos Island': 3000,
        'Lagos Mainland': 3000,
        'Lekki': 3000,
        'Makoko': 3000,
        'Mushin': 3000,
        'Ojo': 3000,
        'Ojuelegba': 3000,
        'Oshodi-Isolo': 3000,
        'Sangotedo': 3500,
        'Somolu': 3000,
        'Surulere': 3000,
        'Victoria Island': 3000,
        'Yaba': 3000,
      };

    const { toShip, shippingInfo } = location.state;

    const { cartItems } = useSelector(state => state.cart)
    const { user } = useSelector(state => state.auth)


    // Calculate order prices
    const itemsPrice = cartItems.reduce((acc, item) => acc + (item.quantity * item.price), 0)
    const shippingPrice = toShip ? shippingCosts[shippingInfo.city] : 0
    const taxPrice = toShip ? Number((0.075 * itemsPrice).toFixed(2)) : 0
    const totalPrice = toShip ? (itemsPrice + shippingPrice + taxPrice).toFixed(2) : itemsPrice



    const proceedToPayment = () => {
        const data = {
            itemsPrice: itemsPrice.toFixed(2),
            shippingPrice: shippingPrice.toFixed(2),
            taxPrice: taxPrice.toFixed(2),
            totalPrice
        }

        sessionStorage.setItem('orderInfo', JSON.stringify(data))
        navigate('/shop/checkout/payment', {state: {
            amount: totalPrice,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.credential,
            phoneNumber: shippingInfo ? shippingInfo.phoneNumber : "",
            cartItems,
            shippingInfo,
            paymentMethod: "Pay Online",
            toShip
        }
        })
    }
    
  return (
    <Fragment>
        <Metadata title={'Checkout - Confirm Order'}/>

        <div className="container-md pt-4">
            <CheckoutSteps shipping confirmOrder currState={{...location.state}}/>
            <h3 className="text-2 text-muted bold-2 mt-4"><strong>Confirm Order</strong></h3>
            <hr />
            <div className="shipping-info mt-4">
                <div className="text-2"><strong>Name: </strong>{user && user.firstName} {user && user.lastName}</div>

                {toShip ? <Fragment>
                    <div className="text-2 mt-4"><strong>Address: </strong>{shippingInfo.street}, {shippingInfo.city}, {shippingInfo.state}, {shippingInfo.country}</div>
                    <div className="text-2 mt-4"><strong>Phone Number: </strong>{shippingInfo.phoneNumber}</div>
                    <div className="text-2 mt-4"><strong>Payment Method: </strong>Pay Online</div> </Fragment>
                    : <Fragment>
                        <div className="text-2 mt-4"><strong>Payment Method: </strong>Pay Online</div>
                    </Fragment>
                }

            </div>

            <h3 className="text-2 mt-5"><strong>Cart Items</strong></h3>
            <hr className="mb-4"/>
            { cartItems.map(item => (
                <Fragment key={item.product}>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center">
                            <div className="cart-img-sm me-3" style={{backgroundImage: `url('${item.image}')`, backgroundSize: 'cover'}}></div>
                            <Link to={`/product/${item.product}`} className="text-2 w-70">{item.name}</Link>
                        </div>
                        
                        <div className="d-flex flex-wrap justify-content-between w-85 ms-3 ms-md-0">
                            <div className="text-2 mt-2 mt-lg-0">{numberWithCommas(item.quantity)} x &#x20A6;{numberWithCommas(item.price)} = <strong>&#x20A6;{numberWithCommas(item.quantity * item.price)}</strong></div>
                        </div>
                        
                    </div>
                </Fragment>
            ))}

            <hr className="mt-4"/>

            <div className="mt-4">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="text-2">Subtotal:</div>
                    <div className="text-2 bold-2">&#x20A6;{numberWithCommas(itemsPrice)}</div>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="text-2">Shipping:</div>
                    <div className="text-2 bold-2">&#x20A6;{numberWithCommas(shippingPrice)}</div>
                </div>

                {toShip ? 
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <div className="text-2">VAT:</div>
                        <div className="text-2 bold-2">&#x20A6;{numberWithCommas(taxPrice)}</div>
                    </div>
                : <Fragment></Fragment>}


                <hr />
                <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="text-3 bold-2">Total:</div>
                    <div className="text-3 bold-3">&#x20A6;{numberWithCommas(totalPrice)}</div>
                </div>

                <div className="d-flex justify-content-end my-5">
                    {toShip ?
                        <Link to="/shop/checkout/shipping" state={{...location.state}} className="d-flex align-items-center justify-content-center bold-1 orange text-2 me-3">Back to Shipping</Link>
                    :   <Link to="/shop/cart" className="d-flex align-items-center justify-content-center text-2 orange bold-1 me-3">Back to Cart</Link>
                    }
                    <button onClick={proceedToPayment} className="d-flex align-items-center justify-content-center orange-btn text-2">Payment</button>
                </div>

            </div>
        </div>
    </Fragment>
  )
}

export default ConfirmOrder
import axios from 'axios';
import {
    GRANT_ACCESS_REQUEST,
    GRANT_ACCESS_SUCCESS,
    GRANT_ACCESS_FAIL,
    GET_ACCESS_LIST_REQUEST,
    GET_ACCESS_LIST_SUCCESS,
    GET_ACCESS_LIST_FAIL,
    CLEAR_ERRORS
} from '../constants/accessConstants'

export const grantAccess = (courseId, users) => async (dispatch) => {
    try {
        dispatch({ type: GRANT_ACCESS_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/access/${courseId}/new`, {users}, config)

        dispatch({
            type: GRANT_ACCESS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GRANT_ACCESS_FAIL,
            payload: error.response.data.errMessage
        })
    }
};


export const getAccessList = (courseId) => async (dispatch) => {

    try {
        dispatch({ type: GET_ACCESS_LIST_REQUEST })

        const { data } = await axios.get(`/api/v1/admin/access/${courseId}`)

        dispatch({
            type: GET_ACCESS_LIST_SUCCESS,
            payload: data.accessList
        })
    } catch (error) {
        dispatch({
            type: GET_ACCESS_LIST_FAIL,
            payload: error.response.data.errMessage
        })
    }
}
// CLEAR ERRORS
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}
import React, { Fragment, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import { initGA, logPageView } from '../../GoogleAnalytics';

import { getPromos, clearErrors } from '../../actions/promoActions'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Metadata from '../layout/metadata';
import ProductCard from './productCard';


const ProductCategories = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const currState = location.state
    const products = currState[params.category]

    const { loading, error, promos } = useSelector(state => state.getPromos);

    const activePromotions = promos.filter(promo => new Date() >= new Date(promo.startDate) && new Date() <= new Date(promo.endDate))

    useEffect(() => {
        initGA();
        logPageView();

        dispatch(getPromos());

        if (error) {
            toast.error("Error getting promos.")
            dispatch(clearErrors())
        }

    }, [dispatch]);

    const goBack = () => {
        navigate(-1)
    }

  return (
    <Fragment>
      <Metadata title={params.category} />
      <ToastContainer />

        <div className="container-md my-4 ps-md-2">
            <div onClick={goBack} className="breadcrumb text-2">{"< Go Back"}</div>
        </div>

        <div className="container-fluid container-md">
            {/* TOP PRODS */}
            <div className="shop-section mt-4 mt-md-5">
                <h5 className="bold-2 text-2">Browse our {params.category}</h5>
                <div className="underline"></div>

                    {loading ? 
                        <Fragment>
                            <div className="my-4">
                                <Skeleton count={1} height={300} baseColor="#dc720082"/>
                            </div>
                        </Fragment>:
                        <Fragment>
                            <div className="d-flex flex-wrap">
                                {products && products.filter(product => !product.hidden).map(product => (
                                    <ProductCard key={product._id} product={product} activePromotions={activePromotions} className={"wrapper-box wrapper-box-sm"}/>
                                ))}
                                {products && products.length === 0 ? <p className="text-2 bold-2">Coming real soon.</p> : <Fragment></Fragment>}
                            </div>
                        </Fragment>
                    }
            </div>

            {/* On Sale */}
            {/* <div className="shop-section">
                <h5 className="bold-2 text-3">Hurry! These are on sale</h5>
                <div className="underline"></div>
                <div className="wrapper">
                    <Link to={`/`}  key={"key"}  className="wrapper-box shadow-sm">
                        <div className="course-vid bg-white" style={{backgroundImage: `url('https://images.yaoota.com/b3rRVzPQDdTJF6dMSjtKIN8iDSY=/trim/yaootaweb-production-ng/media/crawledproductimages/1ce1de3c704fdb47f15002d1f1d0c3701e495507.jpg')`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                        <div className="text-start px-2 pb-4">
                            <p className="p-ellipsis text-2 mt-3 mb-0">Finely coated wonderful pots</p>
                            <p className="text-3 bold-2 my-2">&#x20A6;{numberWithCommas(3406)} <span className="bold-1 text-muted text-1 text-decoration-line-through">&#x20A6;{numberWithCommas(2207)}</span></p>
                            <p className="bold-2 text-1 mb-0 orange"><i className="fa-solid fa-plus"></i> Add to Cart</p>
                        </div>
                    </Link>
                </div>
            </div> */}



        </div>             
    </Fragment>
  )
}

export default ProductCategories
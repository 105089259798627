import React, { useEffect } from 'react';

const YouTubeVideo = ({ videoId }) => {
  useEffect(() => {
    const onYouTubeIframeAPIReady = () => {
      new window.YT.Player('youtube-player', {
        videoId: videoId,
        playerVars: {
          // Customize player options if needed
        }
      });
    };

    if (window.YT) {
      onYouTubeIframeAPIReady();
    } else {
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    }
  }, [videoId]);

  return <div id="youtube-player" className="youtube-vid"></div>;
};

export default YouTubeVideo;
import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { PaystackButton } from 'react-paystack'

import Metadata from '../layout/metadata'
import CheckoutSteps from './checkoutSteps'
import { numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'
import { createOrder, clearErrors } from '../../actions/orderActions'
import { CREATE_ORDER_RESET } from '../../constants/orderConstants'

import { emptyCart } from '../../actions/cartActions'

const paystackPK = process.env.REACT_APP_PAYSTACK_PK

const Payment = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { loading, success, error } = useSelector(state => state.modifyOrder)

    useEffect(() => {
        if (success) {
            dispatch({ type: CREATE_ORDER_RESET })
            dispatch(emptyCart())
            navigate('/shop/checkout/success')
        }
        if (error) {
            dispatch(clearErrors())
        }
    }, [dispatch, success, error]);
    console.log(location.state)
    const { amount, firstName, lastName, email, phoneNumber, cartItems, shippingInfo, paymentMethod, toShip } = location.state;
    const publicKey = paystackPK

    const order = {
        orderItems: cartItems,
        shippingInfo,
        paymentMethod,
    }
    
    const componentProps = {
        email: email,
        amount: Math.round(amount * 100),
        metadata: {
          name: `${firstName} ${lastName}`,
          phone: phoneNumber,
        },
        publicKey,
        text: `Pay ${"\u20A6" + numberWithCommas(location.state.amount)}`,
        onSuccess: () => {
            order.paidAt = new Date();
            dispatch(createOrder(order))
        },
        onClose: () => {}
      }



    const orderInfo = JSON.parse(sessionStorage.getItem('orderInfo'));

    if (orderInfo) {
        order.itemsPrice = orderInfo.itemsPrice
        order.shippingPrice = orderInfo.shippingPrice
        order.taxPrice = orderInfo.taxPrice
        order.totalPrice = orderInfo.totalPrice
    }

  return (
    <Fragment>
        <Metadata title={'Checkout - Payment'}/>
        <div className="container-md pt-4">
            <CheckoutSteps shipping confirmOrder payment currState={{shippingInfo, toShip}}/>
            <h3 className="text-2 text-muted bold-2 mt-4">Payment</h3>
            <hr />
            <Fragment>
                <div className="mt-4">
                    <h5 className="text-2">{paymentMethod}</h5>
                    <img src="/images/paystack.png" alt="paystack Icon" className="d-block my-4" width="100" height="18"/>
                    <div className="d-flex justify-content-end my-5">
                        {loading ? 
                        <Fragment>
                            <span className="d-flex align-items-center justify-content-center orange bold-1 text-2 me-3">Back to Confirmation</span>
                            <button className="orange-btn text-2" disabled>Creating order...</button> 
                        </Fragment>
                            :
                            <Fragment>
                                <Link to="/shop/checkout/confirm-order" state={ {shippingInfo, toShip} } className="d-flex align-items-center justify-content-center orange bold-1 text-2 me-3">Back to Confirmation</Link>
                                <PaystackButton {...componentProps} className="d-flex align-items-center justify-content-center orange-btn text-2"/>
                            </Fragment>
                        }
                    </div>
                </div>

            </Fragment>         
        </div>
    </Fragment>
  )
}

export default Payment
import { ADD_TO_CART, REMOVE_FROM_CART, EMPTY_CART, SAVE_SHIPPING_INFO, SAVE_PAYMENT_METHOD
} from '../constants/cartConstants'

export const cartReducer = (state = { cartItems: [], shippingInfo: {}, paymentMethod: "" }, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            const item = action.payload

            const itemExists = state.cartItems.find(i => i.product === item.product)

            if (itemExists) {
                return {
                    ...state,
                    cartItems: state.cartItems.map(i => i.product === itemExists.product ? item : i)
                }
            } else {
                return {
                    ...state,
                    cartItems: [...state.cartItems, item]
                }
            }

        case REMOVE_FROM_CART:
            return {
                ...state,
                cartItems: state.cartItems.filter(i => i.product !== action.payload)
            }

        case EMPTY_CART:
            return {
                ...state,
                cartItems: []
            }

        case SAVE_SHIPPING_INFO:
            return {
                ...state,
                shippingInfo: action.payload
            }

        case SAVE_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.payload
            }
            
        default:
            return state
    }
}
import React, { Fragment, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Metadata from '../layout/metadata'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { numberWithCommas } from '../../Functions/functions'
import { getProducts, clearErrors } from '../../actions/productActions'

import { initGA, logPageView } from '../../GoogleAnalytics';

import { useDispatch, useSelector } from 'react-redux'
import { addItemToCart, removeItemFromCart } from '../../actions/cartActions'

const Cart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let [ toShip, setToShip ] = useState(false)
    const [validationErrors, setValidationErrors] = useState([]);
    
    const { cartItems } = useSelector(state => state.cart)
    const { loading, error, products } = useSelector(state => state.getProducts);
    
    useEffect(() => {
        initGA();
        logPageView();

        dispatch(getProducts());

        if (error) {
            // toast.error("Error fetching validators.")
            dispatch(clearErrors())
        }

    }, [dispatch, error]);

    const removeItemFromCartHandler = (id) => {
        dispatch(removeItemFromCart(id))
        setToShip(false)
        toast.success("Item removed from cart 👍")
    }

    const increaseQty = (id, quantity, price) => {
        let stock = products.filter(product => product._id === id)
        stock = stock[0].stock || 1
        const newQty = quantity + 1;

        if (newQty > stock) return toast.error("Selected quantity is greater than stock.")

        
        dispatch(addItemToCart(id, newQty, price))
    }

    const decreaseQty = (id, quantity, price) => {
        const newQty = quantity - 1;

        if (newQty <= 0) return toast.error("You can remove the item instead.")

        
        dispatch(addItemToCart(id, newQty, price))
    }

    const checkoutHandler = () => {

        const invalidItems = cartItems.map(item => {
            const product = products.find(product => product._id === item.product);

            if (!product) {
                return `${item.name} no longer exists, please remove it from your cart`

            } else if (product.stock === 0) {
                return `${item.name} is out of stock, please remove it from your cart`

            } else if (typeof product.stock === "number" && item.quantity > product.stock) {
                return `${item.name} remains ${product.stock} unit(s), please adjust the quantity`
            }
      
            return null; // Valid item
        }).filter(item => item !== null);
        if (invalidItems.length > 0) {
            setValidationErrors(invalidItems);
        } else {
            setValidationErrors([]);
            // Proceed with the checkout logic
            if (toShip) navigate('/signin?redirect=shop/checkout/shipping', { state: { toShip } })
            else navigate('/signin?redirect=shop/checkout/confirm-order', { state: { toShip } })  
        }    
    }

    const goBack = () => {
        navigate(-1)
    }

  return (
    <Fragment>
        <ToastContainer />
        <Metadata title={'Your Cart'}/>

            <Fragment>
                <div className="container-md green-color mt-4 ps-md-2">
                    <div onClick={goBack} className="breadcrumb text-2 text-muted">{"< Go Back"}</div>
                </div>

                <div className="container-md my-0 mb-md-5">

                    <div className="d-flex justify-content-between align-items-center mt-4">

                        <h3 className="text-3 bold-2 text-muted m-0">CART</h3>

                        <span className="text-2">{cartItems.length} {cartItems.length === 1 ? "Item": "Items"}</span>
                    </div>
                    <hr className="my-3"/>
                    {loading ? 
                        <div className="my-3">
                            <Skeleton count={2} height={100} baseColor="#dc720082"/>
                        </div> 
                    : (
                        <Fragment>
                            {cartItems.length === 0 ? 
                            <h2 className="mt-4 mb-5 text-2">Your cart is empty.</h2> : (
                            <Fragment>
                                {validationErrors.length > 0 && (
                                    <div className="mb-4 border border-danger rounded p-2 p-md-4">
                                        <h5 className="text-2 red bold-2">Cart Validation Errors:</h5>
                                        <ul className="text-1 bold-2">
                                            {validationErrors.map((item, index) => (
                                            <li key={index}>
                                                {item}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {cartItems.map(item => (
                                    <Fragment key={item.product}>
                                        {typeof item.stock === "number" && !toShip ? setToShip(true) : ""}
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <div className="cart-img border rounded" style={{backgroundImage: `url('${item.image}')`, backgroundSize: 'cover'}}></div>
                                                <div className="product-info d-flex flex-column ms-3">
                                                    <Link to={`/products/${item.product}`} state={{ from: "cart" }} className="text-2">{item.name}</Link>
                                                    <span className="text-3 bold-2 my-1">&#x20A6;{numberWithCommas(item.price)}</span>
                                                    <button className="red-color cart-text remove" onClick={() => removeItemFromCartHandler(item.product)}>Remove</button>
                                                </div>
                                            </div>
                                            
                                            <div className="cart-qty-box">
                                                <div className="d-flex align-items-center justify-content-end">
                                                    <button className="d-flex align-items-center justify-content-center qty-toggle" onClick={() => decreaseQty(item.product, item.quantity, item.price)}>-</button>
                                                    <input type="number" id="count" className="product-qty text-2 mx-2 p-0" value={item.quantity} readOnly/>
                                                    <button className="d-flex align-items-center justify-content-center qty-toggle" onClick={() => increaseQty(item.product, item.quantity, item.price)}>+</button>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                    </Fragment>
                                ))}
                                <div className="d-flex justify-content-between mt-4">
                                    <h4 className="text-3">Total</h4>
                                    <div className="cart-price-box d-flex flex-column align-items-end">
                                    <span className="text-2 text-muted">{cartItems.reduce((acc, item) => (acc + Number(item.quantity)), 0)} (Units)</span>
                                        <span className="text-4 bold-2 my-1">&#x20A6;{numberWithCommas(cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0).toFixed(2))}</span>
                                        {toShip ? <span className="text-2 text-muted">Shipping fees not yet applied</span> : <Fragment></Fragment>}
                                    </div>
                                </div>
                                <div className="d-flex my-5 justify-content-end">
                                    <Link to="/shop" className="d-flex align-items-center justify-content-center orange text-2 me-3">Continue Shopping</Link>
                                    <button onClick={checkoutHandler} className="d-flex align-items-center justify-content-center orange-btn text-2">Checkout</button>
                                </div>
                            </Fragment>
                            )}
                        </Fragment>
                    )}
                </div>
            </Fragment>

    </Fragment>
  )
}

export default Cart
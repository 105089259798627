import React, { Fragment, useState, useEffect, useRef } from 'react'
import Metadata from '../layout/metadata'
import AdminNavigation from '../layout/adminNavigation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getProductDetails, updateProduct, deleteProduct, clearErrors } from '../../actions/productActions'

import DeleteModal from '../layout/deleteModal'
import { UPDATE_PRODUCT_RESET } from '../../constants/productConstants';

import { useParams } from 'react-router-dom'
import { numberWithCommas } from '../../Functions/functions'

import PDFViewer from '../layout/pdfViewer';

const ProductDetails = () => {
    let firstLoad = useRef(true)
    let isDelete = useRef(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, product, error } = useSelector(state => state.productDetails)
    const { loading: modifyLoading, error: modifyError, success } = useSelector(state => state.modifyProduct);

    let [ previewImage, setPreviewImage ] = useState('');
    let [isChecked, setIsChecked] = useState();

    if ( firstLoad.current && product.images) {
        isChecked = product.hidden
        previewImage = product.images[0].url
    }

    const params = useParams();

    useEffect(() => {
        if (!isDelete.current) dispatch(getProductDetails(params.id))

        if (success) {
            toast.success("Completed successfully! 👍");
            dispatch({type: UPDATE_PRODUCT_RESET});
            if (isDelete.current) navigate(-1);
        }

        if (error) {
            toast.error("Error fetching product details, please check your internet connection.")
            dispatch(clearErrors())
        }

        if (modifyError) {
            toast.error("An error occurred, please check your internet connection.")
            dispatch(clearErrors())
        }

    }, [dispatch, error, success])

   const getImage = (e) => {
        firstLoad.current = false;
        setPreviewImage(e.target.src)
    }

    const getClickedButton = (clickedButton) => {
        if (clickedButton === "Delete") {
            dispatch(deleteProduct(params.id));
            isDelete.current = true;
        }
    }

    const onHiddenToggle = (e) => {
        setIsChecked(e.target.checked)
        const update = {
            showHide: true,
            checked: e.target.checked
        }
        dispatch(updateProduct( params.id, update ));
        firstLoad.current = true;
      }

    return (
        <Fragment>
            <Metadata title={'Product Details'}/>
            <AdminNavigation Products />
            <ToastContainer />

            <div className="container-fluid container-md">
 
                <div className="py-3 mb-5">
                    <Link to="/admin/products" className="text-dark text-xs">{"< Back to Products"}</Link>
                </div>

                {loading || !product.keyFeatures ?                             
                    <div className="my-4">
                        <Skeleton count={15} height={50} baseColor="#dc720082"/>
                    </div> :
                    <Fragment> 
                        <div className="row">
                            <div className="col-sm-6 col-xl-4 d-flex flex-column mb-4 mb-sm-0">
                                <div className="product-details-img" style={{backgroundImage: `url('${previewImage}')`}}></div>
                                <div className="flex mt-3">
                                    { product.images && product.images.map(image => (
                                        <img onClick={getImage} key={image.url} src={image.url} className="toggle-img mx-1"/>
                                    ))}
                                </div>

                            </div>
                            <div className="col-sm-6 col-xl-8 d-flex flex-column">
                                <div>
                                    <h3 className="text-3 bold-2 mb-1">{product.name}</h3>
                                    <span className='text-1 text-muted'>Product #{product._id}</span>
                                    <h4 className="text-3 bold-3 mt-2">&#x20A6;{numberWithCommas(product.price)}</h4>
                                </div>
                                <hr className="light-hr my-2 my-sm-2"/>
                                <div>
                                    <span className="text-1 text-muted my-5">Units in Stock</span>
                                    {product.stock || typeof product.stock === "number" ?
                                    <h4 className="text-2 mt-2">{numberWithCommas(product.stock)} Units - <span className={product.stock > 0 ? 'green' : 'red'}>{product.stock > 0 ? 'In Stock' : 'Out of Stock'}</span></h4>
                                    :<h4 className="text-2 mt-2">Downloadable PDF - <span className="green">In Stock</span></h4>
                                    }
                                </div>
                                <hr className="light-hr my-2 my-sm-2"/>
                                <div>
                                    <span className="text-1 text-muted my-5">Category</span>
                                    <h4 className="text-2 mt-2">{product.category}</h4>
                                </div>

                                {/* <hr className="light-hr my-2 my-sm-2"/>
                                <div>
                                    <span className="text-1 text-muted">Packaging</span>
                                    <h4 className="text-sm mt-2">{product.packaging}</h4>
                                </div>
                                <hr className="light-hr my-2 my-sm-2"/>

                                <div>
                                    <span className="text-1 text-muted">Overall Rating</span>
                                    <h4 className="text-2 mt-2">{product.rating} ({product.numOfReviews} reviews)</h4>
                                </div> */}

                                <hr className="light-hr my-2 my-sm-2"/>

                                <div className="d-flex align-items-center mt-2">
                                    <div className="me-2">
                                        <Link to={`/admin/products/${product._id}/update-product`} className="orange-btn text-2 py-2">Update Product</Link>
                                    </div>

                                    <div className='border p-1 rounded'>
                                        <span className="me-2 text-xxs bold-2">Hide Product:</span>
                                        <label className="switch">
                                            <input onChange={onHiddenToggle} type="checkbox" checked={isChecked}/>
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>  
            
                        <div className='mt-5'>
                            <h3 className="text-2">Product Details</h3>
                            <hr className="light-hr my-2 my-sm-2"/>
                            <p className="text-1 text-muted bold-2">Description</p>
                            <p className="text-2">{product.description}</p>

                            <p className="text-1 text-muted bold-2 mt-5 mb-2">Key Features</p>
                            { product.keyFeatures.split('•').slice(1).map(feature => (
                                <p key={feature} className="text-2 mb-2">● {feature}</p>
                            ))}
                        </div>

                        {/* {product.specDocs && product.specDocs.length > 0 ? (
                            <div className="mt-5">
                                <p className="bold-2 text-xs">Product Document</p>
                                <PDFViewer pdfUrl={product.specDocs[0].url} />
                            </div>
                        ): (<Fragment></Fragment>)} */}

                        <div>
                            <button className="red-btn bold-2 text-1 px-3 mt-5 mb-5" data-bs-toggle="modal" data-bs-target="#delete-modal" disabled={modifyLoading}>Delete product record</button>
                            <DeleteModal title="Product Details" item="Product" onSelect={getClickedButton}/>
                        </div>
                    </Fragment>
                }
            </div>                             
        </Fragment>
    )
}

export default ProductDetails
import { 
    CREATE_REVIEW_REQUEST,
    CREATE_REVIEW_SUCCESS,
    CREATE_REVIEW_FAIL,
    CREATE_REVIEW_RESET,
    GET_REVIEWS_REQUEST,
    GET_REVIEWS_SUCCESS,
    GET_REVIEWS_FAIL,
    REVIEW_DETAILS_REQUEST,
    REVIEW_DETAILS_SUCCESS,
    REVIEW_DETAILS_FAIL,
    REVIEW_DETAILS_RESET,
    UPDATE_REVIEW_REQUEST,
    UPDATE_REVIEW_SUCCESS,
    UPDATE_REVIEW_FAIL,
    UPDATE_REVIEW_RESET,
    DELETE_REVIEW_REQUEST,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_FAIL,
    DELETE_REVIEW_RESET,
    CLEAR_ERRORS
} from '../constants/reviewConstants'

export const getReviewsReducer = (state = { reviews:[] }, action) => {
switch(action.type) {
    case GET_REVIEWS_REQUEST:
        return {
            loading: true,
            reviews: []
        }

    case GET_REVIEWS_SUCCESS:
        return {
            loading: false,
            reviews: action.payload
        }

    case GET_REVIEWS_FAIL:
        return {
            loading: false,
            error: action.payload
        }
    case CLEAR_ERRORS:
        return {
            ...state,
            error: null
        }
    default:
        return state;
}
}

export const modifyReviewReducer = (state = { review: {} }, action) => {
switch(action.type) {
    case CREATE_REVIEW_REQUEST:
    case UPDATE_REVIEW_REQUEST:
    case DELETE_REVIEW_REQUEST:
        return {
            ...state,
            loading: true,
        }
    case CREATE_REVIEW_SUCCESS:
    case UPDATE_REVIEW_SUCCESS:
    case DELETE_REVIEW_SUCCESS:
        return {
            loading: false,
            success: action.payload.success,
            review: action.payload.review

        }

    case CREATE_REVIEW_FAIL:
    case UPDATE_REVIEW_FAIL:
    case DELETE_REVIEW_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload
        }

    case CREATE_REVIEW_RESET:
    case UPDATE_REVIEW_RESET:
    case DELETE_REVIEW_RESET:
        return {
            ...state,
            success: false,
        }
    case CLEAR_ERRORS:
        return {
            ...state,
            error: null
        }
    default:
        return state;
}
}

export const reviewDetailsReducer = (state = {review: {}}, action) => {
    switch (action.type) {
        case REVIEW_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false
            }
        case REVIEW_DETAILS_SUCCESS:
            return {
                loading: false,
                review: action.payload,
                success: true
            }
        case REVIEW_DETAILS_FAIL:
            return {
                loading: false,
                success: false,
                error: action.payload
            }
        case REVIEW_DETAILS_RESET:
            return {
                loading: false,
                success: false,
                review: {}
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
import axios from 'axios';
import {
    CREATE_PROMO_REQUEST,
    CREATE_PROMO_SUCCESS,
    CREATE_PROMO_FAIL,
    GET_PROMOS_REQUEST,
    GET_PROMOS_SUCCESS,
    GET_PROMOS_FAIL,
    PROMO_DETAILS_REQUEST,
    PROMO_DETAILS_SUCCESS,
    PROMO_DETAILS_FAIL,
    UPDATE_PROMO_REQUEST,
    UPDATE_PROMO_SUCCESS,
    UPDATE_PROMO_FAIL,
    DELETE_PROMO_REQUEST,
    DELETE_PROMO_SUCCESS,
    DELETE_PROMO_FAIL,
    CLEAR_ERRORS
} from '../constants/promoConstants'

export const newPromo = (promoData) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_PROMO_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/admin/promos/new', promoData, config)

        dispatch({
            type: CREATE_PROMO_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CREATE_PROMO_FAIL,
            payload: error.response.data.errMessage
        })
    }
};

export const getPromos = (role) => async (dispatch) => {
    let URL = '/api/v1/promos';
    if (role === "Admin") { URL = '/api/v1/admin/promos' }

    try {
        dispatch({ type: GET_PROMOS_REQUEST })

        const { data } = await axios.get(URL)

        dispatch({
            type: GET_PROMOS_SUCCESS,
            payload: data.promos
        })
    } catch (error) {
        dispatch({
            type: GET_PROMOS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getPromoDetails = (id, role) => async (dispatch) => {
    let URL =  `/api/v1/promos/${id}`;
    if (role === "Admin") { URL =  `/api/v1/admin/promos/${id}` }

    try {
        dispatch({ type: PROMO_DETAILS_REQUEST })
        const { data } = await axios.get(URL)

        dispatch({
            type: PROMO_DETAILS_SUCCESS,
            payload: data.promo
        })
    } catch (error) {
        dispatch({
            type: PROMO_DETAILS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const updatePromo = (id, update) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROMO_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/promos/${id}`, update, config)

        dispatch({
            type: UPDATE_PROMO_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: UPDATE_PROMO_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const deletePromo = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROMO_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.delete(`/api/v1/admin/promos/${id}`, config)

        dispatch({
            type: DELETE_PROMO_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETE_PROMO_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

// CLEAR ERRORS
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}
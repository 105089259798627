import React, { Fragment } from 'react'

const ShopCarousel = ({images}) => {

  return (
    <Fragment>
        <div id="carouselAutoplaying" className="carousel slide" data-bs-ride="carousel" data-bs-touch="true" data-bs-interval="3000">
            <div className="carousel-inner">

                <div className="carousel-item active">
                    <div className="shop-carousel-image" style={{backgroundImage: `url('/images/${images[0]}')`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                </div>

                <div className="carousel-item">
                    <div className="shop-carousel-image" style={{backgroundImage: `url('/images/${images[1]}')`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                </div>

                {/* <div className="carousel-item">
                    <div className="shop-carousel-image" style={{backgroundImage: `url('/images/${images[2]}')`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                </div> */}
                
            </div>

            <button className="carousel-control-prev d-md-block" type="button" data-bs-target="#carouselAutoplaying" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>

            <button className="carousel-control-next d-md-block" type="button" data-bs-target="#carouselAutoplaying" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
                                
    </Fragment>
  )
}

export default ShopCarousel
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'


const AdminNavigation = ({Dashboard, Academy, Classes, Recipes, Payments, Users, Products, Promos, Orders}) => {
  const { user } = useSelector(state => state.auth)

  return (
    <Fragment>
        <nav className="navbar navbar-expand-lg bg-white border-bottom">
            <div className="container-fluid container-md">
                <div className="user-nav-block">
                    {/* DASHBOARD */}
                    <Link to="/admin/dashboard" className="user-nav-item ms-0">
                        <img src={Dashboard ? '/images/dashboard-icon.png' : '/images/dashboard-icon-gray.png'} className="nav-icon" alt="Dashboard-icon" />
                        <span className={`text-xxs user-nav-label ${Dashboard?'green':'gray'}`}>Dashboard</span>
                    </Link>

                    {/* ACADEMY */}
                    <Link to="/admin/academy" className="user-nav-item">
                    <img src={Academy ? '/images/orders-icon.png' : '/images/orders-icon-gray.png'} className="nav-icon" alt="academy-icon" />
                    <span className={`text-xxs user-nav-label ${Academy?'green':'gray'}`}>Academy</span>
                    </Link>

                    {/* MASTER CLASSES */}
                    <Link to="/admin/classes" className="user-nav-item">
                    <img src={Classes ? '/images/inventory-icon.png' : '/images/inventory-icon-gray.png'} className="nav-icon" alt="Inventory-icon" />
                    <span className={`text-xxs user-nav-label ${Classes?'green':'gray'}`}>Masterclasses</span>
                    </Link>

                    {/* RECIPES */}
                    <Link to="/admin/recipes" className="user-nav-item">
                    <img src={Recipes ? '/images/storage-icon.png' : '/images/storage-icon-gray.png'} className="nav-icon" alt="storage-icon" />
                    <span className={`text-xxs user-nav-label ${Recipes ?'green':'gray'}`}>Recipe</span>
                    </Link>


                    {/* PAYMENTS */}
                    <Link to="/admin/payments" className="user-nav-item">
                    <img src={Payments ? '/images/payments-icon.png' : '/images/payments-icon-gray.png'} className="nav-icon" alt="payments-icon" />
                    <span className={`text-xxs user-nav-label ${Payments?'green':'gray'}`}>Payments</span>
                    </Link>

                    {/* USERS */}
                    <Link to="/admin/users" className="user-nav-item">
                        <img src={Users ? '/images/users-icon.png' : '/images/users-icon-gray.png'} className="nav-icon" alt="users-icon" />
                        <span className={`text-xxs user-nav-label ${Users?'green':'gray'}`}>Users</span>
                    </Link>

                    {/* PRODUCTS */}
                    <Link to="/admin/products" className="user-nav-item">
                        <img src={Products ? '/images/requests-icon.png' : '/images/requests-icon-gray.png'} className="nav-icon" alt="requests-icon" />
                        <span className={`text-xxs user-nav-label ${Products ?'green':'gray'}`}>Products</span>
                    </Link>

                    {/* PROMOS */}
                    <Link to="/admin/promos" className="user-nav-item">
                        <img src={Promos ? '/images/requests-icon.png' : '/images/requests-icon-gray.png'} className="nav-icon" alt="requests-icon" />
                        <span className={`text-xxs user-nav-label ${Promos ?'green':'gray'}`}>Promos</span>
                    </Link>

                    {/* ORDERS */}
                    <Link to="/admin/orders" className="user-nav-item">
                        <img src={Orders ? '/images/orders-icon.png' : '/images/orders-icon-gray.png'} className="nav-icon" alt="Orders-icon" />
                        <span className={`text-xxs user-nav-label ${Orders?'green':'gray'}`}>Orders</span>
                    </Link>
         
                </div>
            </div>
            <hr className="mb-4"/>
        </nav>

    </Fragment>
  )
}

export default AdminNavigation
export const CREATE_SHIPPING_ADDRESS_REQUEST = 'CREATE_SHIPPING_ADDRESS_REQUEST'
export const CREATE_SHIPPING_ADDRESS_SUCCESS = 'CREATE_SHIPPING_ADDRESS_SUCCESS'
export const CREATE_SHIPPING_ADDRESS_RESET = 'CREATE_SHIPPING_ADDRESS_RESET'
export const CREATE_SHIPPING_ADDRESS_FAIL = 'CREATE_SHIPPING_ADDRESS_FAIL'

export const GET_SHIPPING_ADDRESSES_REQUEST = 'GET_SHIPPING_ADDRESSES_REQUEST'
export const GET_SHIPPING_ADDRESSES_SUCCESS = 'GET_SHIPPING_ADDRESSES_SUCCESS'
export const GET_SHIPPING_ADDRESSES_FAIL = 'GET_SHIPPING_ADDRESSES_FAIL'

export const UPDATE_SHIPPING_ADDRESS_REQUEST = 'UPDATE_SHIPPING_ADDRESS_REQUEST'
export const UPDATE_SHIPPING_ADDRESS_SUCCESS = 'UPDATE_SHIPPING_ADDRESS_SUCCESS'
export const UPDATE_SHIPPING_ADDRESS_FAIL = 'UPDATE_SHIPPING_ADDRESS_FAIL'
export const UPDATE_SHIPPING_ADDRESS_RESET = 'UPDATE_SHIPPING_ADDRESS_RESET'

export const DELETE_SHIPPING_ADDRESS_REQUEST = 'DELETE_SHIPPING_ADDRESS_REQUEST'
export const DELETE_SHIPPING_ADDRESS_SUCCESS = 'DELETE_SHIPPING_ADDRESS_SUCCESS'
export const DELETE_SHIPPING_ADDRESS_FAIL = 'DELETE_SHIPPING_ADDRESS_FAIL'
export const DELETE_SHIPPING_ADDRESS_RESET = 'DELETE_SHIPPING_ADDRESS_RESET'

export const SHIPPING_ADDRESS_DETAILS_REQUEST = 'SHIPPING_ADDRESS_DETAILS_REQUEST'
export const SHIPPING_ADDRESS_DETAILS_SUCCESS = 'SHIPPING_ADDRESS_DETAILS_SUCCESS'
export const SHIPPING_ADDRESS_DETAILS_FAIL = 'SHIPPING_ADDRESS_DETAILS_FAIL'
export const SHIPPING_ADDRESS_DETAILS_RESET = 'SHIPPING_ADDRESS_DETAILS_RESET'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
import React, { Fragment, useEffect } from 'react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'

import { getClasses, clearErrors } from '../../actions/classActions'

import { initGA, logPageView } from '../../GoogleAnalytics';

const Masterclass = () => {
    const dispatch = useDispatch();

    const { loading, error, classes } = useSelector(state => state.getClasses);

    useEffect(() => {
        dispatch(getClasses());
        initGA();
        logPageView();

        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            console.log(error, "ERRORRR - masterclass.jsx")
            dispatch(clearErrors())
        }

    }, [dispatch, error])

    const handleContextMenu = (e) => {
        e.preventDefault();
    }

  return (
    <Fragment>
        <ToastContainer/>
            <div className="carousel-image mt-5" style={{backgroundImage: "url('/images/masterclass_bg.png')", backgroundPosition: "top", backgroundSize: "cover"}}></div>
            <div className="carousel-text d-block">
                <h5 className="carousel-header bold-2">Meet the Best and Become a master</h5>
                <p className="text-sm py-2">Top instructors eager to make you a super chef!.</p>
                {/* <hr className="w-100"/> */}
            </div>

            <div className="container-fluid container-md">
                {/* HERO TEXT */}
                <div className="text-center my-5">
                    <h2 className="text-lg">SPECIALLY RECORDED VIDEOS FOR ULTIMATE LEARNING EXPERIENCE</h2>
                    <hr className="mx-auto w-25" />
                    <p className="text-xs my-4 spaced"><span className="bold-2">WATCH ME SPEAK WITH INDUSTRY LEADERS ON TIPS TO BECOMING A MASTER IN THEIR FIELDS. </span> 
                    We all need knowledge and how beautiful it  is to stand on the shoulders of those who have gone ahead of us to create paths. 
                    Experience they say beats knowledge. </p>
                    <button disabled={true} className="text-sm action-btn mb-4 bg-dark">Coming Soon!</button>
                </div> 

                {/*  FREE CLASSES */}
                {/* <div className="text-center">
                    <h2 className="text-lg">FREE CLASSES TO GET YOU STARTED</h2>
                    <hr className="mx-auto w-25" />
                    <p className="text-xs mt-4 mb-5"> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid rem voluptates perferendis quae ratione minima doloribus inventore nostrum! Dolore. </p>
                    
                    <div className="wrapper">
                        { classes && classes.map(classItem => (
                        <Fragment key={classItem._id}>
                            <div className="wrapper-box shadow-sm mb-5">
                                <video onContextMenu={handleContextMenu} className="course-vid"  controls poster={classItem.thumbnailURL}>
                                    <source src={classItem.videoURL} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className="d-flex flex-wrap text-start px-2 pb-2">
                                    <p className="text-xs bold-1 my-3">{classItem.name}</p>
                                </div>
                            </div>
                        </Fragment>
                        ))}
                    </div>

                </div> */}
            </div>
            
    </Fragment>
  )
}

export default Masterclass
import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useLocation } from 'react-router-dom';

import { getNotifications, clearErrors } from '../../actions/notificationActions'
import { signout } from '../../actions/userActions'

import ReactGA from "react-ga4";

import Search from './search';

const ShopHeader = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const currState = location.state;

    const { isAuthenticated, user } = useSelector(state => state.auth);
    const { cartItems } = useSelector(state => state.cart)
    const { error, notifications } = useSelector(state => state.getNotifications)

    let newNotifications = 0;

    if (notifications && notifications.length > 0) {
        newNotifications = (notifications[0].messages.filter(message => message.new === true)).length
    }

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getNotifications());
        }

        if (error) {
            toast.error("An error has occurred, check your internet connection and refresh the page.")
            dispatch(clearErrors())
        }
    }, [dispatch, isAuthenticated, error])

    const signoutHandler = () => {
        dispatch(signout());
        toast.success('Signed out successfully 👍.')
    }


  return (
    <Fragment>
        <ToastContainer />
        {/* <div className="contact-bar d-flex flex-column text-1 bold-2">Get 50% Off on Selected Items    |      Shop Now</div> */}
        <nav className="navbar navbar-expand-lg bg-black sticky-top py-2 shadow-bottom">
            <div className="container-fluid container-md">
                <Link to="/shop" className="text-white text-2 bold-2">Shop OmoyeCooks</Link>

                <button className="navbar-toggler p-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon d-block mt-1 bg-light"></span>
                    <span className="navbar-toggler-icon d-block my-1 bg-light"></span>
                    <span className="navbar-toggler-icon d-block mb-1 bg-light"></span>
                </button>

                <div className="collapse navbar-collapse mt-3 mt-lg-0" id="navbarNavDropdown">
                    <Search/>
                    <ul className="navbar-nav">
                        <Link to="/" className="bold-1 text-2 text-white me-5"><i className="fa-solid fa-arrow-up-right-from-square my-4 my-lg-0 me-2"></i>Academy</Link>
                        {/* PROFILE */}
                        {user && user.firstName ? (
                            <Fragment>
                                {/* PROFILE */}
                                <li className="nav-item dropdown">
                                    
                                    {/* USER  */}
                                    <Link to="#" className="d-flex align-items-center text-white no-drop" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa-solid fa-user me-2 no-drop"></i>
                                        <span className="bold-1 text-2 no-drop">{user.firstName}</span>
                                    </Link>

                                    <ul className="dropdown-menu mt-3 mt-md-0">
                                        <i className="text-muted text-xs d-block text-center m-0 p-0">{user.firstName} {user.lastName}</i>
                                        <hr className="m-2" />
                                        {/* CHECK ADMIN */}
                                        
                                        {user.role === "Admin" ? 
                                            <Fragment>
                                                <li><Link to="/admin/dashboard" className="dropdown-item mt-1 px-1">Admin Dashboard</Link></li>
                                                <li><Link to="/shop" className="dropdown-item px-1">User View</Link></li>
                                            </Fragment> : <Fragment></Fragment>
                                            }
                                        
                                        <li><Link to={`/profile`} className="dropdown-item px-1">My Account</Link></li>
                                        <li><Link to={`/shop/my-orders`} className="dropdown-item px-1">My Orders</Link></li>
                                        <li><Link to={`/shop/my-ebooks`} className="dropdown-item px-1">My Ebooks</Link></li>
                                        <li><Link to="/shop" className="dropdown-item mt-3 mt-md-0 mb-1 px-1" onClick={signoutHandler}>Sign Out</Link></li>
                                    </ul>
                                </li>
                            </Fragment>
                            
                        ) : (<Fragment>
                                <Link to={`/signin?redirect=${location.pathname.substring(1)}`} state={currState} className="bold-1 text-2 text-white"><i className="fa-solid fa-right-to-bracket me-2"></i>Sign In</Link>
                            </Fragment>
                            )}

                        <li className="nav-item dropdown my-4 my-lg-0 ms-0 ms-lg-5">  
                            {/* CART */}
                            <Link to="shop/cart" className="d-flex align-items-center text-white">
                                <i className="fa-solid fa-cart-shopping me-2"></i>
                                <span className='bold-1 text-2'>Cart</span>
                                <span className="cart-number bold-2 text-3 ms-2">{cartItems.length}</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
                                
    </Fragment>
  )
}

export default ShopHeader
import React, { Fragment, useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import { Link, useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Metadata from '../layout/metadata'
import AdminNavigation from '../layout/adminNavigation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NEW_RECIPE_RESET } from '../../constants/recipeConstants'
import { newRecipe, clearErrors } from '../../actions/recipeActions'

const BUCKET = process.env.REACT_APP_AWS_BUCKET
const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const accelerateEndpoint = process.env.REACT_APP_AWS_ACCELERATE_ENDPOINT;
const cloudfront = process.env.REACT_APP_AWS_CLOUDFRONT

const s3 = new AWS.S3({
  accessKeyId,
  secretAccessKey,
  signatureVersion: 'v4',
  accelerateEndpoint
});

function getRecipeKey() {
  const characters = "1234567890abcdefghijklmnopqrstuvwxyz";
  let key = "";
  for (let i = 0; i < 8; i++) {
      key += characters[Math.floor( Math.random() * characters.length )];
  }
  return key;
};

let previousLength = 0;
let recipeImage = ""
let recipeImageDetails = {}

const categories = [
  "Plantain recipes",
  "Nigerian soups",
  "Chicken recipes",
  "Simple breakfast",
  "Pasta recipes",
  "OmoyeCooks special recipes"
  ]

const AddRecipe = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { error, success } = useSelector(state => state.modifyRecipe)
  let [state, setState] = useState("Idle")
  let [recipe, setRecipe] = useState({
    recipeKey: getRecipeKey(),
    name: '',
    category: '',
    description: '',
    ingredients: '',
    steps: '',
    image: {}
  });

  const { recipeKey, name, category, description, ingredients, steps } = recipe;

  useEffect(() => {
    if (error) {
      if (error.includes("E11000")){
        toast.error("A recipe with this exact name already exists, please change the name.")
      } else {
        toast.error("An error occurred, please check your internet connection, refresh, and try again.")
      }
        dispatch(clearErrors())
    }
    if (success) {
        toast.success("Successfully added Recipe 👍")
        dispatch({ type: NEW_RECIPE_RESET })
        navigate("/admin/recipes")
    }
}, [dispatch, error, success]);

  const nameInputHandler = (event) => {
    const textRegex = /^[a-zA-Z0-9\s]+$/;
    if (textRegex.test(event.target.value)) {
      setRecipe({...recipe, [event.target.name]: event.target.value });
    }
  };

  const recipeOnChange = (event) => {
    setRecipe({...recipe, [event.target.name]: event.target.value });
  };

  const handleBulletInput = (event) => {
    const bullet = "\u2022";
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);

    if (newLength > previousLength) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${bullet} `;
      } else if (newLength === 1) {
        event.target.value = `${bullet} ${event.target.value}`;
      }
    }
    
    previousLength = 0;
    setRecipe({...recipe, [event.target.name]: event.target.value });
  }

  const onRecipeImageSelect= event => {
    const file = event.target.files[0]

    if (!file) {
      recipeImageDetails = {}
      recipeImage = ""
      return
    }

    recipeImageDetails.name = file.name
    recipeImageDetails.type = file.type
    recipeImageDetails.size = (parseFloat(file.size) * 0.0000009537).toFixed(2) + "MB"
    recipeImage = file
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    setState("Working")
    const key = `Recipes/${recipeKey}/` + recipeImage.name

    try {
      // UPLOAD IMAGE
      const params = {
        Bucket: BUCKET,
        Key: key,
        Body: recipeImage,
      };

      await s3.upload(params).promise();
    } catch (error) {
      toast.error("Couldn't upload image.")
    } finally {
      recipeImageDetails.URL = `${cloudfront}/${key}`
      recipe.image = recipeImageDetails
      dispatch(newRecipe(recipe))
    }
  }

  return (
    <Fragment>
      <Metadata title={'Add Recipe'} />
      <AdminNavigation Recipe/>
      <ToastContainer />
      <div className="container-fluid container-md bg-light shadow-lg p-0 mb-4">
        <div className="bg-dark border rounded p-3">
          <h3 className="text-md text-light m-0">Add Recipe</h3>
        </div>

        <div className="p-3">
          <Link to="/admin/recipes" className="text-dark text-xs">{"< Back to recipes"}</Link>
        </div>

        <form onSubmit={submitHandler} className="p-3 pb-5">
          <div className="row">

            {/* RECIPE NAME */}
            <div className="col-md-6 mb-4">
            <label htmlFor="name" className="form-label">Recipe Name</label><i className="red">*</i>
                <input 
                    type="text" 
                    className="form-control" 
                    id="name"
                    name='name'
                    value={name} 
                    onChange={nameInputHandler}
                    aria-describedby="nameHelp" 
                    placeholder="E.g. Egusi Soup"
                    readOnly={state === "Working"}
                    required
                />
            </div>

            {/* CATEGORY */}
            <div className="col-md-6 mb-4">
                <label htmlFor="category" className="form-label">Category</label><i className="red-color">*</i>
                <select 
                    name="category" 
                    id="category" 
                    className="form-control"
                    value={category}
                    onChange={recipeOnChange}
                    disabled={state === "Working"}
                    required
                    >
                    <option value="">Choose category</option>
                    {categories.map(category => (
                        <option key={category} value={category}>{category}</option>
                    ))}
                </select>
            </div>

            {/* DESCRIPTION */}
            <div className="col-12 mb-4">
              <label htmlFor="description" className="form-label">Description</label><i className="red">*</i>
              <textarea 
                  className="form-control" 
                  id="description" 
                  name='description'
                  rows="2" 
                  value={description} 
                  onChange={recipeOnChange}
                  placeholder="Describe course..."
                  readOnly={state === "Working"}
                  required
              />
            </div>

            {/* INGREDIENTS */}
            <div className="col-md-6 mb-4">
              <label htmlFor="ingredients" className="form-label">Ingredients</label><i className="red">*</i>
              <textarea 
                  className="form-control" 
                  id="ingredients" 
                  name="ingredients"
                  rows="2" 
                  value={ingredients} 
                  onInput={handleBulletInput}
                  placeholder="Enter ingredients..."
                  readOnly={state === "Working"}
                  required
              />
            </div>  

            {/* REQUIREMENTS */}
            <div className="col-md-6 mb-4">
              <label htmlFor="steps" className="form-label">Cooking Steps</label><i className="red">*</i>
              <textarea 
                  className="form-control" 
                  id="steps" 
                  name="steps"
                  rows="2" 
                  value={steps} 
                  onInput={handleBulletInput}
                  placeholder="Enter cooking steps..."
                  readOnly={state === "Working"}
                  required
              />
            </div>

            {/* IMAGE */}
            <div className="col-md-6 mb-4">
              <label htmlFor="recipeImage" className="form-label">Select Image</label><i className="red">*</i>
              <input
                type="file"
                name="recipeImage"
                className="form-control"
                id="recipeImage"
                onChange={onRecipeImageSelect}
                accept="image/*"
                disabled={state === "Working"}
                aria-describedby="recipeImageHelp"
                required
              />
            </div>

            {/* ###################################### */}

            {/* BUTTON */}
            <div className="d-flex flex-column align-items-end">
              <button className="action-btn mt-4 mt-md-0" type='submit'>{state === "Working" ? "Adding recipe..." : "Add recipe"}</button>
            </div>

          </div>

        </form>

      </div>
    </Fragment>
  );
};

export default AddRecipe;
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Link, useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AWS from 'aws-sdk';

import Metadata from '../layout/metadata'
import AdminNavigation from '../layout/adminNavigation'

import { useParams } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { UPDATE_COURSE_RESET } from '../../constants/courseConstants'
import { getCourseDetails, updateCourse, clearErrors } from '../../actions/courseActions'

const BUCKET = process.env.REACT_APP_AWS_BUCKET
const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const accelerateEndpoint = process.env.REACT_APP_AWS_ACCELERATE_ENDPOINT;
const cloudfront = process.env.REACT_APP_AWS_CLOUDFRONT

const s3 = new AWS.S3({
  accessKeyId,
  secretAccessKey,
  signatureVersion: 'v4',
  accelerateEndpoint
});

let previousLength = 0;

const schedules = [
    'Daily',
    'Weekly',
    'Monthly'
    ]

const durations = [
  '1 day',
  '3 days',
  '5 days',
  '1 week',
  '2 weeks',
  '3 weeks',
  '1 month',
  '2 months',
  '3 months',
  '4 months',
  '5 months',
  '6 months',
  '1 year',
  ]

const UpdateCourse = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const URLparam = useParams();
    
    let firstLoad = useRef(true);
    let isChange = useRef(false);
    
    const { error, loading, success } = useSelector(state => state.newCourse)
    const { course } = useSelector(state => state.courseDetails);
    
    useEffect(() => {
      dispatch(getCourseDetails(URLparam.id, "Admin"));
      firstLoad.current = true;
  
      if (error) {
          toast.error("An error occurred, please check your internet connection, refresh, and try again.")
          dispatch(clearErrors())
          setState("Idle")
      }
      if (success) {
          toast.success("Successfully updated Course 👍")
          setState("Idle")
          firstLoad.current = false;
          dispatch({ type: UPDATE_COURSE_RESET })
          navigate(`/admin/academy/${course._id}`)
      }
  }, [dispatch, error, success])

  const [doc, setDoc] = useState();
  const [state, setState] = useState("Idle")
  const [courseInfo, setCourseInfo] = useState({
      name: "",
      price: "",
      caption: "",
      description: "",
      includes: "",
      requirements: "",
      startDate: "",
      endDate: "",
      duration: "",
      schedule: "",
      doc: {}
  });

    if (course._id && firstLoad.current) {
        setCourseInfo({
            name: course.name,
            price: course.price,
            caption: course.caption,
            description: course.description,
            includes: course.includes,
            requirements: course.requirements,
            startDate: course.startDate.split('T')[0],
            endDate: course.endDate.split('T')[0],
            duration: course.duration,
            schedule: course.schedule
        });
        firstLoad.current = false
    }

  const { name, price, caption, description, includes, requirements, startDate, endDate, duration, schedule} = courseInfo;


  const nameInputHandler = (event) => {
    isChange.current = true;
    const textRegex = /^[a-zA-Z0-9\s]+$/;
    if (textRegex.test(event.target.value)) {
      setCourseInfo({...courseInfo, [event.target.name]: event.target.value });
    }
  };

  const courseInfoOnChange = (event) => {
    isChange.current = true;
    setCourseInfo({...courseInfo, [event.target.name]: event.target.value });
  };

  const handleBulletInput = (event) => {
    isChange.current = true;
    const bullet = "\u2022";
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);

    if (newLength > previousLength) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${bullet} `;
      } else if (newLength === 1) {
        event.target.value = `${bullet} ${event.target.value}`;
      }
    }
    
    previousLength = 0;
    setCourseInfo({...courseInfo, [event.target.name]: event.target.value });
  }

  const onDocChange = e => {
    let docDetails = {}

    const file = e.target.files[0]
    setDoc(file)

    if (file) { 
      isChange.current = true;
      docDetails = {
        name: file.name,
        type: file.type,
        size: (parseFloat(file.size) * 0.0000009537).toFixed(2) + "MB",
        url: `${cloudfront}/Courses/${course.key}/doc/${file.name}`
      }
    }

    setCourseInfo({...courseInfo, ["doc"]: docDetails });
  };

  const submitHandler = async (e) => {
    e.preventDefault()
    setState("Working")

    try {
      // UPLOAD DOCS
      if (doc) {
        const params = {
          Bucket: BUCKET,
          Key: `Courses/${course.key}/doc/${doc.name}`,
          Body: doc,
        };
    
        await s3.upload(params).promise();
      }
    } catch (error) {
      toast.error("Couldn't upload document, proceeding to update course data.")
    } finally {
      dispatch(updateCourse(URLparam.id, courseInfo));  
    }
  }

  return (
    <Fragment>
      <Metadata title={'Update Course'} />
      <AdminNavigation Academy/>
      <ToastContainer />
      <div className="container-fluid container-md bg-light shadow-lg p-0 mb-4">
        <div className="bg-dark border rounded p-3">
          <h3 className="text-sm text-light m-0">Update Course</h3>
        </div>

        <div className="p-3">
          <Link to={`/admin/academy/${course._id}`} className="text-dark text-xs">{"< Back to Course"}</Link>
        </div>

        { !course._id ? <Fragment></Fragment> : (
            <form onSubmit={submitHandler} className="p-3 pb-5">
                <div className="row">

                    {/* COURSE NAME */}
                    <div className="col-md-6 mb-4">
                    <label htmlFor="name" className="form-label">Course Name</label><i className="red">*</i>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="name"
                            name='name'
                            value={name} 
                            onChange={nameInputHandler}
                            aria-describedby="nameHelp" 
                            placeholder="E.g. Baking Class"
                            readOnly={state === "Working"}
                            required
                        />
                    </div>

                    {/* PRICE */}
                    <div className="col-md-6 mb-4">
                        <label htmlFor="price" className="form-label">Price</label><i className="red">*</i>
                        <input 
                            type="number"
                            className="form-control" 
                            id="price"
                            name='price'
                            value={price} 
                            onChange={courseInfoOnChange}
                            aria-describedby="priceHelp" 
                            placeholder="E.g. N2,500"
                            readOnly={state === "Working"}
                            required
                        />
                    </div>

                    {/* CAPTION */}
                    <div className="col-md-6 mb-4">
                    <label htmlFor="caption" className="form-label">Caption</label><i className="red">*</i>
                    <textarea 
                        className="form-control" 
                        id="caption" 
                        name='caption'
                        rows="2" 
                        value={caption} 
                        onChange={courseInfoOnChange}
                        placeholder="Short catchy caption..."
                        readOnly={state === "Working"}
                        required
                    />
                    </div>

                    {/* DESCRIPTION */}
                    <div className="col-md-6 mb-4">
                    <label htmlFor="description" className="form-label">Description</label><i className="red">*</i>
                    <textarea 
                        className="form-control" 
                        id="description" 
                        name='description'
                        rows="2" 
                        value={description} 
                        onChange={courseInfoOnChange}
                        placeholder="Describe course..."
                        readOnly={state === "Working"}
                        required
                    />
                    </div>

                    {/* INCLUDES */}
                    <div className="col-md-6 mb-4">
                    <label htmlFor="includes" className="form-label">Course Includes</label><i className="red">*</i>
                    <textarea 
                        className="form-control" 
                        id="includes" 
                        name="includes"
                        rows="4" 
                        value={includes} 
                        onInput={handleBulletInput}
                        placeholder="Enter list of things included in this course..."
                        readOnly={state === "Working"}
                        required
                    />
                    </div>  

                    {/* REQUIREMENTS */}
                    <div className="col-md-6 mb-4">
                    <label htmlFor="requirements" className="form-label">Course Requirements</label><i className="red">*</i>
                    <textarea 
                        className="form-control" 
                        id="requirements" 
                        name="requirements"
                        rows="4" 
                        value={requirements} 
                        onInput={handleBulletInput}
                        placeholder="Enter course requirements..."
                        readOnly={state === "Working"}
                        required
                    />
                    </div>

                    {/* START DATE */}
                    <div className="col-md-3 mb-4">
                    <label htmlFor="startDate" className="form-label">Start Date</label><i className="red">*</i>
                    <input
                        type="date"
                        className="form-control" 
                        id="startDate" 
                        name="startDate"
                        value={startDate} 
                        onChange={courseInfoOnChange}
                        placeholder="Select course start date..."
                        readOnly={state === "Working"}
                        required
                    />
                    </div> 

                    {/* END DATE */}
                    <div className="col-md-3 mb-4">
                    <label htmlFor="endDate" className="form-label">End Date</label><i className="text-xs red bold-2">* (Lifetime access)</i>
                    <input
                        type="date"
                        className="form-control" 
                        id="endDate" 
                        name="endDate"
                        value={endDate} 
                        onChange={courseInfoOnChange}
                        placeholder="Select course end date..."
                        readOnly={state === "Working"}
                        required
                    />
                    </div>

                    {/* DURATION */}
                    <div className="col-md-3 mb-4">
                      <label htmlFor="duration" className="form-label">Duration</label><i className="red">*</i>
                      <select 
                        name="duration" 
                        id="duration" 
                        className="form-control"
                        value={duration} 
                        onChange={courseInfoOnChange}
                        disabled={state === "Working"}
                        required
                        >
                        <option value="">Choose duration</option>
                        {durations.map(duration => (
                            <option key={duration} value={duration}>{duration}</option>
                        ))}
                      </select>
                    </div>

                    {/* SCHEDULE */}
                    <div className="col-md-3 mb-4">
                    <label htmlFor="schedule" className="form-label">Videos unlock schedule</label><i className="red">*</i>
                    <select 
                        name="schedule" 
                        id="schedule" 
                        className="form-control"
                        value={schedule} 
                        onChange={courseInfoOnChange}
                        disabled={state === "Working"}
                        required
                        >
                        <option value="">Choose schedule</option>
                        {schedules.map(schedule => (
                            <option key={schedule} value={schedule}>{schedule}</option>
                        ))}
                    </select>
                    </div>

                    {/* PRODUCT SPECIFICATION */}
                    <div className="col-12 mb-5">
                        <label htmlFor="doc" className="form-label mb-3">Upload Ingredients Document</label><i className="text-muted text-xs"> (Optional)</i>

                        <p className="text-1 bold-2">
                            Existing file - {course.doc ? <span key={course.doc.name} className="me-2 bold-1">{course.doc.name}</span> : "None"}
                        </p>

                        <input 
                            type="file"
                            name="doc"
                            className="form-control text-xs w-100 p-2 border"
                            id="doc"
                            accept="application/pdf"
                            onChange={onDocChange}
                            disabled={state === "Working"}
                        />
                    </div>

                    {/* BUTTON */}
                    <div className="d-flex flex-column mt-md-5 align-items-end">

                      <button className="upload-btn text-xs mt-5 mt-md-0"
                        type='submit'
                        disabled={!isChange.current || state === "Working"}
                        >{loading || state === "Working" ? "Updating course" : "Update course"}</button>
 
                    </div>

                </div>
            </form>
        )}
      </div>
    </Fragment>
  );
};

export default UpdateCourse;

import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Metadata from '../layout/metadata'

import { register, clearErrors } from '../../actions/userActions'
import { REGISTER_USER_RESET } from '../../constants/userConstants'

import { initGA, logPageView } from '../../GoogleAnalytics';

const Register = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let [user, setUser] = useState({
        firstName: '',
        lastName: '',
        credential: '',
        password: ''
    });

    let { firstName, lastName, credential, password } = user;

    const { isAuthenticated, isSuccess, error, loading } = useSelector(state => state.auth);

    useEffect(() => {
        
        initGA()
        logPageView()

        if (isAuthenticated) {
            navigate('/')
        }

        if (isSuccess) {
            navigate('/signin')
            toast.success("Account successfully created, Please verify your email before you can sign in")
            dispatch({ type: REGISTER_USER_RESET })
        }

        if (error) {
            if (error.includes("E11000")){
                toast.error("This user already exists. Please use a different email address or go to sign in.")
            } else toast.error("An error occurred, please check your internet connection and try again.")
            dispatch(clearErrors());
        }

    }, [dispatch, isSuccess, isAuthenticated, error])

    const submitHandler = (e) => {
        e.preventDefault();

        if (credential.length === 0) return toast.error("Please enter a valid email address")
        else if (credential.charAt(0).match(/[a-z]/i) && !credential.match(/(^\+(?:[0-9]●?){6,14}[0-9]$)|(^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$)/)) return toast.error("Please enter a valid email address")

        user.credential = user.credential.toLowerCase();
        user.credential = user.credential.trim();
        dispatch(register(user));
    }

    const onChange = e => {
        setUser({...user, [e.target.name]: e.target.value })
    }

  return (
    <Fragment>
        <Metadata title={'Register'} />
        <ToastContainer />
            <div className="container-fluid container-md">
                <div className="row shadow-lg mid-container">
                    <div className="col-md-8 py-5 px-md-4">
                        <form className="px-2" onSubmit={submitHandler} encType="application/json">
                            <Link to={"/signin"} className="text-xs">{"< Back to Signin"}</Link>
                            <h3 className="text-lg bold-2 mb-4 mt-4">Sign up</h3>

                            <div className="row">

                                {/* FIRST NAME */}
                                <div className="col-md-6 mb-4">
                                    <label htmlFor="first-name" className="form-label">First Name</label> <span className="red-color">*</span>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        max={30}
                                        id="first-name"
                                        name='firstName' 
                                        value={firstName}
                                        onChange={onChange}
                                        aria-describedby="firstNameHelp" 
                                        placeholder="E.g. John"
                                        required
                                    />
                                </div>

                                {/* LAST NAME */}
                                <div className="col-md-6 mb-4">
                                    <label htmlFor="last-name" className="form-label">Last Name</label> <span className="red-color">*</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="last-name" 
                                        max={30}
                                        name='lastName' 
                                        value={lastName}
                                        onChange={onChange}
                                        aria-describedby="lastNameHelp" 
                                        placeholder="E.g. Smith"
                                        required
                                    />
                                </div>
                            
                                {/* CREDENTIAL */}
                                <div className="col-12 mb-4">
                                    <label htmlFor="credential" className="form-label">Email address</label> <span className="red-color">*</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="credential" 
                                        name='credential' 
                                        value={credential}
                                        onChange={onChange}
                                        aria-describedby="credentialHelp" 
                                        placeholder="E.g. john@email.com"
                                        required
                                    />
                                    <div id="credentialHelp" className="text-xs mt-2 red">
                                    { credential.match(/(^\+(?:[0-9]●?){6,14}[0-9]$)|(^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$)/) 
                                        ? <span className="green bold-1">A verification link will be sent to this email</span> 
                                        : "Please enter a valid email address"
                                    }
                                    </div>
                                </div>

                                {/* PASSWORD */}
                                <div className="col-12 mb-4">
                                    <label htmlFor="Password" className="form-label">Set Password</label> <span className="red-color">*</span>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        id="Password" 
                                        name='password'
                                        minLength="8" 
                                        value={password}
                                        onChange={onChange}
                                        placeholder="**********"
                                        required
                                    />
                                    <div id="passHelp" className={`text-xs mt-2 ${password.length >= 8 ? "green" : "red   "}`}>Password must have a minimum of 8 characters</div>
                                </div>
                            </div>

                            <button 
                                type="submit" 
                                id="register-button"
                                className="action-btn my-4 w-100"
                                disabled={loading ? true : false}
                            >{loading ? "Creating your account, please wait..." : "Create Account"}
                            </button>
                            <div className="form-label text-center">By creating an account, you agree to the OmoyeCooksAcademy</div>
                            <div className="text-center"><Link to="#" className="text-sm">Terms and Conditions and Privacy Policy</Link></div>
                            <div className="form-label text-center mt-3">Already have an account? <Link to="/signin" className="text-end">Sign In</Link></div>
                        </form>
                    </div>
                    <div className="col-md-4" style={{backgroundImage: "url('/images/signin-img.jpg')", backgroundSize: "cover", backgroundPosition: "center"}}>
                    </div>
                </div>
            </div>    
    </Fragment>
  )
}

export default Register
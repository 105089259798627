import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import AdminNavigation from '../layout/adminNavigation'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getUserDetails, clearErrors } from '../../actions/userActions'


const UserDetails = () => {

    const dispatch = useDispatch();
    const params = useParams();

    const { loading, error, user } = useSelector(state => state.userDetails);

    useEffect(() => {
        dispatch(getUserDetails(params.id));

        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            console.log(error)
            dispatch(clearErrors())
        }

    }, [dispatch, error, params.id])

    return (
        <Fragment>
            <Metadata title={"User Details"}/>
            <AdminNavigation Users />
            <ToastContainer/>

            <div className="container-fluid container-md">
                <div className="py-3 mb-3">
                    <Link to="/admin/users" className="text-dark text-xs">{"< Back to Users"}</Link>
                </div>
                {loading ?                         
                    <Fragment>
                        <div className="my-4">
                            <Skeleton count={15} height={40} baseColor="#dc720082"/>
                        </div>
                    </Fragment> :
                    <Fragment>
                        <div className="d-flex align-items-center my-5">
                            <h3 className="text-xl text-muted d-inline-block m-0 mb-5 bold-2">{user.firstName} {user.lastName}</h3>
                        </div>
                    </Fragment> 
                }                            
            </div>
        </Fragment>
    )
}

export default UserDetails;
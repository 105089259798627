// FORMAT TOTAL PROJECT SCORE (N100,000,000 => 1B)
function costFormatter(num) {
    if (Math.abs(num) <= 999) return num
    else if (!num) return;
    else if (Math.abs(num) > 999 && Math.abs(num) < 1000000) return Math.sign(num)*((Math.abs(num)/1000).toFixed(2)) + 'K'
    else if (Math.abs(num) >= 1000000 && Math.abs(num) < 1000000000) return Math.sign(num)*((Math.abs(num)/1000000).toFixed(2)) + 'M'
    else if (Math.abs(num) >= 1000000000 && Math.abs(num) < 1000000000000) return Math.sign(num)*((Math.abs(num)/1000000000).toFixed(2)) + 'B'
    else if (Math.abs(num) >= 1000000000000) return Math.sign(num)*((Math.abs(num)/1000000000000).toFixed(2)) + 'T'
}
// FORMAT NUMBER WITH COMMAS
function numberWithCommas(x) {
    if (x == 0) return x;
    if (!x) return;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// ARRAY REMOVE
function arrayRemove(arr, value) { 
    
    return arr.filter(function(ele){ 
        return ele != value; 
    });
}

// FORMAT DATE
function formatDate(date) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
};

exports.formatDate = formatDate
exports.costFormatter = costFormatter
exports.numberWithCommas = numberWithCommas
exports.arrayRemove = arrayRemove
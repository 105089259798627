import axios from 'axios';
import {
    NEW_CLASS_REQUEST,
    NEW_CLASS_SUCCESS,
    NEW_CLASS_FAIL,
    GET_CLASSES_REQUEST,
    GET_CLASSES_SUCCESS,
    GET_CLASSES_FAIL,
    CLASS_DETAILS_REQUEST,
    CLASS_DETAILS_SUCCESS,
    CLASS_DETAILS_FAIL,
    CLEAR_ERRORS
} from '../constants/classConstants'

export const newClass = (classData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_CLASS_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/admin/classes/new', classData, config)

        dispatch({
            type: NEW_CLASS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_CLASS_FAIL,
            payload: error.response.data.errMessage
        })
    }
};

export const getClasses = (role) => async (dispatch) => {
    let URL = '/api/v1/admin/classes';
    if (role !== "Admin") { URL = '/api/v1/classes' }

    try {
        dispatch({ type: GET_CLASSES_REQUEST })

        const { data } = await axios.get(URL)

        dispatch({
            type: GET_CLASSES_SUCCESS,
            payload: data.classes
        })
    } catch (error) {
        dispatch({
            type: GET_CLASSES_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getClassDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: CLASS_DETAILS_REQUEST })
        const { data } = await axios.get(`/api/v1/admin/classes/${id}`)

        dispatch({
            type: CLASS_DETAILS_SUCCESS,
            payload: data.singleClass
        })
    } catch (error) {
        dispatch({
            type: CLASS_DETAILS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

// CLEAR ERRORS
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}
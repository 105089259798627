import axios from 'axios';
import {
    NEW_RECIPE_REQUEST,
    NEW_RECIPE_SUCCESS,
    NEW_RECIPE_FAIL,
    UPDATE_RECIPE_REQUEST,
    UPDATE_RECIPE_SUCCESS,
    UPDATE_RECIPE_FAIL,
    DELETE_RECIPE_REQUEST,
    DELETE_RECIPE_SUCCESS,
    DELETE_RECIPE_FAIL,
    GET_RECIPES_REQUEST,
    GET_RECIPES_SUCCESS,
    GET_RECIPES_FAIL,
    RECIPE_DETAILS_REQUEST,
    RECIPE_DETAILS_SUCCESS,
    RECIPE_DETAILS_FAIL,
    CLEAR_ERRORS
} from '../constants/recipeConstants'

export const newRecipe = (recipeData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_RECIPE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/admin/recipes/new', recipeData, config)

        dispatch({
            type: NEW_RECIPE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_RECIPE_FAIL,
            payload: error.response.data.errMessage
        })
    }
};

export const getRecipes = (role) => async (dispatch) => {
    let URL = '/api/v1/recipes';
    if (role === "Admin") { URL = '/api/v1/admin/recipes' }

    try {
        dispatch({ type: GET_RECIPES_REQUEST })

        const { data } = await axios.get(URL)

        dispatch({
            type: GET_RECIPES_SUCCESS,
            payload: data.recipes
        })
    } catch (error) {
        dispatch({
            type: GET_RECIPES_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getRecipeDetails = (name, role) => async (dispatch) => {
    let URL =  `/api/v1/recipes/${name}`;
    if (role === "Admin") { URL =  `/api/v1/admin/recipes/${name}` }

    try {
        dispatch({ type: RECIPE_DETAILS_REQUEST })
        const { data } = await axios.get(URL)

        dispatch({
            type: RECIPE_DETAILS_SUCCESS,
            payload: data.recipe
        })
    } catch (error) {
        dispatch({
            type: RECIPE_DETAILS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const updateRecipe = (name, update) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_RECIPE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/recipes/${name}`, update, config)

        dispatch({
            type: UPDATE_RECIPE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: UPDATE_RECIPE_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const deleteRecipe = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_RECIPE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.delete(`/api/v1/admin/recipes/${id}`, config)

        dispatch({
            type: DELETE_RECIPE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETE_RECIPE_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

// CLEAR ERRORS
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}
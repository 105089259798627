export const GET_COURSES_REQUEST = 'GET_COURSES_REQUEST'
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS'
export const GET_COURSES_FAIL = 'GET_COURSES_FAIL'

export const NEW_COURSE_REQUEST = 'NEW_COURSE_REQUEST'
export const NEW_COURSE_SUCCESS = 'NEW_COURSE_SUCCESS'
export const NEW_COURSE_RESET = 'NEW_COURSE_RESET'
export const NEW_COURSE_FAIL = 'NEW_COURSE_FAIL'

export const UPDATE_COURSE_REQUEST = 'UPDATE_COURSE_REQUEST'
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS'
export const UPDATE_COURSE_FAIL = 'UPDATE_COURSE_FAIL'
export const UPDATE_COURSE_RESET = 'UPDATE_COURSE_RESET'

export const ENROLL_COURSE_REQUEST = 'ENROLL_COURSE_REQUEST'
export const ENROLL_COURSE_SUCCESS = 'ENROLL_COURSE_SUCCESS'
export const ENROLL_COURSE_FAIL = 'ENROLL_COURSE_FAIL'
export const ENROLL_COURSE_RESET = 'ENROLL_COURSE_RESET'

export const COURSE_DETAILS_REQUEST = 'COURSE_DETAILS_REQUEST'
export const COURSE_DETAILS_SUCCESS = 'COURSE_DETAILS_SUCCESS'
export const COURSE_DETAILS_RESET = 'COURSE_DETAILS_RESET'
export const COURSE_DETAILS_FAIL = 'COURSE_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import AdminNavigation from '../layout/adminNavigation'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { getPromos, deletePromo, clearErrors } from '../../actions/promoActions'

import { CREATE_PROMO_RESET, UPDATE_PROMO_RESET } from '../../constants/promoConstants'
import { formatDate } from '../../Functions/functions'

import TargetsModal from './promoTargetsModal'
import DeleteModal from '../layout/deleteModal'


const AllPromos = () => {
    let clicked = useRef("All");
    let firstLoad = useRef(true);

    const dispatch = useDispatch();

    const { loading, error, promos } = useSelector(state => state.getPromos);
    const { loading: modifyLoading, error: modifyError, success } = useSelector(state => state.modifyPromo);

    let [ filteredPromos, setFilteredPromos ] = useState(promos)
    let [targets, setTargets] = useState(["hellow"])
    let [idToDelete, setToDelete] = useState(null)

    if (firstLoad.current) { filteredPromos = promos };

    useEffect(() => {
        dispatch(getPromos("Admin"));
        dispatch({ type: CREATE_PROMO_RESET })

        if (success) {
            toast.success("Deleted successfully! 👍");
            dispatch({type: UPDATE_PROMO_RESET});
        }

        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }

        if (modifyError) {
            toast.error("An error occurred, please check your internet connection.")
            dispatch(clearErrors())
        }

    }, [dispatch, error, success])


    const onSearchChange = (e) => {
        if (e.target.value === "") {
            return setFilteredPromos(promos)
        }
        let matches = promos.filter(promos => promos.targetType.toLowerCase().includes(e.target.value.toLowerCase()))

        setFilteredPromos(matches);
        firstLoad.current = false;
    }

    const toggleTable = (promoStatus) => {
        firstLoad.current = false
        let filter
        clicked.current = promoStatus
        if (promoStatus === "All") {
            filter = promos;
        } else if (promoStatus === "Active") {
            filter = promos.filter(promo => new Date() >= new Date(promo.startDate) && new Date() <= new Date(promo.endDate))
        } else if (promoStatus === "Inactive") {
            filter = promos.filter(promo => new Date() <= new Date(promo.startDate) || new Date() >= new Date(promo.endDate))
        } else {
            filter = []
        }

        setFilteredPromos(filter)
    }

    const rowData = [];

    const getClickedButton = (clickedButton) => {
        if (clickedButton === "Delete") {
            dispatch(deletePromo(idToDelete));
        }
    }

    return (
        <Fragment>
            <Metadata title={"All Promos"}/>
            <AdminNavigation Promos />
            <ToastContainer/>
            <div className="container-fluid container-md">
                <Fragment>
                    <div className="d-flex align-items-center mt-3">
                        <h3 className="text-2 text-muted d-inline-block m-0 me-4 bold-2">PROMOS</h3>
                        <div className="d-flex ms-auto align-items-center">
                            <input
                            className="form-control d-inline-block py-1" 
                            type="search" 
                            placeholder="Search promos"
                            aria-label="Search"
                            onChange={onSearchChange}
                            />
                            <Link to="/admin/promos/add-promo" className="text-2 bold-2 d-none d-sm-block add-btn w-75 ms-2">+ Add promo</Link>
                            <Link to="/admin/promos/add-promo" className="d-sm-none px-3 bold-3 add-btn ms-2">+</Link>
                        </div>
                    </div>

                    {/* FILTER */}
                    <div className="d-flex flex-column flex-md-row justify-content-between mt-3">
                        <span className="text-muted text-2 order-1 order-md-2">{filteredPromos.length} {clicked.current === 'All' ? 'promos' : clicked.current + " promos"}</span>
                        <div className="table-nav-block order-2 order-md-1 mt-3 mt-md-0">
                            <button onClick={function() { toggleTable('All') }} className={`table-nav ms-0 ${clicked.current === 'All' ? 'table-active':''}`}>All</button>
                            <button onClick={function() { toggleTable('Active') }} className={`table-nav ${clicked.current === 'Active' ? 'table-active':''}`}>Active</button>
                            <button onClick={function() { toggleTable('Inactive') }} className={`table-nav ${clicked.current === 'Inactive' ? 'table-active':''}`}>Inactive</button>
                        </div>
                    </div>
                    {/* FILTER END */}

                    {loading || modifyLoading ? 
                        <Fragment>
                            <div className="my-4">
                                <Skeleton count={15} height={20} baseColor="#dc720082"/>
                            </div>
                        </Fragment> : (
                        <Fragment>
                            <table className="table mt-3 mb-5">
                                <thead className="table-secondary py-5">
                                    <tr>
                                        <th scope="col">Promo Type</th>
                                        <th scope="col">Target Type</th>
                                        <th scope="col" className="text-center">Discount Value</th>
                                        <th scope="col" className="text-center">Start Date</th>
                                        <th scope="col" className="text-center">End Date</th>
                                        <th scope="col" className="text-center">Status</th>
                                        <th scope="col" className="text-center">Action</th>
                                    </tr>
                                </thead>

                                {filteredPromos.forEach(promo => rowData.push(
                                    <tr className="bg-white" key={promo._id}>
                                        <td className="text-2">{promo.promoType}</td>
                                        <td className="text-2">{promo.targetType}</td>
                                        <td className="text-2 text-center">{promo.discountValue}% off</td>
                                        <td className="text-2 text-center">{formatDate(promo.startDate)}</td>
                                        <td className="text-2 text-center">{formatDate(promo.endDate)}</td>
                                        <td className="text-2 text-center">{new Date() >= new Date(promo.startDate) && new Date() <= new Date(promo.endDate) ? "Active" : "Inactive"}</td>
                                        <td className="text-center text-2">
                                            <i className="fa-solid fa-clipboard-list fa-lg me-3" onClick={function() {setTargets(promo.targetValues.length > 0 ? promo.targetValues : [promo.targetType])}} data-bs-toggle="modal" data-bs-target="#promo-target-modal"></i>
                                            <i className="fa-solid fa-trash fa-lg" onClick={function() {setToDelete(promo._id)}} data-bs-toggle="modal" data-bs-target="#delete-modal"></i>
                                        </td>
                                    </tr>                                 
                                ))}
                                <tbody>{rowData}</tbody>
                            </table>
 
                            <TargetsModal targets={targets}/>
                            <DeleteModal title="All Promos" item="promo" onSelect={getClickedButton}/>

                            { filteredPromos.length === 0 ? (
                            <div className="text-center mb-5 w-100">
                                <h3 className='text-sm'>NO DATA</h3>
                            </div>
                            ):(<Fragment></Fragment>)}

                        </Fragment>
                    )}
                
                </Fragment>                              
                {/* )} */}
            </div>
        </Fragment>
    )
}

export default AllPromos;
import axios from 'axios';
import {
    GET_NOTIFICATIONS_REQUEST,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAIL,
    CLEAR_ERRORS
} from '../constants/notificationConstants'

export const getNotifications = () => async (dispatch) => {
    try {
        dispatch({ type: GET_NOTIFICATIONS_REQUEST })

        const { data } = await axios.get(`/api/v1/notifications`)

        dispatch({
            type: GET_NOTIFICATIONS_SUCCESS,
            payload: data.notifications
        })
    } catch (error) {
        dispatch({
            type: GET_NOTIFICATIONS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

// CLEAR ERRORS
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}
import { 
    CREATE_SHIPPING_ADDRESS_REQUEST,
    CREATE_SHIPPING_ADDRESS_SUCCESS,
    CREATE_SHIPPING_ADDRESS_RESET,
    CREATE_SHIPPING_ADDRESS_FAIL,
    GET_SHIPPING_ADDRESSES_REQUEST,
    GET_SHIPPING_ADDRESSES_SUCCESS,
    GET_SHIPPING_ADDRESSES_FAIL,
    SHIPPING_ADDRESS_DETAILS_REQUEST,
    SHIPPING_ADDRESS_DETAILS_SUCCESS,
    SHIPPING_ADDRESS_DETAILS_FAIL,
    SHIPPING_ADDRESS_DETAILS_RESET,
    UPDATE_SHIPPING_ADDRESS_REQUEST,
    UPDATE_SHIPPING_ADDRESS_SUCCESS,
    UPDATE_SHIPPING_ADDRESS_FAIL,
    UPDATE_SHIPPING_ADDRESS_RESET,
    DELETE_SHIPPING_ADDRESS_REQUEST,
    DELETE_SHIPPING_ADDRESS_SUCCESS,
    DELETE_SHIPPING_ADDRESS_FAIL,
    DELETE_SHIPPING_ADDRESS_RESET,
    CLEAR_ERRORS
} from '../constants/shippingAddressConstants'

export const getShippingAddressesReducer = (state = { shippingAddresses:[] }, action) => {
switch(action.type) {
    case GET_SHIPPING_ADDRESSES_REQUEST:
        return {
            loading: true,
            shippingAddresses: []
        }

    case GET_SHIPPING_ADDRESSES_SUCCESS:
        return {
            loading: false,
            shippingAddresses: action.payload
        }
    case GET_SHIPPING_ADDRESSES_FAIL:
        return {
            loading: false,
            error: action.payload
        }
    case CLEAR_ERRORS:
        return {
            ...state,
            error: null
        }
    default:
        return state;
}
}

export const modifyShippingAddressReducer = (state = { shippingAddress: {} }, action) => {
switch(action.type) {
    case CREATE_SHIPPING_ADDRESS_REQUEST:
    case UPDATE_SHIPPING_ADDRESS_REQUEST:
    case DELETE_SHIPPING_ADDRESS_REQUEST:
        return {
            ...state,
            loading: true,
        }
    case CREATE_SHIPPING_ADDRESS_SUCCESS:
    case UPDATE_SHIPPING_ADDRESS_SUCCESS:
    case DELETE_SHIPPING_ADDRESS_SUCCESS:
        return {
            loading: false,
            success: true,
            shippingAddress: action.payload.shippingAddress

        }

    case CREATE_SHIPPING_ADDRESS_FAIL:
    case UPDATE_SHIPPING_ADDRESS_FAIL:
    case DELETE_SHIPPING_ADDRESS_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload
        }

    case CREATE_SHIPPING_ADDRESS_RESET:
    case UPDATE_SHIPPING_ADDRESS_RESET:
    case DELETE_SHIPPING_ADDRESS_RESET:
        return {
            ...state,
            success: false,
        }
    case CLEAR_ERRORS:
        return {
            ...state,
            error: null
        }
    default:
        return state;
}
}

export const shippingAddressDetailsReducer = (state = {shippingAddress: {}}, action) => {
    switch (action.type) {
        case SHIPPING_ADDRESS_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false
            }
        case SHIPPING_ADDRESS_DETAILS_SUCCESS:
            return {
                loading: false,
                shippingAddress: action.payload,
                success: true
            }
        case SHIPPING_ADDRESS_DETAILS_FAIL:
            return {
                loading: false,
                success: false,
                error: action.payload
            }
        case SHIPPING_ADDRESS_DETAILS_RESET:
            return {
                loading: false,
                success: false,
                shippingAddress: {}
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import AdminNavigation from '../layout/adminNavigation'
import { costFormatter, numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'


const Payments = () => {
    const dispatch = useDispatch();

    useEffect(() => {

    }, [])

    return (
        <Fragment>
            <Metadata title={'Dashboard'}/>
            <AdminNavigation Dashboard/>
            <div className="container-fluid container-md mt-5">
            <Fragment>
                <i className="mid-container-header text-xl bold-2 text-muted mb-5">HELLO, OMOYE!</i>
                <h5 className="my-5">YOUR PAYMENTS WILL BE HERE</h5>
            </Fragment>
            </div>
        </Fragment>
    )
}

export default Payments
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import AdminNavigation from '../layout/adminNavigation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getRecipeDetails, clearErrors } from '../../actions/recipeActions'

import RecipeDeleteModal from './recipeDeleteModal'

const AdminRecipeDetails = () => {

    const dispatch = useDispatch();
    const params = useParams();

    const { loading, error, success, recipe } = useSelector(state => state.recipeDetails);

    const [refresh, setRefresh] = useState("")

    useEffect(() => {
        dispatch(getRecipeDetails(params.name, "Admin"));

        if (error) {
            dispatch(clearErrors())
        }

    }, [dispatch, error, params.name])

    const handleContextMenu = (e) => {
        e.preventDefault();
    }

    return (
        <Fragment>
            <Metadata title={"Recipe Details"}/>
            <AdminNavigation Recipes />
            <ToastContainer/>
            <div className="container-fluid container-md mb-5">

                <Fragment>
                    <div className="py-3 mb-3">
                        <Link to="/admin/recipes" className="text-dark text-xs">{"< Back to recipes"}</Link>
                    </div>

                {loading || !recipe  || !recipe.image ? 
                    <div className="my-4">
                        <Skeleton count={15} height={20} baseColor="#dc720082"/>
                    </div> : 
                
                    <div className="mb-4">

                        <div className="row">
                            {recipe.video && recipe.video.URL ? 
                                <div className="col-sm-6 col-lg-4">
                                    <video onContextMenu={handleContextMenu} className="recipe-img" controls poster={recipe.image.URL}>
                                        <source src={recipe.video.URL} type={recipe.video.type} />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                :
                                <div className="col-sm-6 col-lg-4">
                                    <div className="recipe-img" style={{backgroundImage: `url(${recipe.image.URL})`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                                </div>
                            }



                            <div className="col-sm-6 col-lg-8 ps-sm-3 ps-lg-5 mt-4 mt-sm-0">
                                <h3 className="text-sm bold-2 orange mb-4">KEY - {recipe.key}</h3>

                                <h3 className="text-sm bold-2 orange">{recipe.name}</h3>
                                
                                <p className="text-xs mt-2 spaced mb-0">{recipe.description}</p>
        
        
                                <div className="row my-4">
                                    {/* INGREDIENTS */}
                                    <div className="col-lg-6 mb-3 mb-lg-0">
                                        <h3 className="text-sm bold-2 orange mb-0 mb-lg-3">Ingredients:</h3>
                                        { recipe.name && recipe.ingredients.split('•').slice(1).map(ingredient => (
                                            <p key={ingredient} className="text-xs my-2 p-0">● {ingredient}</p>
                                        ))}
                                    </div>
        
                                    {/* STEPS */}
                                    <div className="col-lg-6 mt-md-0">
                                        <h3 className="text-sm bold-2 orange mb-0 mb-lg-3">Cooking Steps:</h3>
                                        { recipe.name && recipe.steps.split('•').slice(1).map(step => (
                                            <p key={step} className="text-xs my-2 p-0">● {step}</p>
                                        ))}
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <Link to={`/admin/recipes/${recipe.name}/update-recipe`} state={{firstLoad: true}} className="text-xs action-btn py-2 me-2">Update</Link>
                                    <button className="text-xs action-btn" data-bs-toggle="modal" data-bs-target="#deleteModal">Delete</button>
                                    <RecipeDeleteModal props={{type: "Recipe", data: recipe}}/>
                                </div>


                            </div>
                        </div>

                    </div>
                }
                </Fragment>                              
            </div>
        </Fragment>
    )
}

export default AdminRecipeDetails;
import React, { Fragment, useEffect } from 'react'
import Metadata from '../layout/metadata'
import { numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'
import { getOrderDetails, clearErrors } from '../../actions/orderActions'

import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { formatDate } from '../../Functions/functions'

const OrderDetails = () => {

    const dispatch = useDispatch();

    const { order, error, loading } = useSelector(state => state.orderDetails)

    const params = useParams();

    useEffect(() => {
        dispatch(getOrderDetails(params.id))

        if (error) {
            console.log(error, "ERRROOORR!! - order/orderDetails.jsx")
            dispatch(clearErrors())
        }
    }, [dispatch, error, params.id])

    const rowData = []
    return (
        <Fragment>
            <Metadata title={'Order Details'}/>
            <div className="container-md green-color my-4 ps-4 ps-md-2">
                <Link to="/shop/orders" className="text-2">{"< Back to Orders"}</Link>
            </div>

            <div className="container-md mb-5 pt-2">
                {loading ? 
                    <div className="my-4">
                        <Skeleton count={15} height={20} baseColor="#dc720082"/>
                    </div> : (
                    <Fragment>
                    <h3 className="text-2">ORDER <span className="text-muted">#{order._id}</span></h3>
                    
                    <hr className="mb-3" />

                    <p className="text-2">Created: {formatDate(order.createdAt)}</p>
                    {order.user ? (
                        <p className="text-2">Order By: <strong>{order.user.firstName} {order.user.lastName}</strong></p>
                        ):(<Fragment></Fragment>)
                    }
                    <p className="text-2">Status: <strong>{order.orderStatus}</strong></p>
                    <p className="text-2">Items Price: <strong> &#x20A6;{numberWithCommas(order.itemsPrice)}</strong></p>
                    <p className="text-2">Shipping: <strong> &#x20A6;{numberWithCommas(order.shippingPrice)}</strong></p>
                    <p className="text-2">Tax: <strong> &#x20A6;{numberWithCommas(order.taxPrice)}</strong></p>
                    <p className="text-2">Total: <strong className="green-color"> &#x20A6;{numberWithCommas(order.totalPrice)}</strong></p>
                    <p className="text-2">Payment Status: <strong>{order.paidAt ? "Paid" : "Not Paid"}</strong></p>
                    {order.shippingInfo ? (
                        <p className="text-2">Payment Method: <strong>{order.paymentMethod}</strong>{order.financeStatus ? ` - ${order.financeStatus}` : ""}</p>
                    ):(<Fragment></Fragment>)}
                    
                    <hr className="my-4" />

                    <div className="d-flex justify-content-between align-items-center my-4">
                        <h3 className="text-2 orange bold-3 m-0">Products in Order</h3>
                        {order.orderItems ? (
                            <span className="text-1 text-muted">({order.orderItems.length} Products)</span>
                        ):(<Fragment></Fragment>)}
                    </div>
            
                    <table className="table">
                        <thead className="table-secondary py-5">
                            <tr>
                                <th scope="col"><i className="fa-solid fa-image"></i></th>
                                <th scope="col">Product name</th>
                                <th scope="col">Price</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>
                        {order.orderItems ? (
                            order.orderItems.forEach(item => rowData.push(
                                <tr className="bg-white" key={item._id}>
                                    <td><div style={{backgroundImage: `url('${item.image}')`, backgroundSize: 'cover'}} className="table-image"></div></td>
                                    <td className="text-2">{item.name}</td>
                                    <td className="text-2">&#x20A6;{numberWithCommas(item.price)}</td>
                                    <td className="text-2">{numberWithCommas(item.quantity)}</td>
                                    <td className="text-2">&#x20A6;{numberWithCommas((item.quantity * item.price).toFixed(2))}</td>
                                </tr>                                 
                            ))
                        ):(<Fragment></Fragment>)}

                        <tbody>{rowData}</tbody>
                    </table>

                    {order.shippingInfo ? (
                        <Fragment>
                            <h3 className="text-2 orange bold-3 mt-5 mb-4">Shipping Information</h3>
                            <p className="text-2">Address: <strong>{order.shippingInfo.street}</strong></p>
                            <p className="text-2">City: <strong>{order.shippingInfo.city}</strong></p>
                            <p className="text-2">State: <strong>{order.shippingInfo.state}</strong></p>
                            <p className="text-2">Country: <strong>{order.shippingInfo.country}</strong></p>
                            <p className="text-2">Phone Number: <strong>{order.shippingInfo.phoneNumber}</strong></p>
                        </Fragment>
                    ):(<Fragment></Fragment>)}
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}

export default OrderDetails
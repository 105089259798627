import React, { Fragment, useEffect, useState } from 'react';

import { Link, useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

import Metadata from '../layout/metadata'
import AdminNavigation from '../layout/adminNavigation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { CREATE_PROMO_RESET } from '../../constants/promoConstants'
import { newPromo, clearErrors } from '../../actions/promoActions'
import { getProducts } from '../../actions/productActions'
import { getCourses } from '../../actions/courseActions'

const animatedComponents = makeAnimated();

const AddPromo = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const { error, loading, success } = useSelector(state => state.modifyPromo)
    const { products } = useSelector(state => state.getProducts)
    const { courses } = useSelector(state => state.getCourses);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [promo, setPromo] = useState({
        promoType: "Percentage",
        targetType:  "All products", 
        targetValues:  [], 
        discountValue:  10,
        startDate:  "",
        endDate:  ""
    })

    const { promoType, targetType, discountValue, startDate, endDate } = promo;

    const productCategoryOptions = [
        {label: 'Ebooks', value: 'Ebooks'},
        {label: 'Spices', value: 'Spices'},
        {label: 'Cookbooks', value: 'Cookbooks'},
        {label: 'Tshirts', value: 'Tshirts'},
        {label: 'Utensils', value: 'Utensils'},
        {label: 'Services', value: 'Services'}
    ]

    const productOptions = products.map((product) => ({
        value: product._id,
        label: product.name,
    }));

    const courseOptions = courses.map((course) => ({
        value: course._id,
        label: course.name,
    }));

    useEffect(() => {
        dispatch(getProducts())
        dispatch(getCourses())
        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }
        if (success) {
            toast.success("Successfully added Promo 👍")
            dispatch({ type: CREATE_PROMO_RESET })
            navigate(`/admin/promos`)
        }
    }, [dispatch, error, success])


    const promoOnChange = (event) => {
        if (event.target.name === "targetType") setSelectedOptions([])
        setPromo({...promo, [event.target.name]: event.target.value });
    };

    const handleTargetValueChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        promo.targetValues = selectedOptions
        dispatch(newPromo(promo))
    }

    return (
        <Fragment>
            <Metadata title={'Add Promo'} />
            <AdminNavigation Promos/>
            <ToastContainer />
            <div className="container-fluid container-md bg-light shadow-lg p-0 mb-4">
            <div className="bg-dark border rounded p-3">
                <h3 className="text-sm text-light m-0">Add Promo</h3>
            </div>

            <div className="p-3">
                <Link to="/admin/promos" className="text-dark text-xs">{"< Back to Promos"}</Link>
            </div>

            <form onSubmit={submitHandler} className="p-3 pb-5">
                <div className="row">

                {/* PROMO TYPE */}
                <div className="col-md-6 mb-4">
                    <label htmlFor="promoType" className="form-label">Promo Type</label><i className="red">*</i>
                    <select name="promoType" value={promoType} onChange={promoOnChange} className="form-control" required>
                        <option value="Percentage">Percentage Discount</option>
                        {/* <option value="free_shipping">Free Shipping</option> */}
                    </select>
                </div>

                {/* TARGET TYPE */}
                <div className="col-md-6 mb-4">
                    <label htmlFor="targetType" className="form-label">Target Type</label><i className="red">*</i>
                    <select name="targetType" value={targetType} onChange={promoOnChange} className="form-control" required>
                        <option value="All products">All products</option>
                        <option value="Product category">Product categories</option>
                        <option value="Single products">Single products</option>
                        <option value="All courses">All courses</option>
                        <option value="Single courses">Single courses</option>
                    </select>
                </div>

                {/* PRODUCT CATEGORY */}
                {targetType === 'Product category' && (
                    <div className="col-md-12 mb-4">
                        <label htmlFor="targetValues" className="form-label">Select Product Categories</label><i className="red">*</i>
                        <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            name="targetValues"
                            isMulti
                            className="w-100 form-control"
                            options={productCategoryOptions}
                            value={selectedOptions}
                            onChange={handleTargetValueChange}
                            placeholder="Select categories..."
                            required
                        />
                    </div>
                )}

                {/* INDIVIDUAL PRODUCTS */}
                {targetType === 'Single products' && (
                    <div className="col-md-12 mb-4">
                        <label htmlFor="targetValues" className="form-label">Select Products</label><i className="red">*</i>
                        <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            name="targetValues"
                            isMulti
                            className="w-100 form-control"
                            options={productOptions}
                            value={selectedOptions}
                            onChange={handleTargetValueChange}
                            placeholder="Select products..."
                            required
                        />
                    </div>
                )}

                {/* INDIVIDUAL COURSES */}
                {targetType === 'Single courses' && (
                    <div className="col-md-12 mb-4">
                        <label htmlFor="targetValues" className="form-label">Select Courses</label><i className="red">*</i>
                        <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            name="targetValues"
                            isMulti
                            className="w-100 form-control"
                            options={courseOptions}
                            value={selectedOptions}
                            onChange={handleTargetValueChange}
                            placeholder="Select courses..."
                            required
                        />
                    </div>
                )}

                {/* DISCOUNT VALUE */}
                <div className="col-md-4 mb-4">
                    <label htmlFor="discountValue" className="form-label">Discount Value (%)</label><i className="red">*</i>
                    <input 
                        type="number" 
                        className="form-control" 
                        id="discountValue" 
                        name='discountValue' 
                        value={discountValue} 
                        onChange={promoOnChange}
                        placeholder="E.g. 15"
                        required
                    />
                </div>

                {/* START DATE */}
                <div className="col-md-4 mb-4">
                    <label htmlFor="startDate" className="form-label">Start Date</label><i className="red">*</i>
                    <input
                        type="date"
                        className="form-control" 
                        id="startDate" 
                        name="startDate"
                        value={startDate} 
                        onChange={promoOnChange}
                        placeholder="Select promo start date..."
                        required
                    />
                </div> 

                {/* END DATE */}
                <div className="col-md-4 mb-4">
                    <label htmlFor="endDate" className="form-label">End Date</label><i className="text-xs red bold-2">*</i>
                    <input
                        type="date"
                        className="form-control" 
                        id="endDate" 
                        name="endDate"
                        value={endDate} 
                        onChange={promoOnChange}
                        placeholder="Select promo end date..."
                        required
                    />
                </div>

                {/* ###################################### */}

                {/* BUTTON */}
                <div className="d-flex flex-column align-items-end">
                    <button className="action-btn text-xs mt-4 mt-md-0" type='submit' disabled={loading}>{loading ? "Adding promo..." : "Add promo"}</button>
                </div>
                </div>

            </form>

            </div>
        </Fragment>
    );
};

export default AddPromo;

import { 
    NEW_RECIPE_REQUEST,
    NEW_RECIPE_SUCCESS,
    NEW_RECIPE_RESET,
    NEW_RECIPE_FAIL,
    UPDATE_RECIPE_REQUEST,
    UPDATE_RECIPE_SUCCESS,
    UPDATE_RECIPE_FAIL,
    UPDATE_RECIPE_RESET,
    DELETE_RECIPE_REQUEST,
    DELETE_RECIPE_SUCCESS,
    DELETE_RECIPE_FAIL,
    DELETE_RECIPE_RESET,
    GET_RECIPES_REQUEST,
    GET_RECIPES_SUCCESS,
    GET_RECIPES_FAIL,
    RECIPE_DETAILS_REQUEST,
    RECIPE_DETAILS_SUCCESS,
    RECIPE_DETAILS_FAIL,
    RECIPE_DETAILS_RESET,
    CLEAR_ERRORS
} from '../constants/recipeConstants'

export const getRecipesReducer = (state = { recipes:[] }, action) => {
switch(action.type) {
    case GET_RECIPES_REQUEST:
        return {
            loading: true,
            recipes: []
        }

    case GET_RECIPES_SUCCESS:
        return {
            loading: false,
            recipes: action.payload
        }

    case GET_RECIPES_FAIL:
        return {
            loading: false,
            error: action.payload
        }
    case CLEAR_ERRORS:
        return {
            ...state,
            error: null
        }
    default:
        return state;
}
}

export const modifyRecipeReducer = (state = { recipe: {} }, action) => {
switch(action.type) {
    case NEW_RECIPE_REQUEST:
    case UPDATE_RECIPE_REQUEST:
    case DELETE_RECIPE_REQUEST:
        return {
            ...state,
            loading: true,
        }
    case NEW_RECIPE_SUCCESS:
    case UPDATE_RECIPE_SUCCESS:
    case DELETE_RECIPE_SUCCESS:
        return {
            loading: false,
            success: action.payload.success,
            recipe: action.payload.recipe

        }

    case NEW_RECIPE_FAIL:
    case UPDATE_RECIPE_FAIL:
    case DELETE_RECIPE_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload
        }

    case NEW_RECIPE_RESET:
    case UPDATE_RECIPE_RESET:
    case DELETE_RECIPE_RESET:
        return {
            ...state,
            success: false,
        }
    case CLEAR_ERRORS:
        return {
            ...state,
            error: null
        }
    default:
        return state;
}
}

export const recipeDetailsReducer = (state = {recipe: {}}, action) => {
    switch (action.type) {
        case RECIPE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false
            }
        case RECIPE_DETAILS_SUCCESS:
            return {
                loading: false,
                recipe: action.payload,
                success: true
            }
        case RECIPE_DETAILS_FAIL:
            return {
                loading: false,
                success: false,
                error: action.payload
            }
        case RECIPE_DETAILS_RESET:
            return {
                loading: false,
                success: false,
                recipe: {}
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
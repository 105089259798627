import React, { Fragment } from 'react'

import { useEffect } from 'react';

import { initGA, logPageView } from '../../GoogleAnalytics';


const recipient = 'omoyecooks@gmail.com';
const subject = 'Work with Omoye';
const body = 'Hi Omoye,\nI would love to work with you!';

const sendEmail = () => {
    const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoUrl);
  };

const AboutOmoye = () => {

    useEffect(() => {
        initGA();
        logPageView();
    }, [])

    return (
    <Fragment>
        <div className="container-fluid container-md">
            <div className="about">


                <div className="text-center">
                    <h2 className="text-lg bold-1">ABOUT OMOYE</h2>

                    <hr className="mx-auto w-25" />

                    <p className="text-xs my-4 spaced">Omoye was born in Lagos, Nigeria. 
                        Being raised in a culturally diverse state had a profound effect on her passion for cooking. 
                        One of the most beautiful memories of her childhood was the community of women who would gather together to cook for special occasions. 
                        This experience instilled in Omoye a love for culinary arts and a desire to share it with others.
                    </p>                    

                    <p className="text-xs spaced">Her personal mantra, <span className="bold-2">"cooking is fun"</span>, is expressed in the recipes she's shared with her growing online and offline community. 
                        As a result, she has earned the nickname <span className="bold-2">"Creative Recipes Queen"</span> among her tribe, the OmoyeCooks online family. 
                        Omoye's mission is to prove that cooking is anything but dull and mundane.
                    </p>

                    <p className="text-xs spaced">    
                        Her ultimate goal is to bring the flavors of Nigerian and African cuisine to the rest of the world. 
                        And with this passion, she invites everyone to join her on this journey. 
                        Together, they can embrace the joy that cooking brings to all of us. 
                        So, let's unite and share the love of food that we all possess. 
                    </p>

                    <p><i className='text-xs bold-2'>
                        …And guys! This is what we have ❤
                        </i>
                    </p>


                
                    <div className="about-wrapper mt-5">
                        <div className="about-img" style={{backgroundImage: "url('/images/omoye_2.jpg')", backgroundPosition: "top", backgroundSize: "cover"}}></div>
                        <div className="about-img mx-2" style={{backgroundImage: "url('/images/omoye.jpg')", backgroundPosition: "top", backgroundSize: "cover"}}></div>
                        <div className="about-img" style={{backgroundImage: "url('/images/about.png')", backgroundPosition: "top", backgroundSize: "cover"}}></div>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <button onClick={sendEmail} className="carousel-btn py-3 mt-5 border rounded text-xxs bold-2 white">Work with Omoye</button>
                </div>

                <div className="text-center margin-top-70">
                    <h2 className="text-lg bold-1">OMOYE HAS WORKED WITH YOUR FAVORITE BRANDS!</h2>

                    <hr className="mx-auto w-25" />

                    <p className="text-xs my-4 spaced"> Throughout her career, Omoye has collaborated and formed partnerships with some of the world's most renowned and distinguished brands. 
                        These companies have entrusted her with their prestigious products and services, allowing her to showcase her expertise and knowledge in the kitchen.
                    </p>                    

                    <p className="text-xs spaced">Omoye has worked with Cadbury Hot Chocolate, a beloved household name known for its rich and creamy taste that has been enjoyed by millions of people worldwide.
                    </p>

                    <p className="text-xs spaced">    
                    Another brand that Omoye has worked with is Grand Pure Soya Oil, a leading producer of high-quality cooking oil that is used in kitchens all over the world. 
                    </p>
                    
                
                    <div className="about-wrapper mt-5">
                        <div className="about-img" style={{backgroundImage: "url('/images/cadbury.jpg')", backgroundPosition: "top", backgroundSize: "cover"}}></div>
                        
                        <div className="about-img bg-white ms-2 d-flex justify-content-center align-items-center">
                            <div className="w-50 h-50" style={{backgroundImage: "url('/images/grand.png')", backgroundPosition: "top", backgroundSize: "cover"}}></div>
                        </div>

                        <div className="about-img ms-2" style={{backgroundImage: "url('/images/devon.jpg')", backgroundPosition: "top", backgroundSize: "cover"}}></div>

                        <div className="about-img ms-2" style={{backgroundImage: "url('/images/buchy.png')", backgroundPosition: "top", backgroundSize: "cover"}}></div>
                    
                    </div>
                </div>

                {/* NOMINATION */}
                <div className="text-center margin-top-70">
                    <h2 className="text-lg bold-1">OMOYE IS AN AWARD WINNER!</h2>

                    <hr className="mx-auto w-25" />

                    <p className="text-xs my-4 spaced bold-2"> Omoye was nominated as the Best Nigerian Food Blogger of the year 2022.
                    </p>                    

                    <div className="d-flex justify-content-center mt-5">
                        <div className="about-img-wide" style={{backgroundImage: "url('/images/kitchen.png')", backgroundPosition: "top", backgroundSize: "cover"}}></div>
                    </div>
                </div>

            </div>


        </div>
                                
    </Fragment>
    )
}

export default AboutOmoye;
import React, { Fragment, useEffect, useState, useRef } from 'react'
import Metadata from '../layout/metadata'

import AdminNavigation from '../layout/adminNavigation'
import DeleteModal from '../layout/deleteModal'
import { formatDate, numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'
import { getAdminOrderDetails, updateOrderStatus, deleteOrderData, clearErrors } from '../../actions/orderActions'
import { DELETE_ORDER_RESET } from '../../constants/orderConstants'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const AdminOrderDetails = () => {
    let isDelete = useRef(false);

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const { user } = useSelector(state => state.auth);
    const { order, error, loading } = useSelector(state => state.orderDetails)
    const {loading: modifyLoading, error: modifyError, success} = useSelector(state => state.modifyOrder)


    let [orderStatus, setOrderStatus] = useState(order.orderStatus);

    useEffect(() => {
        dispatch(getAdminOrderDetails(params.id))

        if (success) {
            dispatch({type: DELETE_ORDER_RESET});
            toast.success("Completed successfully! 👍");
            if (isDelete.current) navigate("/admin/orders");
        }

        if (error) {
            toast.error("Error fetching order details, please check your internet connection.")
            dispatch(clearErrors())
        }

        if (modifyError) {
            toast.error("An error occurred, please check your internet connection.")
            dispatch(clearErrors())
        }
    }, [dispatch, error, params.id])

    const getClickedButton = (clickedButton) => {
        if (clickedButton === "Delete") {
            dispatch(deleteOrderData(params.id));
            isDelete.current = true;
        }
    }

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateOrderStatus(orderStatus, params.id));
        window.location.reload(true);
    }

    const rowData = []
    return (
        <Fragment>
            <Metadata title={'Order Details'}/>
            <AdminNavigation Orders/>
            <ToastContainer/>
            <Fragment>
                {/* USERS SHOW PAGE */}

                <div className="container-md my-4">
                    <Link to="/admin/orders" className="text-1">{"< Back to Orders"}</Link>
                </div>
                <div className="container-md mb-5 pt-2">
                    {loading ? 
                        <div className="my-4">
                            <Skeleton count={15} height={20} baseColor="#dc720082"/>
                        </div> : (
                        <Fragment>
                        <h3 className="text-2">ORDER <span className="text-muted">#{order._id}</span></h3>

                        <hr className="mb-3"/>

                        {order.user ? (
                        <div className="row">
                            <div className="col-md-6">
                                <p className="text-2">Created: <strong>{order.createdAt ? formatDate(order.createdAt) : ""}</strong></p>
                                <p className="text-2">Order By: <strong>{order.user.firstName} {order.user.lastName}</strong></p>
                                <p className="text-2">Status: <strong>{order.orderStatus}</strong></p>
                                <p className="text-2">Items Price: <strong> &#x20A6;{numberWithCommas(order.itemsPrice)}</strong></p>
                                <p className="text-2">Shipping: <strong> &#x20A6;{numberWithCommas(order.shippingPrice)}</strong></p>
                                <p className="text-2">Tax: <strong> &#x20A6;{numberWithCommas(order.taxPrice)}</strong></p>
                                <p className="text-2">Total: <strong className="green-color"> &#x20A6;{numberWithCommas(order.totalPrice)}</strong></p>
                                <p className="text-2">Payment Status: <strong>{order.paidAt ? "Paid" : "Not Paid"}</strong></p>
                                <p className="text-2">Payment Method: {order.loan ? <Link to={`/admin/loans/${order.loan._id}`} className="bold-2 green-color">{order.paymentMethod} - {order.loan.status}</Link> : <strong>{order.paymentMethod}</strong>}</p>
                                <i className="d-block text-1 bold-2">{order.change && order.orderStatus !== "Delivered" && order.orderStatus !== "Cancelled"? `(Customer requires ${"\u20A6" + numberWithCommas(order.change)} change)`: ""}</i>
                                {order.orderStatus === "Delivered" ? <p className="text-2"><span className="green-color">Delivered: </span><strong>{order.deliveredAt ? formatDate(order.deliveredAt) : ""}</strong></p> : <Fragment></Fragment>}
                                {order.orderStatus === "Cancelled" ? <p className="text-2"><span className="red-color">Cancelled: </span><strong>{order.cancelledAt ? formatDate(order.cancelledAt) : ""}</strong></p> : <Fragment></Fragment>}
                            </div>

                            <div className="col-md-6">
                                <h5 className="text-2 orange bold-3 mt-4">Update order status</h5>
                                <form onSubmit={submitHandler}>
                                    <select defaultValue={order.orderStatus} className="form-control mt-2 w-75" name="orderStatus" id="orderStatus" onChange={(e) => setOrderStatus(e.target.value)}
                                    disabled={order.orderStatus === "Delivered" || order.orderStatus === "Cancelled" ? true : false}>
                                        <option value="Processing">Processing</option>
                                        <option className="my-2 py-2" value="Shipped">Shipped</option>
                                        <option className="my-2 py-2" value="Delivered">Delivered</option>
                                        <option className="red" value="Cancelled">Cancel Order</option>
                                    </select>
                                    {modifyLoading ? (
                                        <i className="d-block text-muted text-1 bold-2">Updating order status...</i>
                                    ):(<Fragment></Fragment>)}
                                    
                                    <button type="submit" className="orange-btn text-1 mt-3 py-2 px-3" disabled={  order.orderStatus === "Delivered" || order.orderStatus === "Cancelled" || modifyLoading }>Update Status</button>
                                </form>
                            </div>
                        </div>
                        ):(<Fragment></Fragment>)}
                        
                        <hr className="my-4" />

                        <div className="d-flex justify-content-between align-items-center my-4">
                            <h3 className="text-2 orange bold-3 m-0">Products in Order</h3>
                            {order.orderItems ? (
                                <span className="text-1 text-muted">({order.orderItems.length} Products)</span>
                            ):(<Fragment></Fragment>)}
                        </div>
                
                        <table className="table">
                            <thead className="table-secondary py-5">
                                <tr>
                                    <th scope="col"><i className="fa-solid fa-image"></i></th>
                                    <th scope="col">Product name</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            {order.orderItems ? (
                                order.orderItems.forEach(item => rowData.push(
                                    <tr className="bg-white" key={item._id}>
                                        <td><div style={{backgroundImage: `url('${item.image}')`, backgroundSize: 'cover'}} className="table-image"></div></td>
                                        <td className="text-2">{item.name}</td>
                                        <td className="text-2">&#x20A6;{numberWithCommas(item.price)}</td>
                                        <td className="text-2">{numberWithCommas(item.quantity)}</td>
                                        <td className="text-2">&#x20A6;{numberWithCommas((item.quantity * item.price).toFixed(2))}</td>
                                    </tr>                                 
                                ))
                            ):(<Fragment></Fragment>)}

                            <tbody>{rowData}</tbody>
                        </table>

                        {order.shippingInfo ? (
                            <Fragment>
                                <h3 className="text-2 bold-3 orange mt-5 mb-4">Shipping Infromation</h3>
                                <p className="text-2">Address: <strong>{order.shippingInfo.street}</strong></p>
                                <p className="text-2">City: <strong>{order.shippingInfo.city}</strong></p>
                                <p className="text-2">State: <strong>{order.shippingInfo.state}</strong></p>
                                <p className="text-2">Country: <strong>{order.shippingInfo.country}</strong></p>
                                <p className="text-2">Phone Number: <strong>{order.shippingInfo.phoneNumber}</strong></p>
                            </Fragment>
                        ):(<Fragment></Fragment>)}


                        {user.role === "Admin+" ?
                        <Fragment>
                            <button className="red-btn bold-2 text-2 mt-5" data-bs-toggle="modal" data-bs-target="#delete-modal" disabled={modifyLoading}>Delete order record</button>
                            <DeleteModal title="Order Details" item="order record" onSelect={getClickedButton}/>
                        </Fragment>

                        :<Fragment></Fragment>}
                    </Fragment>)}
                </div>
            </Fragment>
        </Fragment>
    )
}

export default AdminOrderDetails
import React from 'react';
import { Link } from 'react-router-dom'
import { numberWithCommas } from '../../Functions/functions';

const ProductCard = ({ product, activePromotions, className }) => {
  const { _id, name, category, price } = product;

  // Function to calculate the discounted price based on active promotions
  const calculateDiscountedPrice = () => {

    const applicablePromotions = activePromotions.filter(promo => {
      const targetValues = promo.targetValues.map(item => item.value); // Extract values from the array of objects
    
      return (
        (promo.targetType === 'All products' || targetValues.includes(category)) || targetValues.includes(_id) && promo.promoType === 'Percentage'
      );
    });

    if (applicablePromotions.length > 0) {
      const maxDiscount = Math.max(...applicablePromotions.map(promo => promo.discountValue));
      const discountPercentage = maxDiscount / 100;
      return price - (price * discountPercentage);
    }

    return price;
  };

  const discountedPrice = calculateDiscountedPrice();

  return (
    <Link to={`/shop/${category.toLowerCase()}/${_id}`} className={`${className} shadow-sm`} state={{ discountedPrice: discountedPrice }}>
        <div className="course-vid bg-white" style={{backgroundImage: `url('${product.images[0].url}')`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
        <div className="text-start px-2 pb-4">
            <p className="p-ellipsis text-2 mt-3 mb-0">{name}</p>
            {discountedPrice !== price ? 
                <p className="text-3 bold-2 my-2">&#x20A6;{numberWithCommas(discountedPrice)} <span className="bold-1 text-muted text-1 text-decoration-line-through">&#x20A6;{numberWithCommas(price)}</span></p>
            :   <p className="text-3 bold-2 my-2">&#x20A6;{numberWithCommas(price)}</p>
            }
            <p className={`text-2 bold-2 mb-0 ${product.stock === 0 ? "red" : "green"}`}>{product.stock === 0 ? "Out of stock" : "In stock"}</p>
            {/* <p className="bold-2 text-1 mb-0 orange"><i className="fa-solid fa-plus"></i> Add to Cart</p> */}
        </div>
    </Link>
  );
};

export default ProductCard;

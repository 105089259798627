import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

// import { Carousel } from 'bootstrap'

const CarouselComponent = ({images}) => {
    // useEffect(() => {
    //     const carouselElement = document.querySelector('.carousel');
    //     const carousel = new Carousel(carouselElement);

    //     return () => {
    //         carousel.dispose();
    //     }
    // })

  return (
    <Fragment>
        <div id="carouselAutoplaying" className="carousel slide mt-5" data-bs-ride="carousel" data-bs-touch="true" data-bs-interval="3000">
            <div className="carousel-inner">
                {/* <div className="carousel-item active">
                    <div className="carousel-image" style={{backgroundImage: `url('/images/${images[0]}')`, backgroundPosition: "top", backgroundSize: "cover"}}></div>
                </div> */}

                <div className="carousel-item active">
                    <div className="carousel-image" style={{backgroundImage: `url('/images/${images[0]}')`, backgroundPosition: "top", backgroundSize: "cover"}}></div>
                    <div className="carousel-text d-block">
                        <h5 className="carousel-header bold-2">Learn to cook with Omoye</h5>
                        <p className="text-sm py-2 mb-4">Full courses to make you master the art of cooking..</p>
                        <Link to="/academy" className="text-xs carousel-btn">Enter the academy</Link>
                    </div>
                    {/* <img src="/images/carousel-1.jpg" className="carousel-image d-block w-100" alt="Image 1"/> */}
                </div>

                <div className="carousel-item">
                    <div className="carousel-image" style={{backgroundImage: `url('/images/${images[1]}')`, backgroundPosition: "top", backgroundSize: "cover"}}></div>
                    <div className="carousel-text d-block">
                        <h5 className="carousel-header bold-2">New recipes beyond your imagination</h5>
                        <p className="text-sm py-2 mb-4">Experience different food combinations your tongue will thank you for.</p>
                        <Link to="/recipes" className="text-xs carousel-btn">Check out Recipes</Link>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="carousel-image" style={{backgroundImage: `url('/images/${images[2]}')`, backgroundPosition: "top", backgroundSize: "cover"}}></div>
                    <div className="carousel-text d-block">
                        <h5 className="carousel-header bold-2">Meet the world's best tutors</h5>
                        <p className="text-sm py-2 mb-4">Because we want you to be the best.</p>
                        <Link to="/masterclass" className="text-xs carousel-btn">Coming soon!</Link>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="carousel-image" style={{backgroundImage: `url('/images/${images[3]}')`, backgroundPosition: "top", backgroundSize: "cover"}}></div>
                    <div className="carousel-text d-block">
                        <h5 className="carousel-header bold-2 mb-5">Shop for our best selling products.</h5>
                        <Link to="/shop" className="text-xs carousel-btn">Shop now!</Link>
                    </div>
                </div>

                
            </div>
            <button className="carousel-control-prev d-md-block" type="button" data-bs-target="#carouselAutoplaying" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next d-md-block" type="button" data-bs-target="#carouselAutoplaying" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
                                
    </Fragment>
  )
}

export default CarouselComponent
import { 
    CREATE_ORDER_REQUEST,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAIL,
    CREATE_ORDER_RESET,
    GET_ORDERS_REQUEST,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_FAIL,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,
    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAIL,
    UPDATE_ORDER_RESET,
    DELETE_ORDER_REQUEST,
    DELETE_ORDER_SUCCESS,
    DELETE_ORDER_FAIL,
    DELETE_ORDER_RESET,
    CLEAR_ERRORS
} from '../constants/orderConstants'

export const modifyOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_ORDER_REQUEST:
        case UPDATE_ORDER_REQUEST:
        case DELETE_ORDER_REQUEST:
            return{
                ...state,
                loading: true,
                success: false
            }
        case CREATE_ORDER_SUCCESS:
        case UPDATE_ORDER_SUCCESS:
        case DELETE_ORDER_SUCCESS:
            return{
                loading: false,
                order: action.payload,
                success: true
            }
        case CREATE_ORDER_FAIL:
        case UPDATE_ORDER_FAIL:
        case DELETE_ORDER_FAIL:
            return{
                loading: false,
                error: action.payload,
                success: false
            }
        case CREATE_ORDER_RESET:
        case UPDATE_ORDER_RESET:
        case DELETE_ORDER_RESET:
            return {
                ...state,
                success: false,
                order: {}
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const getOrdersReducer = (state = {orders: [], loading: true}, action) => {
    switch (action.type) {
        case GET_ORDERS_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_ORDERS_SUCCESS:
            return{
                loading: false,
                orders: action.payload
            }
        case GET_ORDERS_FAIL:
            return{
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const orderDetailsReducer = (state = {order: {}}, action) => {
    switch (action.type) {
        case ORDER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ORDER_DETAILS_SUCCESS:
            return {
                loading: false,
                order: action.payload
            }
        case ORDER_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
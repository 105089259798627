import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useLocation } from 'react-router-dom';

import { getNotifications, clearErrors } from '../../actions/notificationActions'
import { signout } from '../../actions/userActions'

import ReactGA from "react-ga4";

const Header = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { isAuthenticated, user } = useSelector(state => state.auth);

    const { error, notifications } = useSelector(state => state.getNotifications)

    let newNotifications = 0;

    if (notifications && notifications.length > 0) {
        newNotifications = (notifications[0].messages.filter(message => message.new === true)).length
    }

    useEffect(() => {
        if (isAuthenticated) {
            // dispatch(getNotifications());
        }

        if (error) {
            toast.error("An error has occurred, check your internet connection and refresh the page.")
            dispatch(clearErrors())
        }
    }, [dispatch, isAuthenticated, error])

    const signoutHandler = () => {
        dispatch(signout());
        toast.success('Signed out successfully 👍.')
    }

  return (
    <Fragment>
        <ToastContainer />
        <nav className="navbar navbar-expand-lg bg-body-tertiary bg-black py-2 fixed-top">
            <div className="container-fluid container-md">
                <Link to="/" className="navbar-brand bold-2">OmoyeCooks</Link>

                <button className="navbar-toggler p-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon d-block mt-1 bg-light"></span>
                    <span className="navbar-toggler-icon d-block my-1 bg-light"></span>
                    <span className="navbar-toggler-icon d-block mb-1 bg-light"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link to="/academy" className="nav-link mt-2 mt-lg-0 active" aria-current="page">Academy</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/recipes" className="nav-link mt-2 mt-lg-0">Recipes</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/masterclass" state={{from: "Masterclass"}} className="nav-link mt-2 mt-lg-0">Masterclasses</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/shop" className="nav-link mt-2 mt-lg-0">Shop</Link>
                        </li>
                        {/* <li className="nav-item  orange-hover dropdown">
                            <Link to="#" className="nav-link mt-2 mt-lg-0 dropdown-toggle no-drop" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Shop
                            </Link>
                            <ul className="dropdown-menu">
                                <li onClick={onSpiceBrandsClick}><a href="https://selar.co/vu5y" target="_blank" className="dropdown-item px-1">Spice Brands</a></li>
                                <li onClick={onCookBookClick}><Link to="/coming-soon" state={{from: "Cook Book"}} className="dropdown-item px-1">Cook Book</Link></li>
                                <li onClick={onCustomizedTClick}><Link to="/coming-soon" state={{from: "Customized T-shirts"}} className="dropdown-item px-1">Customized T-shirts</Link></li>
                            </ul>
                        </li> */}
                        <li className="nav-item">
                            <Link to="/about-omoye" className="nav-link mt-2 mt-lg-0">Meet Omoye</Link>
                        </li>
                        {/* PROFILE */}
                        {user && user.firstName ? (
                        <Fragment>
                            {/* PROFILE */}
                            <li className="nav-item dropdown my-2 my-lg-0 ms-lg-3">
                                
                                {/* BIG SCREEN  */}
                                <Link to="#" className="d-none d-lg-flex nav-link user-icon" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className='bold-1 text-md white'>{user.firstName.charAt(0)}{user.lastName.charAt(0)}</span>
                                </Link>

                                {/* SMALL SCREEN */}
                                <Link to="#" className="d-lg-none nav-link user-icon-sm px-3 mb-4 no-drop" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="bold-1 no-drop white">{user.firstName} {user.lastName}</span>
                                </Link>

                                <ul className="dropdown-menu">
                                    <i className="text-muted text-xs d-block text-center m-0 p-0">{user.firstName} {user.lastName}</i>
                                    <hr className="m-2" />
                                    {/* CHECK ADMIN */}
                                    
                                    {user.role === "Admin" ? 
                                        <Fragment>
                                            <li><Link to="/admin/dashboard" className="dropdown-item mt-1 px-1">Admin Dashboard</Link></li>
                                            <li><Link to="/" className="dropdown-item px-1">User View</Link></li>
                                        </Fragment> : <Fragment></Fragment>
                                         }
                                    
                                    <li><Link to={`/profile`} state={{ from: "undefined" }} className="dropdown-item px-1">My Account</Link></li>

                                    <li><Link to="/" className="dropdown-item mt-3 mt-md-0 mb-1 px-1" onClick={signoutHandler}>Sign Out</Link></li>
                                </ul>
                            </li>
                        </Fragment>
                            
                        ) : (<Fragment>
                                <Link to={`/signin?redirect=${location.pathname.substring(1)}`} className="signin-btn mt-3 mt-lg-0 ms-0 ms-lg-3">Sign In</Link>
                            </Fragment>
                            )}

                            {/* END PROFILE */}
                    </ul>
                </div>
            </div>
        </nav>
                                
    </Fragment>
  )
}

export default Header
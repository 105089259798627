export const CREATE_PROMO_REQUEST = 'CREATE_PROMO_REQUEST'
export const CREATE_PROMO_SUCCESS = 'CREATE_PROMO_SUCCESS'
export const CREATE_PROMO_RESET = 'CREATE_PROMO_RESET'
export const CREATE_PROMO_FAIL = 'CREATE_PROMO_FAIL'

export const GET_PROMOS_REQUEST = 'GET_PROMOS_REQUEST'
export const GET_PROMOS_SUCCESS = 'GET_PROMOS_SUCCESS'
export const GET_PROMOS_FAIL = 'GET_PROMOS_FAIL'

export const UPDATE_PROMO_REQUEST = 'UPDATE_PROMO_REQUEST'
export const UPDATE_PROMO_SUCCESS = 'UPDATE_PROMO_SUCCESS'
export const UPDATE_PROMO_FAIL = 'UPDATE_PROMO_FAIL'
export const UPDATE_PROMO_RESET = 'UPDATE_PROMO_RESET'

export const DELETE_PROMO_REQUEST = 'DELETE_PROMO_REQUEST'
export const DELETE_PROMO_SUCCESS = 'DELETE_PROMO_SUCCESS'
export const DELETE_PROMO_FAIL = 'DELETE_PROMO_FAIL'
export const DELETE_PROMO_RESET = 'DELETE_PROMO_RESET'

export const PROMO_DETAILS_REQUEST = 'PROMO_DETAILS_REQUEST'
export const PROMO_DETAILS_SUCCESS = 'PROMO_DETAILS_SUCCESS'
export const PROMO_DETAILS_FAIL = 'PROMO_DETAILS_FAIL'
export const PROMO_DETAILS_RESET = 'PROMO_DETAILS_RESET'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import AdminNavigation from '../layout/adminNavigation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { getClasses, clearErrors } from '../../actions/classActions'

let clicked = "All";
let firstLoad = true;

const Classes = () => {

    const dispatch = useDispatch();

    const { loading, error, classes } = useSelector(state => state.getClasses);

    let [ filteredClasses, setFilteredClasses ] = useState(classes)

    if (firstLoad) { filteredClasses = classes };

    useEffect(() => {
        dispatch(getClasses("Admin"));

        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            console.log(error, "ERRORRR - masterclass.jsx")
            dispatch(clearErrors())
        }

    }, [dispatch, error])


    const onSearchChange = (e) => {
        if (e.target.value === "") {
            return setFilteredClasses(classes)
        }
        let matches = classes.filter(classes => classes.name.toLowerCase().includes(e.target.value.toLowerCase()))

        setFilteredClasses(matches);
        firstLoad = false;
    }

    const toggleTable = (classlevel) => {
        firstLoad = false
        let filter
        clicked = classlevel
        if (classlevel === "All") {
            filter = classes;
        } else if (classlevel === "Free") {
            filter = classes.filter(classes => classes.level === "Free")
        } else if (classlevel === "Bronze") {
            filter = classes.filter(classes => classes.level === "Bronze")
        } else if (classlevel === "Silver") {
            filter = classes.filter(classes => classes.level === "Silver")
        } else if (classlevel === "Gold"){
            filter = classes.filter(classes => classes.level === "Gold")
        } else {
            filter = []
        }

        setFilteredClasses(filter)
    }

    const rowData = [];

    return (
        <Fragment>
            <Metadata title={"Classes"}/>
            <AdminNavigation Classes/>
            <ToastContainer/>
            <div className="container-fluid container-md">
                {/* {loading ? <Loader/> : ( */}
                <Fragment>
                    {/* FREE CLASSES */}
                    <div className="d-flex align-items-center mt-3">
                        <h3 className="text-xs text-muted d-inline-block m-0 me-4 bold-2">CLASSES</h3>
                        <div className="d-flex ms-auto align-items-center">
                            <input
                            className="form-control d-inline-block py-1" 
                            type="search" 
                            placeholder="Search classes"
                            aria-label="Search"
                            onChange={onSearchChange}
                            />
                            <Link to="/admin/classes/add-class" className="text-xs bold-2 d-none d-sm-block add-btn w-75 ms-2">+ Add class</Link>
                            <Link to="/admin/classes/add-class" className="d-sm-none px-3 bold-3 add-btn ms-2">+</Link>
                        </div>
                    </div>

                    {/* FILTER */}
                    <div className="d-flex flex-column flex-md-row justify-content-between mt-3">
                        <span className="text-muted text-xs order-1 order-md-2">{filteredClasses.length} {clicked === 'All' ? 'classes' : clicked + " classes"}</span>
                        <div className="table-nav-block order-2 order-md-1 mt-3 mt-md-0">
                            <button onClick={function() { toggleTable('All') }} className={`table-nav ms-0 ${clicked === 'All'?'table-active':''}`}>All</button>
                            <button onClick={function() { toggleTable('Free') }} className={`table-nav ${clicked === 'Free'?'table-active':''}`}>Free</button>
                            <button onClick={function() { toggleTable('Bronze') }} className={`table-nav ${clicked === 'Bronze'?'table-active':''}`}>Bronze</button>
                            <button onClick={function() { toggleTable('Silver') }} className={`table-nav ${clicked === 'Silver'?'table-active':''}`}>Silver</button>
                            <button onClick={function() { toggleTable('Gold') }} className={`table-nav ${clicked === 'Gold'?'table-active':''}`}>Gold</button>
                        </div>
                    </div>
                    {/* FILTER END */}

                    {loading ?                         
                        <Fragment>
                            <div className="my-4">
                                <Skeleton count={15} height={20} baseColor="#dc720082"/>
                            </div>
                        </Fragment> :  (
                        <Fragment>
                            <table className="table mt-3 mb-5">
                                <thead className="table-secondary py-5">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Size</th>
                                        <th scope="col">Duration</th>
                                        <th scope="col">Uploaded</th>
                                        <th scope="col">Views</th>
                                        <th scope="col" className="text-center">Action</th>
                                    </tr>
                                </thead>

                                {filteredClasses.forEach(classes => rowData.push(
                                        <tr className="bg-white" key={classes._id}>
                                            <td className="text-xs">{classes.name}</td>
                                            <td className="text-xs">{classes.size}</td>
                                            <td className="text-xs">{classes.duration}</td>
                                            <td className="text-xs">{classes.uploadedAt.split('T')[0]}</td>
                                            <td className="text-xs"> {classes.views}</td>
                                            <td className="text-center text-xs"><Link to={`/admin/classes/${classes._id}`}className="orange bold-2">View</Link></td>
                                        </tr>                                 
                                    ))}
                                    <tbody>{rowData}</tbody>
                            </table>
                            { filteredClasses.length === 0 ? (
                            <div className="text-center mb-5 w-100">
                                <h3 className='text-sm'>NO DATA</h3>
                            </div>
                            ):(<Fragment></Fragment>)}
                        </Fragment>
                    )}
                
                </Fragment>                              
                {/* )} */}
            </div>
        </Fragment>
    )
}

export default Classes;
import React, { Fragment } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

const ComingSoon = () => {
    const location = useLocation();

    return (
    <Fragment>
        <div className="container-fluid container-md shadow-lg coming-soon p-0 mt-4">
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-center m-0  px-0 px-md-5">

                <div className="coming-soon-img mx-auto" style={{backgroundImage: `url(${"/images/pot.png"})`, backgroundPosition: "center", backgroundSize: "cover"}}></div>

                <div className="d-flex flex-column mt-4 mt-md-0 align-items-center">
                    <h1 className="bold-3 text-center orange">{location.state.from.toUpperCase()}</h1>
                    <h1 className="text-muted">is <u>coming soon</u></h1>
                </div>

            </div>


        </div>
                                
    </Fragment>
    )
}

export default ComingSoon;
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import AdminNavigation from '../layout/adminNavigation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { getRecipes, clearErrors } from '../../actions/recipeActions'

let clicked = "All";
let firstLoad = true;

const Recipes = () => {

    const dispatch = useDispatch();

    const { loading, error, recipes } = useSelector(state => state.getRecipes);

    let [ filteredRecipes, setFilteredRecipes ] = useState(recipes)

    if (firstLoad) { filteredRecipes = recipes };

    useEffect(() => {
        dispatch(getRecipes("Admin"));

        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            console.log(error, "ERRORRR - recipes.jsx")
            dispatch(clearErrors())
        }

    }, [dispatch, error])


    const onSearchChange = (e) => {
        if (e.target.value === "") {
            return setFilteredRecipes(recipes)
        }
        let matches = recipes.filter(recipe => recipe.name.toLowerCase().includes(e.target.value.toLowerCase()))

        setFilteredRecipes(matches);
        firstLoad = false;
    }

    const toggleTable = (category) => {
        firstLoad = false
        let filter
        clicked = category
        if (category === "All") {
            filter = recipes;
        } else if (category === "Simple breakfast") {
            filter = recipes.filter(recipe => recipe.category === "Simple breakfast")
        } else if (category === "Plantain recipes") {
            filter = recipes.filter(recipe => recipe.category === "Plantain recipes")
        } else if (category === "Nigerian soups") {
            filter = recipes.filter(recipe => recipe.category === "Nigerian soups")
        } else if (category === "Chicken recipes") {
            filter = recipes.filter(recipe => recipe.category === "Chicken recipes")
        } else if (category === "Pasta recipes"){
            filter = recipes.filter(recipe => recipe.category === "Pasta recipes")
        } else if (category === "OmoyeCooks special recipes") {
            filter = recipes.filter(recipe => recipe.category === "OmoyeCooks special recipes")
        } else {
            filter = []
        }

        setFilteredRecipes(filter)
    }

    const rowData = [];

    return (
        <Fragment>
            <Metadata title={"Recipes"}/>
            <AdminNavigation Recipes/>
            <ToastContainer/>
            <div className="container-fluid container-md">
                <Fragment>
                    {/* Plantain recipes recipes */}
                    <div className="d-flex align-items-center mt-3">
                        <h3 className="text-xs text-muted d-inline-block m-0 me-4 bold-2">RECIPES</h3>
                        <div className="d-flex ms-auto align-items-center">
                            <input
                            className="form-control d-inline-block py-1" 
                            type="search" 
                            placeholder="Search recipes"
                            aria-label="Search"
                            onChange={onSearchChange}
                            />
                             <Link to="/admin/recipes/add-recipe" className="text-xs bold-2 d-none d-sm-block add-btn w-75 ms-2">+ Add recipe</Link>
                            <Link to="/admin/recipes/add-recipe" className="d-sm-none px-3 bold-3 add-btn ms-2">+</Link>
                        </div>
                    </div>

                    {/* FILTER */}
                    <div className="d-flex flex-column flex-md-row justify-content-between mt-3">
                        <span className="d-inline-block ms-0 ms-md-4 w-fit text-muted text-xs order-1 order-md-2">{filteredRecipes.length} {clicked === 'All' ? 'recipes' : clicked + ""}</span>
                        <div className="table-nav-block order-2 order-md-1 mt-3 mt-md-0">
                            <button onClick={function() { toggleTable('All') }} className={`table-nav ms-0 ${clicked === 'All'?'table-active':''}`}>All</button>
                            <button onClick={function() { toggleTable('Plantain recipes') }} className={`table-nav ${clicked === 'Plantain recipes'?'table-active':''}`}>Plantain recipes</button>
                            <button onClick={function() { toggleTable('Nigerian soups') }} className={`table-nav ${clicked === 'Nigerian soups'?'table-active':''}`}>Nigerian soups</button>
                            <button onClick={function() { toggleTable('Chicken recipes') }} className={`table-nav ${clicked === 'Chicken recipes'?'table-active':''}`}>Chicken recipes</button>
                            <button onClick={function() { toggleTable('Simple breakfast') }} className={`table-nav ${clicked === 'Simple breakfast'?'table-active':''}`}>Simple breakfast</button>
                            <button onClick={function() { toggleTable('Pasta recipes') }} className={`table-nav ${clicked === 'Pasta recipes'?'table-active':''}`}>Pasta recipes</button>
                            <button onClick={function() { toggleTable('OmoyeCooks special recipes') }} className={`table-nav ${clicked === 'OmoyeCooks special recipes'?'table-active':''}`}>OmoyeCooks special recipes</button>
                        </div>
                    </div>
                    {/* FILTER END */}

                    {loading ? 
                        <Fragment>
                            <div className="my-4">
                                <Skeleton count={15} height={20} baseColor="#dc720082"/>
                            </div>
                        </Fragment> : (
                        <Fragment>
                            <table className="table mt-3 mb-5">
                                <thead className="table-secondary py-5">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Uploaded</th>
                                        {/* <th scope="col">Views</th> */}
                                        <th scope="col" className="text-center">Action</th>
                                    </tr>
                                </thead>

                                {filteredRecipes.forEach(recipes => rowData.push(
                                        <tr className="bg-white" key={recipes._id}>
                                            <td className="text-xs">{recipes.name}</td>
                                            <td className="text-xs">{recipes.category}</td>
                                            <td className="text-xs">{recipes.createdAt.split('T')[0]}</td>
                                            {/* <td className="text-xs"> {recipes.views}</td> */}
                                            <td className="text-center text-xs"><Link to={`/admin/recipes/${recipes.name}`}className="orange bold-2">View</Link></td>
                                        </tr>                                 
                                    ))}
                                    <tbody>{rowData}</tbody>
                            </table>
                            { filteredRecipes.length === 0 ? (
                            <div className="text-center mb-5 w-100">
                                <h3 className='text-sm'>NO DATA</h3>
                            </div>
                            ):(<Fragment></Fragment>)}
                        </Fragment>
                    )}
                
                </Fragment>                              
            </div>
        </Fragment>
    )
}

export default Recipes;
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import AdminNavigation from '../layout/adminNavigation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getCourseDetails, modifyCourse, clearErrors } from '../../actions/courseActions'
import { UPDATE_COURSE_RESET } from '../../constants/courseConstants'

import { numberWithCommas, arrayRemove } from '../../Functions/functions';
import EndSessionModal from './endSessionModal'
import CourseAccessListModal from '../academy/courseAccessListModal'
import CourseAccessModal from '../academy/courseAccessModal'

import { grantAccess, getAccessList, clearErrors as clearAccessErrors } from '../../actions/accessActions'

import { GRANT_ACCESS_RESET } from '../../constants/accessConstants';

// LOCK/UNLOCK VIDEOS
let vidsToLock = []
let vidsToUnlock = []
let toggle = 1;

let courseData = {};

let state = "Idle"
let unlockedVids = [];
let lockedVids = [];
let sortedGrouped = [];
let firstLoad = true;

const CourseDetails = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const { loading, error, course } = useSelector(state => state.courseDetails);
    let { loading: updateLoading, success } = useSelector(state => state.newCourse)

    const { error: accessError, loading: accessLoading, success: accessSuccess } = useSelector(state => state.grantAccess);
    const { accessList } = useSelector(state => state.getAccessList);

    let [isChecked, setIsChecked] = useState();

    const [refresh, setRefresh] = useState("")


    if (firstLoad && course.name) {
        isChecked = course.hidden

        lockedVids = course.courseVideos.filter(video => !video.unlocked)
        unlockedVids = course.courseVideos.filter(video => video.unlocked).sort((a, b) => a.unlockOrder - b.unlockOrder)

        sortedGrouped = unlockedVids.reduce((acc, obj) => {
        const date = obj.dateUnlocked.split("T")[0];
        const index = acc.findIndex(item => Object.keys(item)[0] === date);
        if (index === -1) {
            acc.push({ [date]: [obj] });
        } else {
            acc[index][date].push(obj);
        }
        return acc;
        }, [])
        .sort((a, b) => new Date(Object.keys(b)[0]) - new Date(Object.keys(a)[0]));

        // This code first uses the reduce() method to group the data by date and maintain the original structure as an array of objects with a single key-value pair representing a date and its corresponding array of objects.
        // It then chains the sort() method to sort the resulting array of objects by date in descending order (i.e., latest to earliest).
        // The sort() method uses a custom comparator function that extracts the date from each object by converting the first key of the object to a Date object.
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        dispatch(getCourseDetails(params.id, "Admin"));
        dispatch(getAccessList(params.id));

        setIsChecked(course.hidden)
        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }

        if (accessError) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearAccessErrors())
        }

        if (accessSuccess) {
            dispatch({ type: GRANT_ACCESS_RESET })
            toast.success("Successfully granted access!")
        }

        if (success) {
            firstLoad = true;
            vidsToLock = []
            vidsToUnlock = []
            toggle = 1;
            state = "Done"
            dispatch({ type: UPDATE_COURSE_RESET })
            toast.success("Operation successful")
        }

    }, [dispatch, accessSuccess, success, error, params.id])

    const checkboxChange = (e) => {

        const isChecked = e.target.checked
        const isUnlocked = e.target.alt
        
        if (isChecked) {
            if (isUnlocked === "false") {
                vidsToUnlock.push(parseInt(e.target.lang))
            } else if (isUnlocked === "true") {
                vidsToLock.push(parseInt(e.target.lang))
            }
        } else {
            if (isUnlocked === "false") {
                vidsToUnlock = arrayRemove(vidsToUnlock, parseInt(e.target.lang))
            } else if (isUnlocked === "true") {
                vidsToLock = arrayRemove(vidsToLock, parseInt(e.target.lang))
            }
        }

        courseData = {
            vidsToLock: vidsToLock,
            vidsToUnlock: vidsToUnlock
        }
        setRefresh(toggle % 2 === 0)
        toggle += 1

    }

    const toggleUnlock = (e) => {
        setRefresh("")
        dispatch(modifyCourse( params.id, courseData ));
    }

    const onHiddenToggle = (e) => {
      firstLoad = false;
      setIsChecked(e.target.checked)
      courseData = {
          isAddVideo: false,
          hiddenToggle: true,
          checked: e.target.checked
      }

      dispatch(modifyCourse( params.id, courseData ));

    }

    const onGrantAccess = (users) => {
        dispatch(grantAccess(params.id, users));
    }

    const rowData = [];

    return (
        <Fragment>
            <Metadata title={"Course Details"}/>
            <AdminNavigation Academy />
            <ToastContainer/>
            <div className="container-fluid container-md">

                <Fragment>
                    <div className="py-3 mb-3">
                        <Link to="/admin/academy" className="text-dark text-xs">{"< Back to Courses"}</Link>
                    </div>

                    {loading?                         
                        <Fragment>
                            <div className="my-4">
                                <Skeleton count={15} height={40} baseColor="#dc720082"/>
                            </div>
                        </Fragment> :
                
                        <div className="container-fluid container-md p-0 mb-4">
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <h3 className="text-xs bold-2 orange m-0">KEY - {course.key}</h3>
                                <div>
                                    <span className="me-2 text-xxs bold-2">Hide Course:</span>
                                    <label className="switch">
                                        <input onChange={onHiddenToggle} type="checkbox" checked={isChecked}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                            </div>
                            <div className="mb-4">
                                <h3 className="text-xs text-xs bold-2 orange">{course.name}</h3>
                                <p className="text-xs spaced mb-0">{course.caption}</p>
                            </div>

                            {/* DESCRIPTION */}
                            <div className="">
                                <h3 className="text-xs bold-2 orange">Course Description</h3>
                                <p className="spaced text-xs m-0">{course.description}</p>
                            </div>

                            <div className="row my-4 my-md-5">
                                {/* PRICE */}
                                <div className="col-md-4 mb-4 mb-lg-0">
                                    <h3 className="text-xs bold-2 orange">Course Price:</h3>
                                    <p className="text-xs m-0 bold-3">&#x20A6;{numberWithCommas(course.price)}</p>
                                </div>
                                {/* DURATION */}
                                <div className="col-md-4 mb-4 mb-lg-0">
                                    <h3 className="text-xs bold-2 orange">Course Duration:</h3>
                                    <p className="text-xs m-0">{course.duration}</p>
                                </div>
                                {/* VIDEO HOURS */}
                                <div className="col-md-4">
                                    <h3 className="text-xs bold-2 orange">Total Videos Duration:</h3>
                                    <p className="text-xs m-0">{Math.ceil(course.totalVideoDuration/60)} mins</p>
                                </div>
                            </div>

                            {/* <hr/> */}

                            <div className="row mb-4">
                                {/* INCLUDES */}
                                <div className="col-md-6 mb-4 mb-lg-0">
                                    <h3 className="text-xs bold-2 orange">This course Includes:</h3>
                                    { course.name && course.includes.split('•').slice(1).map(incItem => (
                                        <p key={incItem} className="spaced text-xs my-2 p-0">• {incItem}</p>
                                    ))}
                                </div>
                                {/* REQUIREMENTS */}
                                <div className="col-md-6">
                                    <h3 className="text-xs bold-2 orange">Course requirements:</h3>
                                    { course.name && course.requirements.split('•').slice(1).map(reqItem => (
                                        <p key={reqItem} className="text-xs my-2 p-0">• {reqItem}</p>
                                    ))}
                                </div>
                            </div>

                            <div className="row my-4 my-md-5">
                                {/* START */}
                                <div className="col-md-6 mb-4 mb-lg-0">
                                    <h3 className="text-xs bold-2 orange">Start Date:</h3>
                                    <p className="spaced text-xs m-0">{course.startDate && course.startDate.split("T")[0]}</p>
                                </div>
                                {/* END */}
                                <div className="col-md-6">
                                    <h3 className="text-xs bold-2 orange">End Date:</h3>
                                    <p className="spaced text-xs m-0">{course.endDate && course.endDate.split("T")[0]}</p>
                                </div>
                            </div>

                            <Fragment>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h3 className="d-inline-block text-xs bold-2 orange my-0">Enrollees: <span className="text-dark">({course.studentsEnrolled && course.studentsEnrolled.length})</span></h3>
                                    
                                    <div className="d-flex align-items-center">
                                        <span data-bs-toggle="modal" data-bs-target="#accessListModal" className="click text-xs bold-2 orange me-3">Access list</span>
                                        <button data-bs-toggle="modal" data-bs-target="#accessModal" className="orange-btn text-xs bold-1">{accessLoading ? "Working..." : "Free access"}</button>
                                    </div>
                                </div>
                                <CourseAccessListModal accessList={accessList}/> 
                                <CourseAccessModal onSubmit={onGrantAccess}/> 
                                <table className="table mt-3 mb-5">
                                    <thead className="table-secondary py-5">
                                        <tr>
                                            <th scope="col">Student</th>
                                            <th scope="col">Email</th>
                                            <th scope="col" className="text-center">Date enrolled</th>
                                        </tr>
                                    </thead>

                                    {course.name && course.studentsEnrolled.forEach(student => rowData.push(
                                        <tr className="bg-white" key={student.credential}>
                                            <td className="text-xs">{student.firstName} {student.lastName}</td>
                                            <td className="text-xs">{student.credential}</td>
                                            <td className="text-xs text-center">{student.date.split("T")[0]}</td>
                                        </tr>                                 
                                    ))}
                                    <tbody>{rowData}</tbody>
                                </table>
                                { course.name && course.studentsEnrolled.length === 0 ? (
                                <div className="text-center mb-5 w-100">
                                    <h3 className='text-xxs bold-2'>NO STUDENTS ENROLLED YET</h3>
                                </div>
                                ):(<Fragment></Fragment>)}

                            </Fragment>

                            {/* PREVIEW VIDEO */}
                            <h3 className="text-sm bold-2 mb-4 orange">Preview Video:</h3>
                            {course.prevVideo ? 
                                <div className="d-flex flex-column mb-5 px-1 pb-3 border-orange shadow-sm w-50 w-md-25 bg-white">
                                    <video onContextMenu={handleContextMenu} className="course-vid" controls poster={course.prevVideo.thumbnailURL}>
                                        <source src={course.prevVideo.videoURL} type={course.prevVideo.type} />
                                        Your browser does not support the video tag.
                                    </video>
                                    <div>
                                        <p className="text-xs mt-3 mb-0">Name: {course.prevVideo.videoName}</p>
                                        <p className="text-xs mt-2 mb-0">Size: {course.prevVideo.videoSize}</p>
                                    </div>
                                </div> : <p className="text-xs bold-2 mb-5">No preview video</p> }


                            {/* LOCKED VIDEOS */}
                            <h3 className="text-sm bold-2 mb-3 orange">Locked Videos:</h3>
                            <div className="row m-0 p-0 mb-5">
                                { course.name && lockedVids.map(video => (
                                    <Fragment key={video.videoPosition}>
                                        <div className="col-6 col-md-3 px-0">
                                            <div className="px-1 pb-3 me-1 mt-1 border-orange bg-white shadow-sm position-relative">
                                                <input className="video-check" onChange={checkboxChange} type="checkbox" name={video.videoName} id={video.videoName} alt={`${video.unlocked}`} lang={`${video.videoPosition}`} />       
                                                {vidsToUnlock.indexOf(video.videoPosition) >= 0 ?
                                                <div className="video-check-num bold-2">{vidsToUnlock.indexOf(video.videoPosition) + 1}</div>
                                                : <Fragment></Fragment>}
                                                <video onContextMenu={handleContextMenu} className="course-vid" controls poster={video.thumbnailURL}>
                                                    <source src={video.videoURL} type={video.type} />
                                                    Your browser does not support the video tag.
                                                </video>
                                                <div>
                                                    <p className="text-xs mt-1 mb-0">Name: {video.videoName}</p>
                                                    <p className="text-xs mt-1 mb-0">Size: {video.videoSize}</p>
                                                    <p className="text-xs mt-1 mb-0">Status: <span className={`bold-2 ${video.unlocked ? "green" : "red"}`}>{video.unlocked ? "Unlocked" : "Locked"}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                ))}
                                {lockedVids.length === 0 ? <p className="text-xs bold-2 mb-5 px-0">No locked videos</p> : <Fragment></Fragment>}
                            </div>

                            {/* UNLOCKED VIDEOS */}
                            <div className="row m-0 p-0 mb-4">
                                <div className="d-flex m-0 p-0 justify-content-between align-items-center">
                                    <h3 className="p-0 text-sm bold-2 mb-2 orange">Unlocked Videos:</h3>
                                    {sortedGrouped.length > 0 ? <button className="text-xs orange-btn" data-bs-toggle="modal" data-bs-target="#endSessionModal">End Session</button> : <Fragment></Fragment>}
                                </div>
                                <EndSessionModal course={course}/>  
                                {sortedGrouped.map((group, index) => (
                                <Fragment key={index}>
                                    {Object.keys(group).map((date) => (
                                    <Fragment key={date}>
                                        <h5 className="text-xs text-muted bold-2 p-0 mt-4 mb-3">Unlocked: {date}</h5>

                                        {group[date].map((video) => (
                                            <Fragment key={video.videoPosition}>
                                                <div className="col-6 col-md-3 px-0">
                                                    <div className="px-1 pb-3 me-1 mt-1 border-orange bg-white shadow-sm position-relative">
                                                        {video.videoPosition > 0 ?
                                                        <input className="video-check" onChange={checkboxChange} type="checkbox" name={video.videoName} id={video.videoName} alt={`${video.unlocked}`} lang={`${video.videoPosition}`} />
                                                        : <Fragment></Fragment>}
                                                        <video onContextMenu={handleContextMenu} className="course-vid" controls poster={video.thumbnailURL}>
                                                            <source src={video.videoURL} type={video.type} />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        <div>
                                                            <p className="text-xs mt-1 mb-0">Name: {video.videoName}</p>
                                                            <p className="text-xs mt-1 mb-0">Size: {video.videoSize}</p>
                                                            <p className="text-xs mt-1 mb-0">Status: <span className={`bold-2 ${video.unlocked ? "green" : "red"}`}>{video.unlocked ? "Unlocked" : "Locked"}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))}
                                    </Fragment>
                                ))}
                                </Fragment>

                                ))}
                                {unlockedVids.length === 0 ? <p className="text-xs bold-2 px-0">No unlocked videos</p> : <Fragment></Fragment>}


                                <div className="d-flex flex-column p-0 my-5">
                                    <Link to={`/admin/academy/${course._id}/add-videos`} className="action-btn text-xs py-3 text-center w-100">Add Videos</Link>
                                    <Link to={`/admin/academy/${course._id}/change-videos`} className="action-btn text-xs py-3 text-center mt-3 w-100">Change Videos</Link>
                                    <Link to={`/admin/academy/${course._id}/update-course`} className="action-btn text-xs py-3 text-center my-3 w-100">Update Course</Link>
                                    <button className="action-btn text-xs py-3 text-center w-100" type="button" onClick={toggleUnlock} disabled={updateLoading || (!vidsToLock.length > 0 && !vidsToUnlock.length > 0)}>Lock/Unlock Videos</button>                            
                                </div>
                            </div>
                        </div>
                    }
                </Fragment>                              
            </div>
        </Fragment>
    )
}

export default CourseDetails;
import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import { numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'
import { getBuyerOrders, clearErrors } from '../../actions/orderActions'


const BuyerOrders = () => {
    let clicked = useRef("All");
    let firstLoad = useRef(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, error, orders } = useSelector(state => state.getOrders );

    let [ filteredOrders, setFilteredOrders ] = useState(orders)

    if (firstLoad.current) {filteredOrders = orders};

    useEffect(() => {
        dispatch(getBuyerOrders());

        if (error) {
            console.log(error, "ERRROORRRR!! - buyerOrders.jsx")

            dispatch(clearErrors())
        }
    }, [dispatch, error])

    const toggleTable = (status) => {
        firstLoad.current = false
        let filter
        clicked.current = status
        if (status === "All") {
            filter = orders;
        } else if (status === "Processing") {
            filter = orders.filter(order => order.orderStatus === "Processing")
        } else if (status === "Shipped") {
            filter = orders.filter(order => order.orderStatus === "Shipped")
        } else if (status === "Delivered") {
            filter = orders.filter(order => order.orderStatus === "Delivered")
        } else if (status === "Cancelled"){
            filter = orders.filter(order => order.orderStatus === "Cancelled")
        } else {
            filter = []
        }

        setFilteredOrders(filter)
    }

    const goBack = () => {
        navigate(-1)
    }

    const rowData = [];

    const onSearchChange = (e) => {
        if (e.target.value === "") {
            return setFilteredOrders(orders)
        }
        let matches = orders.filter(order => order._id.toLowerCase().includes(e.target.value.toLowerCase()))

        setFilteredOrders(matches);
        firstLoad.current = false;
    }

    return (
        <Fragment>
            <Metadata title={"Buyer Orders"}/>

            <div className="container-md my-4 ps-4 ps-md-2">
                <div onClick={goBack} className="breadcrumb text-2 text-muted">{"< Go Back"}</div>
            </div>

            <div className="container-fluid">
                <div className="container-md mt-5">
                    <h3 className="text-3 text-muted mb-3">MY ORDERS</h3>

                    <div className="d-flex">
                        <input 
                        className="form-control mb-3 py-2" 
                        type="search" 
                        placeholder="Search orders by Id" 
                        aria-label="Search"
                        onChange={onSearchChange}
                        />
                    </div>

                    <div className="d-flex flex-column flex-md-row justify-content-between border-bottom mb-4">
                        <span className="text-muted text-2 order-1 order-md-2">{filteredOrders.length} {clicked.current === 'All' ? 'Orders' : clicked.current}</span>
                        <div className="table-nav-block order-2 order-md-1 mt-3 mt-md-0">
                            <button onClick={function() { toggleTable('All') }} className={`table-nav ms-0 ${clicked.current === 'All'?'table-active':''}`}>All</button>
                            <button onClick={function() { toggleTable('Processing') }} className={`table-nav ${clicked.current === 'Processing'?'table-active':''}`}>Processing</button>
                            <button onClick={function() { toggleTable('Shipped') }} className={`table-nav ${clicked.current === 'Shipped'?'table-active':''}`}>Shipped</button>
                            <button onClick={function() { toggleTable('Delivered') }} className={`table-nav ${clicked.current === 'Delivered'?'table-active':''}`}>Delivered</button>
                            <button onClick={function() { toggleTable('Cancelled') }} className={`table-nav ${clicked.current === 'Cancelled'?'table-active':''}`}>Cancelled</button>
                        </div>
                    </div>
                        

                    {loading ? <Loader/> : (
                        <Fragment>

                        <table className="table mt-3 mb-5">
                            <thead className="table-secondary py-5">
                                <tr>
                                    <th scope="col">OrderId</th>
                                    <th scope="col">Products in order</th>
                                    <th scope="col">Order Amount</th>
                                    <th scope="col">Date ordered</th>
                                    <th scope="col">Status</th>
                                    <th className="text-center" scope="col">Action</th>
                                </tr>
                            </thead>

                            {filteredOrders.forEach(order => rowData.push(
                                <tr className="bg-white" key={order._id}>
                                    <td className="text-2">{order._id}</td>
                                    <td className="text-2">{order.orderItems.length}</td>
                                    <td className="text-2">&#x20A6;{numberWithCommas(order.totalPrice)}</td>
                                    <td className="text-2">{order.createdAt.split('T')[0]}</td>
                                    <td className={`text-2 ${order.orderStatus === 'Cancelled' ? 'red-color' : order.orderStatus === 'Delivered' ? 'green-color' : ''}`}>{order.orderStatus}</td>
                                    <td className="text-center"><Link to={`/shop/my-orders/${order._id}`}className="orange bold-2">View</Link></td>
                                </tr>                                 
                            ))}
                            <tbody>{rowData}</tbody>
                        </table>
                        { filteredOrders.length == 0 ? (
                            <div className="text-center w-100 mb-5">
                                <h3 className='text-2'>NO DATA</h3>
                            </div>
                        ):(<Fragment></Fragment>)}
                    </Fragment>
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default BuyerOrders
import React, { Fragment, useEffect, useState } from 'react'

import Metadata from '../layout/metadata'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux'
import { formatDate } from '../../Functions/functions'
import { useNavigate } from 'react-router-dom'

const BuyerEbooks = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, user } = useSelector(state => state.auth);
    const ebooks = user.ebooks

    let [ filteredEbooks, setFilteredEbooks ] = useState(ebooks)

    const onSearchChange = (e) => {
        if (e.target.value === "") {
            return setFilteredEbooks(ebooks)
        }
        let matches = ebooks.filter(ebook => ebook.name.toLowerCase().includes(e.target.value.toLowerCase()))

        setFilteredEbooks(matches);
    }

    const handleDownload = (name, url) => {
        const pdfUrl = url;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'; // Open in a new tab
        link.download = name; // Specify the suggested file name
        link.click();
      };

    const goBack = () => {
        navigate(-1)
    }

    const rowData = []

    return (
        <Fragment>
            <Metadata title={"My Ebooks"}/>

            <div className="container-md my-4 ps-4 ps-md-2">
                <div onClick={goBack} className="breadcrumb text-2 text-muted">{"< Go Back"}</div>
            </div>

            <div className="container-fluid">
                <div className="container-md mt-5">
                    <h3 className="text-3 text-muted mb-3">MY EBOOKS</h3>

                    <div className="d-flex">
                        <input 
                        className="form-control mb-3 py-2" 
                        type="search" 
                        placeholder="Search ebooks by name" 
                        aria-label="Search"
                        onChange={onSearchChange}
                        />
                    </div>

                    {loading ?                        
                        <Fragment>
                            <div className="my-4">
                                <Skeleton count={15} height={20} baseColor="#dc720082"/>
                            </div>
                        </Fragment> :  (
                        <Fragment>
                            <table className="table mt-3 mb-5">
                                <thead className="table-secondary py-5">
                                    <tr>
                                        <th scope="col"><i className="fa-solid fa-image"></i></th>
                                        <th scope="col">Ebook</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Purchased</th>
                                        <th scope="col" className="text-center">Action</th>
                                    </tr>
                                </thead>                            
                                {filteredEbooks.forEach(ebook => rowData.push(
                                    <tr className="bg-white" key={ebook.name}>
                                        <td><div style={{backgroundImage: `url('${ebook.image}')`, backgroundSize: 'cover'}} className="table-image border rounded"></div></td>
                                        <td className="text-2 bold-2">{ebook.name}</td>
                                        <td className="text-2">{ebook.price}</td>
                                        <td className="text-2">{formatDate(ebook.date)}</td>
                                        <td className="text-center"><button className='text-1 orange-btn bold-3' onClick={() => handleDownload(ebook.name, ebook.doc)}>Download E-book</button></td>
                                    </tr>                                 
                                ))}
                                <tbody>{rowData}</tbody>
                            </table>
                            { filteredEbooks.length == 0 ? (
                                <div className="text-center mb-5 w-100">
                                    <h3 className='text-2'>NO DATA</h3>
                                </div>
                            ):(<Fragment></Fragment>)}
                        </Fragment>                          
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default BuyerEbooks;